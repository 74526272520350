import { createRouterMiddleware } from '@lagunovsky/redux-react-router'
import { configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { createBrowserHistory } from 'history'

import createRootReducer from './rootReducer'

export const history = createBrowserHistory()

const middleware = [createRouterMiddleware(history)]

const sentryReduxEnhancer = Sentry.createReduxEnhancer({})

export const store = configureStore({
  reducer: createRootReducer(history),
  devTools: process.env.NODE_ENV === 'development',
  middleware,
  enhancers: [sentryReduxEnhancer],
})

export type RootState = ReturnType<typeof store.getState>
