import { useTranslation } from 'react-i18next'
import { ListItem, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { formatToShortDayMonthString, formatToTimeString } from '@klr/shared'

interface StyledTypographyProps {
  isChange: boolean
  component?: string
}

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(2.5),
  '&:last-child': {
    marginBottom: 0,
  },
}))

const StyledDateTimeContainer = styled('div')(() => ({
  marginRight: 35,
  width: 50,
  minWidth: 50,
}))

const StyledTimeTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isChange',
})<StyledTypographyProps>(({ isChange, theme }) => ({
  color: theme.palette.primary.light,
  fontSize: '1rem',
  fontWeight: theme.typography.fontWeightBold,
  ...(isChange && {
    color: theme.palette.text.primary,
  }),
}))

const StyledCityTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isChange',
})<StyledTypographyProps>(({ isChange, theme }) => ({
  marginRight: 32,
  color: theme.palette.text.secondary,
  fontSize: '1rem',
  fontWeight: 500,
  position: 'relative',
  '&::after': {
    content: "''",

    position: 'absolute',
    borderRadius: '50%',
    height: 8,
    width: 8,
    backgroundColor: theme.palette.action.active,

    top: '50%',
    left: -27.5,
    transform: 'translateY(-50%)',
    zIndex: 2,
  },
  ...(isChange && {
    color: theme.palette.primary.main,
    '&::after': {
      background: theme.palette.primary.main,
    },
  }),
}))

type PropsItem = {
  dateTime: Date
  city: string
  station: string
  isChange: boolean
  isShowDate: boolean
}

export const ListStationItem = ({ dateTime, city, station, isChange, isShowDate }: PropsItem) => {
  const { t } = useTranslation()

  return (
    <StyledListItem>
      <StyledDateTimeContainer>
        <StyledTimeTypography component="time" variant="caption" isChange={isChange}>
          {formatToTimeString(dateTime)}
        </StyledTimeTypography>
        {isShowDate || isChange ? (
          <Typography variant="body2" mt={0.25}>
            {formatToShortDayMonthString(dateTime)}
          </Typography>
        ) : null}
      </StyledDateTimeContainer>
      <div>
        <StyledCityTypography variant="body1" isChange={isChange}>
          {city} {isChange && <span>({t('Booking.trip.transfer')})</span>}
        </StyledCityTypography>
        <Typography variant="body2">{station}</Typography>
      </div>
    </StyledListItem>
  )
}
