import { ReactNode } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

import { TicketDetail, TicketReqChangeData } from '@klr/api-connectors'
import { FormProvider, useConfirm } from '@klr/shared'

import { useChangeTicket } from '../../hooks/tickets/useChangeTicket'
import {
  TicketPassengerFormFields,
  ticketPassengerSchema,
} from '../../tickets-passenger.validation'

interface ChildrenProps {
  isLoading: boolean
  methods: UseFormReturn<TicketPassengerFormFields>
  t: TFunction
}

interface PassengerFormProps {
  children: (props: ChildrenProps) => ReactNode
  defaultPlace?: number
  ticketData: TicketDetail
}

export const PassengerForm = ({ children, defaultPlace, ticketData }: PassengerFormProps) => {
  const { t } = useTranslation()
  const confirm = useConfirm()
  const { id, first_name, last_name, phone, email, ticket_number, information, is_free_place } =
    ticketData

  const { mutate, isLoading } = useChangeTicket(id)

  const defaultValues: TicketPassengerFormFields = {
    firstName: first_name,
    lastName: last_name,
    phone,
    email,
    ticketNumber: ticket_number,
    note: information ?? '',
  }

  if (defaultPlace) {
    defaultValues.place = defaultPlace
  }

  const methods = useForm<TicketPassengerFormFields>({
    defaultValues,
    resolver: yupResolver(ticketPassengerSchema(t)),
  })

  const onSubmit = (fields: TicketPassengerFormFields): void => {
    const newData: TicketReqChangeData = {
      first_name: fields.firstName ?? undefined,
      last_name: fields.lastName ?? undefined,
      phone: fields.phone ?? undefined,
      email: fields.email,
      ticket_number: fields.ticketNumber,
      information: fields.note,
    }

    if (fields.place) {
      newData.place = fields.place
    }

    mutate(newData)
  }

  const handleChange = (fields: TicketPassengerFormFields): void => {
    if (!is_free_place || !fields.place || defaultPlace === fields.place) {
      onSubmit(fields)
    } else {
      confirm({
        title: t('Common.fixed_place_dialog_title'),
      }).then((success) => {
        if (success) {
          onSubmit(fields)
        }
      })
    }
  }

  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(handleChange)}>
      {children({ isLoading, methods, t })}
    </FormProvider>
  )
}
