import { lazy, Suspense, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { Box, Divider } from '@mui/material'

import { Fallback, TabData, TabsControls } from '@klr/shared'

type TabValueType = 'general' | 'security'

const TabsContainer = lazy(() => import('./components/TabsContainer'))

const getTabsConfig: (t: TFunction) => TabData<TabValueType>[] = (t: TFunction) => [
  { value: 'general', label: t('Auth.user_data') },
  { value: 'security', label: t('Auth.change_password') },
]

export const AccountController = () => {
  const { t } = useTranslation()

  const [currentTab, setCurrentTab] = useState<TabValueType>('general')

  return (
    <>
      <Box>
        <TabsControls<TabValueType>
          currentTab={currentTab}
          handleChange={setCurrentTab}
          tabsArray={getTabsConfig(t)}
        />
        <Divider />
      </Box>
      <Suspense fallback={<Fallback />}>
        <TabsContainer currentTab={currentTab} />
      </Suspense>
    </>
  )
}
