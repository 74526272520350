import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { BookingItem, TransportsSchemeItem } from '@klr/api-connectors'

import { ToggledPlace } from '../booking.types'

import {
  AvailableTransportPlaces,
  BookingCheckoutStore,
  PassengerItem,
  ToggleSelectedData,
} from './booking-checkout-store.types'
import {
  addNewPlaceToCS,
  changeSelectedDataInPassengers,
  changeSelectedDataInTransport,
  getSelectedPlacesFromScheme,
  initializeCallStackFromPassengers,
  removeFirstAndAddSelectedPlaceToCS,
  removeOldAndAddNewPlaceToCS,
  removeSelectedDataFromPassengers,
  removeSelectedPlaceFromCS,
  setPriceAndSelectedPlacesToPassengers,
  setupTransportData,
  transformFreeMainTransportPlaces,
  transformPassengerFreePlaces,
} from './booking-checkout-store.utils'

const initialState = {
  bookingData: null,
  availableTransportPlaces: {},
  passengers: [],
  transportData: [],
  callStackTransportSelectedPlaces: {},
}

export const useBookingCheckoutStore = create<BookingCheckoutStore>()(
  devtools(
    (_, get) => ({
      ...initialState,
      getFullPrice: () => {
        return get().passengers.reduce((acc, currentValue) => acc + currentValue.price, 0)
      },
      selectedPlaceCount: () => {
        return get().passengers.reduce(
          (acc, currentValue) => acc + currentValue.selectedPlaces.length,
          0
        )
      },
      getMainTransportId: () => {
        return get().bookingData?.transport_id || 0
      },
      getFreeMainTransportPlaces: () => {
        return transformFreeMainTransportPlaces(get().availableTransportPlaces)
      },
    }),
    {
      name: 'bookingCheckoutStore',
      enabled: process.env.NODE_ENV === 'development',
    }
  )
)

export const clearBookingCheckoutStore = () =>
  useBookingCheckoutStore.setState(() => ({
    ...initialState,
  }))

export const initializePassengers = (payload: PassengerItem[]) => {
  useBookingCheckoutStore.setState(() => ({
    passengers: payload,
  }))
}

export const initializeBookingData = (payload: BookingItem) =>
  useBookingCheckoutStore.setState(() => ({ bookingData: payload }))

export const initializeAvailableTransportPlaces = (payload: AvailableTransportPlaces) =>
  useBookingCheckoutStore.setState(() => ({ availableTransportPlaces: payload }))

export const initializeTransportData = (
  initialData: TransportsSchemeItem[],
  newRouteName: string
) =>
  useBookingCheckoutStore.setState(() => ({
    transportData: setupTransportData(initialData, newRouteName),
  }))

export const initializePassengerSelectedPlaces = () =>
  useBookingCheckoutStore.setState((state) => {
    if (state.transportData.length) {
      const passengersWithPlaces = setPriceAndSelectedPlacesToPassengers({
        availableFreeTransportPlaces: state.getFreeMainTransportPlaces(),
        mainTransportId: state.getMainTransportId(),
        passengers: state.passengers,
        selectedPlaces: getSelectedPlacesFromScheme(state.transportData),
      })

      return {
        passengers: passengersWithPlaces,
        callStackTransportSelectedPlaces: initializeCallStackFromPassengers(passengersWithPlaces),
      }
    }

    return state
  })

export const toggleSelectedData = ({
  passengerIndex,
  transportId,
  newPlace,
  oldPlace,
}: ToggleSelectedData) =>
  useBookingCheckoutStore.setState((state) => {
    const newPassengers = changeSelectedDataInPassengers(state.passengers, {
      passengerIndex,
      transportId,
      newPlace,
      oldPlace,
    })

    return {
      transportData: changeSelectedDataInTransport(state.transportData, {
        transportId,
        newPlace: newPlace.place,
        oldPlace,
      }),
      passengers: transformPassengerFreePlaces({
        availableFreeTransportPlaces: state.getFreeMainTransportPlaces(),
        mainTransportId: state.getMainTransportId(),
        passengers: newPassengers,
      }),
    }
  })

export const toggleMultipleSelectedData = ({ transportId, place }: ToggledPlace, lock: boolean) => {
  useBookingCheckoutStore.setState((state) => {
    const transportData = changeSelectedDataInTransport(state.transportData, {
      transportId,
      newPlace: place.place,
    })

    const passengerIndex = lock
      ? state.passengers.findIndex((item) =>
          item.selectedPlaces.every((selectedPlace) => selectedPlace.transportId !== transportId)
        )
      : -1

    if (lock && passengerIndex === -1) {
      return state
    }

    const newPassengers = lock
      ? changeSelectedDataInPassengers(state.passengers, {
          passengerIndex,
          transportId,
          newPlace: place,
        })
      : removeSelectedDataFromPassengers(state.passengers, {
          transportId,
          place,
        })

    return {
      transportData,
      passengers: transformPassengerFreePlaces({
        availableFreeTransportPlaces: state.getFreeMainTransportPlaces(),
        mainTransportId: state.getMainTransportId(),
        passengers: newPassengers,
      }),
    }
  })
}

export const addPassenger = (payload: PassengerItem) =>
  useBookingCheckoutStore.setState((state) => ({
    passengers: [...state.passengers, payload],
  }))

export const removePassenger = (payload: number) =>
  useBookingCheckoutStore.setState((state) => {
    const removedPassenger = state.passengers.find((item) => item.place === payload)

    const newPassengers = state.passengers.filter((item) => item.place !== payload)

    return {
      passengers: transformPassengerFreePlaces({
        availableFreeTransportPlaces: state.getFreeMainTransportPlaces(),
        mainTransportId: state.getMainTransportId(),
        passengers: newPassengers,
        oldPassenger: removedPassenger,
      }),
    }
  })

// Call stack
export const removeSelectedPlaceFromCallStack = (transportId: number, place: number) =>
  useBookingCheckoutStore.setState((state) => ({
    callStackTransportSelectedPlaces: removeSelectedPlaceFromCS({
      state: state.callStackTransportSelectedPlaces,
      transportId,
      place,
    }),
  }))

export const removeOldAndAddNewPlaceToCallStack = (
  transportId: number,
  oldPlace: number,
  newPlace: number
) =>
  useBookingCheckoutStore.setState((state) => ({
    callStackTransportSelectedPlaces: removeOldAndAddNewPlaceToCS({
      state: state.callStackTransportSelectedPlaces,
      transportId,
      oldPlace,
      newPlace,
    }),
  }))

export const removeFirstAndAddSelectedPlaceToCallStack = (transportId: number, place: number) =>
  useBookingCheckoutStore.setState((state) => ({
    callStackTransportSelectedPlaces: removeFirstAndAddSelectedPlaceToCS({
      state: state.callStackTransportSelectedPlaces,
      transportId,
      place,
    }),
  }))

export const addNewPlaceToCallStack = (transportId: number, place: number) =>
  useBookingCheckoutStore.setState((state) => ({
    callStackTransportSelectedPlaces: addNewPlaceToCS({
      state: state.callStackTransportSelectedPlaces,
      transportId,
      place,
    }),
  }))
