import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { PlaceInsert, Scheme, SchemeFloor } from '../scheme.types'
import {
  addRow,
  generateEmptyRow,
  getFilteredNonUniquePlaces,
  getSeatsInRow,
  insertPlaceInScheme,
  removeRow,
} from '../utils'

interface SchemeStore {
  nonUniquePlaces: number[] | null
  scheme: Scheme | null
  resetStore(): void
  addFloorToScheme(): void
  removeFloorFromScheme(): void
  addRowToScheme(payload: SchemeFloor): void
  removeRowFromScheme(payload: SchemeFloor): void
  setPlaceInScheme(payload: PlaceInsert): void
  setScheme(payload: Scheme): void
  setNonUniquePlaces(payload: number[] | null): void
}

export const useSchemeStore = create<SchemeStore>()(
  immer(
    devtools(
      persist(
        (set) => ({
          nonUniquePlaces: null,
          scheme: null,

          resetStore: () => set({ scheme: null, nonUniquePlaces: null }, false, 'reset'),
          setPlaceInScheme: (payload: PlaceInsert) =>
            set(
              (state) => {
                state.scheme = insertPlaceInScheme(state.scheme, payload)
              },
              false,
              'setPlaceInScheme'
            ),
          addFloorToScheme: () =>
            set(
              (state) => {
                if (!state.scheme || state.scheme.floor.second) return

                state.scheme.floor.second = {
                  seatsCount: 0,
                  row: generateEmptyRow(),
                }
                state.scheme.floorCount = 2
              },
              false,
              'addFloorToScheme'
            ),
          removeFloorFromScheme: () =>
            set(
              (state) => {
                if (!state.scheme) return

                state.scheme.floor.second = null
                state.scheme.floorCount = 1
              },
              false,
              'removeFloorFromScheme'
            ),
          addRowToScheme: (payload: SchemeFloor) =>
            set(
              (state) => {
                // Check for the existence of the scheme and the specified floor
                if (!state.scheme || !(payload in state.scheme.floor)) return

                const floor = state.scheme.floor[payload]
                if (!floor) return // Additional check for TypeScript

                floor.row = addRow(floor.row)
              },
              false,
              'addRowToScheme'
            ),
          removeRowFromScheme: (payload: SchemeFloor) =>
            set(
              (state) => {
                if (!state.scheme || !state.scheme.floor[payload]) return

                // Temporary variable to hold the non-nullable floor object
                const floor = state.scheme.floor[payload]

                if (!floor) return // Additional check for TypeScript
                const rowSeats = getSeatsInRow(floor.row)

                state.nonUniquePlaces = getFilteredNonUniquePlaces(state.nonUniquePlaces, rowSeats)

                floor.row = removeRow(floor.row)

                floor.seatsCount -= rowSeats.length
              },
              false,
              'removeRowFromScheme'
            ),
          setScheme: (payload) => set({ scheme: payload }, false, 'setScheme'),
          setNonUniquePlaces: (payload) =>
            set({ nonUniquePlaces: payload }, false, 'setNonUniquePlaces'),
        }),
        { name: 'schemeStore', version: 2 }
      ),
      {
        name: 'schemeStore',
        enabled: process.env.NODE_ENV === 'development',
      }
    )
  )
)
