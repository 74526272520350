import { DropzoneOptions, ErrorCode, useDropzone as useLibDropzone } from 'react-dropzone'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

export const useDropZone = (
  options: Omit<DropzoneOptions, 'maxFiles' | 'accept' | 'multiple' | 'onDropRejected'>
) => {
  const { t } = useTranslation()

  return useLibDropzone({
    maxFiles: 1,
    maxSize: 10 * 1024 * 1000,
    minSize: 100,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    multiple: false,
    onDropRejected: (rejections) => {
      let error
      switch (!!rejections[0].errors[0].code && rejections[0].errors[0].code) {
        case ErrorCode.FileTooLarge:
          error = t('Validations.file_too_large')
          break
        case ErrorCode.FileInvalidType:
          error = t('Validations.file_invalid_type')
          break
        case ErrorCode.FileTooSmall:
          error = t('Validations.file_too_small')
          break

        default:
          break
      }

      toast.error(error || t('Validations.file_fail_upload'))
    },
    ...options,
  })
}
