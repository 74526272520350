import { memo } from 'react'
import { Box } from '@mui/material'

import { useNavData } from '../../config-navigation'

import { NavSectionHorizontal } from './components/NavSectionHorizontal'

export const NavHorizontal = memo(() => {
  const navData = useNavData()

  return (
    <Box px={3}>
      <NavSectionHorizontal data={navData} />
    </Box>
  )
})
