import { Box, Chip } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledChipQuickWrap = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingBottom: theme.breakpoints.up('sm') ? theme.spacing(5) : 0,
}))

export const StyledChipQuickChipWrap = styled(Box)({
  position: 'absolute',
})

export const StyledChipQuickChip = styled(Chip)(({ theme }) => ({
  border: `1px dashed ${theme.palette.action.active}`,
  marginRight: 6,
  marginTop: 6,
}))
