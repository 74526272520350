import { toast } from 'react-hot-toast'

export const copyToClipboard = (
  textToCopy: string,
  toastMessage = 'Текст скопійований.',
  toastErrorMessage = 'Не вдалося скопіювати текст. Спробуйте знову.'
): void => {
  navigator.clipboard
    .writeText(textToCopy)
    .then(() => {
      toast.success(toastMessage)
    })
    .catch(() => toast.error(toastErrorMessage))
}
