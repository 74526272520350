import { AxiosPromise } from 'axios'

import { axios } from '../_axios'
import { DetailType } from '../_types'
import { AgentReqUploadLogo } from '../agents'

import {
  AccountCurrentAgent,
  AccountCurrentAgentMutate,
  AccountReqChange,
  AccountReqChangePassword,
} from './account.models'

export const apiAuthFetchAccount = (): AxiosPromise => {
  return axios({
    url: '/account',
    method: 'get',
  })
}

export const apiAuthChangeAccount = (data: AccountReqChange): AxiosPromise => {
  return axios({
    url: '/account',
    method: 'patch',
    data,
  })
}

export const apiAccountMutatePassword = (data: AccountReqChangePassword): AxiosPromise => {
  return axios({
    url: '/account/password',
    method: 'put',
    data,
  })
}

// ==================== Agent Account ====================

export const apiAgentsFetchAccount = (): AxiosPromise<DetailType<AccountCurrentAgent>> => {
  return axios({
    url: '/agents/account',
    method: 'get',
  })
}

export const apiAgentsMutateAccount = (
  data: AccountCurrentAgentMutate
): AxiosPromise<DetailType<AccountCurrentAgent>> => {
  return axios({
    url: '/agents/account',
    method: 'put',
    data,
  })
}

export const apiAccountUploadLogo = ({
  id,
  data,
}: AgentReqUploadLogo): AxiosPromise<DetailType<{ logo: string }>> => {
  return axios.post(`/agents/${id}/logo`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}
