import { forwardRef, Ref } from 'react'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { BookingPromoRoute } from '@klr/api-connectors'
import { formatCurrency, useLocales } from '@klr/shared'

const StyledTypography = styled(Typography)<{ ownerState: { isSmallScreen: boolean } }>(
  ({ theme, ownerState }) => ({
    color: ownerState.isSmallScreen ? 'inherit' : theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: theme.spacing(1.5),
  })
)

const StyledPriceDiscount = styled('span')(({ theme }) => ({
  color: theme.palette.warning.main,
}))

const StyledPriceDiscountPromo = styled('span')<{ ownerState: { isSmallScreen: boolean } }>(({
  theme,
  ownerState,
}) => {
  const isSmallScreen = ownerState.isSmallScreen

  return {
    color: isSmallScreen ? 'inherit' : theme.palette.text.disabled,
    fontSize: theme.typography.pxToRem(14),
    textDecorationLine: 'line-through',
    marginRight: theme.spacing(0.75),
    ...(isSmallScreen && {
      position: 'absolute',
      top: 4,
      left: '20%',
    }),
  }
})

type Props = {
  agentProfit?: number
  fullEBPrice: number | null
  promoRoute: BookingPromoRoute | null
  price: number
  currency: string
  isSmallScreen?: boolean
}

export const CardTripPrice = forwardRef(
  (
    { fullEBPrice, promoRoute, price, currency, isSmallScreen = false }: Props,
    ref: Ref<HTMLSpanElement>
  ) => {
    const { currentLang } = useLocales()
    const priceFormat = formatCurrency(price, currency, currentLang.code)

    const fullPrice = promoRoute ? promoRoute.full_price : fullEBPrice

    return (
      <StyledTypography
        variant="button"
        align={isSmallScreen ? 'center' : 'right'}
        ownerState={{ isSmallScreen }}
        ref={ref}
        data-testid="ticket-price"
      >
        {fullPrice ? (
          <>
            <StyledPriceDiscountPromo ownerState={{ isSmallScreen }}>
              {formatCurrency(fullPrice, currency, currentLang.code)}
            </StyledPriceDiscountPromo>

            <StyledPriceDiscount>{priceFormat}</StyledPriceDiscount>
          </>
        ) : (
          <span>{priceFormat}</span>
        )}
      </StyledTypography>
    )
  }
)
