import { Avatar, Card, CardContent, CardMedia } from '@mui/material'
import { styled } from '@mui/material/styles'

// TODO: перенести компоненти в auth module коли він буде присутній
export const StyledCardContainer = styled(Card)(() => ({
  overflow: 'visible',
  display: 'flex',
  position: 'relative',
  '& > *': {
    flexGrow: 1,
    flexBasis: '50%',
    width: '50%',
  },
}))

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(8, 4, 3, 4),
}))

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  position: 'absolute',
  top: -32,
  left: theme.spacing(3),
  height: 64,
  width: 64,
}))

export const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  backgroundSize: 'contain',
  [theme.breakpoints.down('xl')]: {
    display: 'none',
  },
}))
