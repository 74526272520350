export const paths = {
  home: '/',
  app: '/app/',
  accountSettings: '/app/account/settings/',
  currencies: '/app/currencies/',

  // Reports
  reports: '/app/reports',
  orderList: '/app/order-list',
  reportsTickets: '/app/reports/tickets',

  // Error
  error404: '/404',
}
