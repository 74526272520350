export const DEFAULT_ROUTE_STATION_STEP_SIZE = 100

export * from './app-modules.constants'
export * from './data-grid.constants'
export * from './date.constants'
export * from './local-storage.constants'
export * from './messages.constants'
export * from './promo-ids.constants'
export * from './regexp.constants'

// Pagination constants

export const globalPaths = {
  app: {
    index: '/app',
  },
  error404: '/404',
  auth: {
    login: '/login',
  },
}

export const AGENT_NEWS_TELEGRAM_LINK = 'https://t.me/+hmDGGRHtZXdhMzNi'
export const UKRAINE_COUNTRY_ID = 1
