import { Card, CardContent } from '@mui/material'
import { styled } from '@mui/material/styles'
import { DataGridPremium, gridClasses } from '@mui/x-data-grid-premium'

export const StyledWrapDataGrid = styled(CardContent, {
  shouldForwardProp: (prop) => prop !== 'height',
})<{ height?: number | string }>(({ height = 712 }) => ({
  height,
  width: '100%',
}))

export const DashboardDataGridWrapper = styled(Card)(({ theme }) => ({
  flex: 1,
  height: 800,
  minHeight: 0,
  display: 'flex',
  padding: theme.spacing(2),
  flexDirection: 'column',
}))

export const StyledDataGridPremium = styled(DataGridPremium)(({ theme }) => ({
  [`& .selected-row`]: {
    border:
      theme.palette.mode === 'dark'
        ? `2px dashed ${theme.palette.warning.light}`
        : `2px dashed ${theme.palette.warning.dark}`,
  },

  [`& .disabled-row`]: {
    border:
      theme.palette.mode === 'dark'
        ? `2px dashed ${theme.palette.error.light}`
        : `2px dashed ${theme.palette.error.dark}`,
  },

  [`& .${gridClasses.row}--editing`]: {
    border:
      theme.palette.mode === 'dark'
        ? `2px dashed ${theme.palette.success.light}`
        : `2px dashed ${theme.palette.success.dark}`,
  },
})) as typeof DataGridPremium
