import { AxiosPromise } from 'axios'

import { axios } from '../_axios'
import { ListType } from '../_types'

import { CountryDto, GetCountryListParams } from './countries.models'

/**
 * @description Get a list of countries
 *
 * @tags Countries
 * @request GET:/countries
 */
export const getCountryList = (
  params: GetCountryListParams
): AxiosPromise<ListType<CountryDto>> => {
  return axios.get('/countries', {
    params,
  })
}
