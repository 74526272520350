// it is Linear colors, they can be changed
export const colors = [
  '#bec2c8',
  '#95a2b3',
  '#5e6ad2',
  '#26b5ce',
  '#4cb782',
  '#f2be00',
  '#f2994a',
  '#f7c8c1',
  '#eb5757',
]
