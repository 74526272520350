import { Pagination } from '../../_types'
import { DEFAULT_LIMIT } from '../constants'

type GetQueryPagination = (payload: { pageParam?: number; limit?: number }) => Pagination

// TODO: move to api-connectors
export const getQueryPagination: GetQueryPagination = ({ pageParam, limit = DEFAULT_LIMIT }) => {
  if (limit < 1 || (pageParam && pageParam < 0)) {
    throw new Error(
      '"limit" and "pageParam" arguments in the "getQueryPagination" helper must be positive!'
    )
  }

  return { limit, page: pageParam ?? 1 }
}
