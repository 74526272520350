import { forwardRef, Ref } from 'react'

type Props = {
  color?: string
  size?: number
}

export const ClientIcon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }: Props, svgRef?: Ref<SVGSVGElement>) => {
    return (
      <svg
        ref={svgRef}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={color}
        stroke={color}
        strokeWidth="2"
        {...rest}
      >
        <path
          opacity="0.32"
          d="M1.5 12C1.5 17.799 6.20101 22.5 12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12ZM12.9491 5.80725C12.5501 5.06425 11.4499 5.06425 11.051 5.80725L9.54862 8.60535L6.36352 9.2112C5.54932 9.36605 5.22022 10.319 5.77507 10.9151L8.03362 13.3415L7.61877 16.5888C7.51432 17.4065 8.39427 18.0073 9.15727 17.6394L12 16.2687L14.8428 17.6394C15.6058 18.0073 16.4857 17.4065 16.3813 16.5888L15.9664 13.3415L18.225 10.9151C18.7798 10.319 18.4507 9.36605 17.6365 9.2112L14.4514 8.60535L12.9491 5.80725Z"
        />
        <path d="M11.0509 5.80725C11.4499 5.06425 12.5501 5.06425 12.949 5.80725L14.4514 8.60535L17.6365 9.2112C18.4507 9.36605 18.7798 10.319 18.2249 10.9151L15.9664 13.3415L16.3812 16.5888C16.4857 17.4065 15.6057 18.0073 14.8427 17.6394L12 16.2687L9.15724 17.6394C8.39424 18.0073 7.51429 17.4065 7.61874 16.5888L8.03359 13.3415L5.77504 10.9151C5.22019 10.319 5.54929 9.36605 6.36349 9.2112L9.54859 8.60535L11.0509 5.80725Z" />
      </svg>
    )
  }
)
