import { Grid } from '@mui/material'

import { Floor } from '@klr/api-connectors'
import {
  AxisPosition,
  Direction,
  DriverPlace,
  isSeat,
  StyledBus,
  StyledCardBox,
  TransportPlaceLock,
} from '@klr/schemes'

import { Place } from './components/Place'

interface BusContainerProps {
  activeFloor: number
  axisPosition: AxisPosition
  busSeatsFloor: Floor
  direction: Direction
  onToggle: TransportPlaceLock
  selectedSeat?: number
  isSingleSelected?: boolean
  transportId: number
  otherSelectedSeats: number[]
}

export const BusContainer = ({
  activeFloor,
  axisPosition,
  busSeatsFloor,
  direction,
  onToggle,
  selectedSeat,
  isSingleSelected = false,
  transportId,
  otherSelectedSeats,
}: BusContainerProps) => {
  return (
    <StyledCardBox overflow="auto" width="100%" minWidth={300} maxWidth="min-content">
      <StyledBus>
        <Grid container flexDirection={direction} flexWrap="nowrap" justifyContent="space-around">
          {activeFloor === 0 && (
            <Grid item minWidth={50} minHeight={50}>
              <DriverPlace axisPosition={axisPosition} />
            </Grid>
          )}
          <Grid item container>
            {busSeatsFloor.map((rowOfSeat, rowIndex) => (
              <Grid item key={`floor-${rowOfSeat.length}-row-${rowIndex}`}>
                <Grid container flexDirection={direction} flexWrap="nowrap" columns={5}>
                  {rowOfSeat.map((seat, seatIndex) => (
                    <Grid item key={`${seat.type}-${isSeat(seat) ? seat.place : ''}-${seatIndex}`}>
                      <Place
                        axisPosition={axisPosition}
                        onToggle={onToggle}
                        seat={seat}
                        index={`${activeFloor}-${rowIndex}-${seatIndex}`}
                        isSingleSelected={isSingleSelected}
                        selectedByCurrentPassenger={isSeat(seat) && selectedSeat === seat.place}
                        selectedByOtherPassenger={
                          isSeat(seat) && otherSelectedSeats.includes(seat.place)
                        }
                        transportId={transportId}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </StyledBus>
    </StyledCardBox>
  )
}
