import { useActiveLink } from '@klr/shared'

import { NavListProps } from '../../../../shared/types'

import { NavItem } from './nav-item'

export const NavList = ({ data, depth, slotProps }: NavListProps) => {
  const active = useActiveLink(data.path)

  return (
    <NavItem
      title={data.title}
      path={data.path}
      icon={data.icon}
      //
      depth={depth}
      //
      active={active}
      className={active ? 'active' : ''}
      sx={{
        ...(depth === 1 ? slotProps?.rootItem : slotProps?.subItem),
      }}
    />
  )
}
