import { SyntheticEvent, useCallback, useState } from 'react'
import { useDebounce } from 'react-use'

export function useSearchValue() {
  const [value, setValue] = useState('')
  const [searchValue, setSearchValue] = useState('')

  const [, cancel] = useDebounce(
    () => {
      setSearchValue(value)
    },
    600,
    [value]
  )

  const handleInputChange = useCallback(
    (_: SyntheticEvent, newValue: string) => {
      if (newValue) {
        setValue(newValue)
      } else {
        cancel()
        setSearchValue('')
      }
    },
    [cancel]
  )

  return { searchValue, handleInputChange }
}
