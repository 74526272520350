import { TFunction, useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import {
  Card,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import { ConfirmedTransport } from '@klr/api-connectors'

import { useGetTicketTransportInfo } from '../../../../../../../hooks/tickets'

type Props = {
  ticket_id: number
}

const StyledImageCard = styled(Card)(({ theme }) => ({
  textAlign: 'center',
  height: 300,
  width: 'auto',
  padding: theme.spacing(1),
}))

const StyledImage = styled('img')(() => ({
  height: '100%',
  width: '100%',
  margin: '0 auto',
}))

// TODO: move to utils
export const transformTransportType = (
  transport_type: ConfirmedTransport['transport_type'] | undefined,
  t: TFunction
) => {
  switch (transport_type) {
    case 'changing':
      return t('Booking.trip.transfer')
    case 'boarding':
      return t('Booking.trip.boarding')
    default:
      return ''
  }
}

export const TicketsTransportInfo = ({ ticket_id }: Props) => {
  const { t } = useTranslation()

  const {
    isFetching,
    data: ticketsTransportInfo,
    refetch: fetchTicketsTransportInfo,
  } = useGetTicketTransportInfo({ ticket_id })

  const _renderTableRows = () => {
    return ticketsTransportInfo?.map((item, idx) => (
      <TableRow key={idx}>
        <TableCell>
          {item.transport_logo ? (
            <StyledImageCard>
              <Stack alignItems="center">
                <Typography>{item.route_name}</Typography>
                <Typography>{transformTransportType(item.transport_type, t)}</Typography>
              </Stack>

              <StyledImage src={item.transport_logo} alt="preview-transport" />
            </StyledImageCard>
          ) : (
            <Typography>
              {item.route_name}: {transformTransportType(item.transport_type, t)}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <Typography variant="body2" color="textSecondary">
            {item.transport}
          </Typography>
        </TableCell>
      </TableRow>
    ))
  }

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item md={6}>
        <LoadingButton
          loading={isFetching}
          variant="outlined"
          onClick={() => fetchTicketsTransportInfo()}
          fullWidth
          data-testid="show-transport"
        >
          {t('Booking.trip.show_transport')}
        </LoadingButton>
      </Grid>
      <Grid item xs={12}>
        <Table size="small" data-testid="show-transport-details">
          <TableBody>{_renderTableRows()}</TableBody>
        </Table>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  )
}
