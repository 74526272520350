import {
  DoDisturbOnOutlined as CancelledIcon,
  EventAvailableOutlined as ReservedIcon,
  MonetizationOnOutlined as PaidIcon,
  ScheduleOutlined as DeferredIcon,
} from '@mui/icons-material'

import { StatusTicketEnum } from '@klr/api-connectors'
import { MaterialIcon } from '@klr/shared'

export interface StatusIconDataType {
  status: StatusTicketEnum
  title: string
  icon: MaterialIcon
  color: 'success' | 'warning' | 'error' | 'info'
}

export const statusIconDataList: StatusIconDataType[] = [
  {
    status: StatusTicketEnum.DEFERRED,
    title: 'Очікує на оплату',
    icon: DeferredIcon,
    color: 'warning',
  },
  {
    status: StatusTicketEnum.RESERVED,
    title: 'Зарезервовано',
    icon: ReservedIcon,
    color: 'info',
  },
  {
    status: StatusTicketEnum.CANCEL,
    title: 'Скасовано',
    icon: CancelledIcon,
    color: 'error',
  },
  {
    status: StatusTicketEnum.RETURN,
    title: 'Повернуто',
    icon: CancelledIcon,
    color: 'error',
  },
  {
    status: StatusTicketEnum.PAID,
    title: 'Оплачено клієнтом',
    icon: PaidIcon,
    color: 'success',
  },
  {
    status: StatusTicketEnum.CASH,
    title: 'Оплачено через касу',
    icon: PaidIcon,
    color: 'success',
  },
  {
    status: StatusTicketEnum.CASH_DISP,
    title: 'Оплачено диспетчером',
    icon: PaidIcon,
    color: 'success',
  },
]
