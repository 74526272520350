import type { AxiosPromise } from 'axios'

import { axios } from '../_axios'
import { ByQuery } from '../_byQuery'
import { DetailType, ListType } from '../_types'

import type {
  NotificationDTO,
  NReqChangeMessageTemplate,
  NReqCreateMessageTemplate,
  NReqSendMessageToClient,
  NReqSendPassengerInfoToDriver,
  NResSendMessageToClient,
} from './notifications.models'
import {
  MessageDispatchTemplate,
  NReqSendMessageToUser,
  NResSendPassengerInfoToDriver,
  NResTelegramDetail,
} from './notifications.models'

/**
 * Отримати шаблони для повідомлень
 */
export const apiNotificationsFetchMessageTemplates = (): AxiosPromise<
  ListType<MessageDispatchTemplate>
> => {
  return axios.get('/notifications/clients/templates')
}

/**
 * Створити шаблон повідомлення
 *
 * @param data
 */
export const apiNotificationsCreateMessageTemplate = (
  data: NReqCreateMessageTemplate
): AxiosPromise<DetailType<MessageDispatchTemplate>> => {
  return axios.post('/notifications/clients/templates', data)
}

/**
 * * Оновити шаблон повідомлення
 *
 * @param templateId
 * @param data
 */
export const apiNotificationsChangeMessageTemplate = ({
  id,
  data,
}: NReqChangeMessageTemplate): AxiosPromise<DetailType<MessageDispatchTemplate>> => {
  return axios.put(`/notifications/clients/templates/${id}`, data)
}

/**
 * Видалити шаблон повідомлення
 *
 * @param templateId
 */
export const apiNotificationsDeleteMessageTemplate = (
  templateId: MessageDispatchTemplate['id']
): AxiosPromise => {
  return axios.delete(`/notifications/clients/templates/${templateId}`)
}

/**
 * Відсилання користувачу повідомлення з модалки шапки
 * @param data
 */
export const apiNotificationsSendMessageToUser = (data: NReqSendMessageToUser): AxiosPromise => {
  return axios.post('/notifications/users/send', data)
}

/**
 * Інформування пасажирів з відомості диспетчера
 *
 * @param data
 */
export const apiNotificationsSendMessageToClient = (
  data: NReqSendMessageToClient
): AxiosPromise<DetailType<NResSendMessageToClient>> => {
  return axios.post('/notifications/clients/send', data)
}

/**
 * Інформування водія довільним повідомленням через Telegram
 *
 * @param data
 */
export const apiNotificationsSendPassengerInfoToDriver = (
  data: NReqSendPassengerInfoToDriver
): AxiosPromise<NResSendPassengerInfoToDriver> => {
  return axios.post('/notifications/drivers/send-passenger-info', data)
}

/**
 * Get notifications by query
 *
 * @param data
 */
export const apiNotificationsByQuery = (
  data: ByQuery.Request<NotificationDTO>
): AxiosPromise<ByQuery.ApiResponse<NotificationDTO>> => {
  return axios.post('/notifications/byquery', data)
}

/**
 * Get telegram chat id and title
 */
export const apiNotificationsFetchTelegramChat = (): AxiosPromise<NResTelegramDetail> => {
  return axios.get('/notifications/drivers/chat')
}
