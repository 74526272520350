import { isValid } from 'date-fns'

import {
  formatToDateString,
  formatToDayMonthString,
  formatToShortDayMonthString,
  formatToTimeString,
  formatToUkrDateString,
  formatToUkrFullDateTimeString,
} from './format-date.helper'
import {
  parseDateString,
  parseDateTimeString,
  parseUkrDateString,
  parseUkrTimeDateString,
} from './parse-date.helper'

const transformDateString = (
  value: string,
  parseFunction: (val: string) => Date,
  formatFunction: (date: Date) => string
): string => {
  const date = parseFunction(value)
  return isValid(date) ? formatFunction(date) : 'invalid date'
}

// 'dd.MM.yyyy' to 'dd MMM.' | '01.12.2023' to '01 dec.'
export const transformUkrToShortString = (value: string): string => {
  return transformDateString(value, parseUkrDateString, formatToShortDayMonthString)
}

// 'dd.MM.yyyy' to 'yyyy-MM-dd' | '01.12.2023' to '2023-12-01'
export const transformUkrToIntDateString = (value: string): string => {
  return transformDateString(value, parseUkrDateString, formatToDateString)
}

export const transformIntToUkrDateString = (value: string): string => {
  return transformDateString(value, parseDateString, formatToUkrDateString)
}

// 'HH:mm dd.MM.yyyy' to 'dd.MM.yyyy' | '00:00 01.12.2023' to '01.12.2023'
export const transformUkrTimeDateToUkrDateString = (value: string): string => {
  return transformDateString(value, parseUkrTimeDateString, formatToUkrDateString)
}

// 'yyyy-MM-dd HH:mm:ss' to 'dd MMMM HH:mm:ss' | '2023-12-01 00:00:00' to '01 december 00:00:00'
export const transformDateTimeToUkrFullDateTimeString = (value: string): string => {
  return transformDateString(value, parseDateTimeString, formatToUkrFullDateTimeString)
}

// 'yyyy-MM-dd HH:mm:ss' to 'HH:mm' | '2023-12-01 00:00:00' to '00:00'
export const transformDateTimeToTimeString = (value: string): string => {
  return transformDateString(value, parseDateTimeString, formatToTimeString)
}

// 'yyyy-MM-dd' to 'dd MMMM' | '2023-12-01' to '01 december'
export const transformDateToFullUkrDateString = (value: string): string => {
  return transformDateString(value, parseDateString, formatToDayMonthString)
}
