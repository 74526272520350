import { ReqFetchList } from '../_types'

export type SorryBonusType = 'type_delay_bus' | 'type_refund' | 'type_gift' | 'type_apology'

export enum ValueTypeEnum {
  FIXED = 'fixed',
  PERCENT = 'percent',
}

export interface SorryBonusDTO {
  id: number
  title: string
  type: SorryBonusType
  type_translate: string
  text: string
  value: number
  value_type: ValueTypeEnum
  sms_text: string
}

export interface SorryBonusCategoryDTO {
  title: string
  type: SorryBonusType
}

export interface SorryBonusReqFetchList extends ReqFetchList {
  valueType?: ValueTypeEnum
}

export type SorryBonusReqCreate = Omit<SorryBonusDTO, 'id' | 'type_translate'>

export interface SorryBonusReqChange {
  id: SorryBonusDTO['id']
  data: SorryBonusReqCreate
}
