import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import {
  apiNotificationsFetchMessageTemplates,
  MessageDispatchTemplate,
  NotificationQueryKeys,
  notificationQueryKeys,
} from '@klr/api-connectors'

export const useFetchTemplates = (
  options: Omit<
    UseQueryOptions<
      MessageDispatchTemplate[],
      AxiosError,
      MessageDispatchTemplate[],
      NotificationQueryKeys['templates']
    >,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery({
    queryFn: async () => {
      const response = await apiNotificationsFetchMessageTemplates()

      return response.data.items
    },
    queryKey: notificationQueryKeys.templates(),
    ...options,
  })
}
