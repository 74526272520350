import React, { ChangeEvent, memo, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, TextField } from '@mui/material'

import { PhoneInputMask, SearchTextField } from '@klr/shared'

import { HandleChangeQueryParams, QueryParamsOrderList } from '../../../useOrderListQueryParams'

type FieldType = {
  label: string
  name: 'promo_code' | 'order_number' | 'ticket_number' | 'ticket_email' | 'ticket_phone'
  placeholder: string
  type: string
}

interface SearchTextFieldsProps {
  queryParams: QueryParamsOrderList
  onChangeQueryParams: HandleChangeQueryParams
}

export const OptionSearchTextFields = memo(
  ({ queryParams, onChangeQueryParams }: SearchTextFieldsProps) => {
    const { t } = useTranslation()

    const textFieldOptions: FieldType[] = useMemo(
      () => [
        {
          label: t('Orders.order_number'),
          type: 'number',
          name: 'order_number',
          placeholder: '3143134',
        },
        {
          label: t('Common.ticket_number'),
          type: 'number',
          name: 'ticket_number',
          placeholder: '3143134',
        },
        {
          label: t('Booking.checkout.ticket_email'),
          type: 'email',
          name: 'ticket_email',
          placeholder: 'name@gmail.com',
        },
        {
          label: t('Booking.checkout.promo_code'),
          type: 'text',
          name: 'promo_code',
          placeholder: 'KLR-1234',
        },
        {
          label: t('Booking.checkout.customer_phone'),
          type: 'phone',
          name: 'ticket_phone',
          placeholder: '+380 00 000 00 00',
        },
      ],
      [t]
    )

    const [selectField, setSelectField] = useState<FieldType>(textFieldOptions[0])

    const handleInputChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (queryParams[selectField.name] === event.target.value) return
        onChangeQueryParams(selectField.name, event.target.value)
      },
      [onChangeQueryParams, queryParams, selectField.name]
    )

    return (
      <Grid container columnSpacing={1} rowSpacing={{ xs: 1 }} direction="row">
        <Grid item xs={12} sm={6}>
          <TextField
            select
            label={t('Common.search_by')}
            value={selectField.name}
            SelectProps={{
              native: true,
              size: 'small',
            }}
            fullWidth
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const val = textFieldOptions.find((option) => option.name === event.target.value)

              if (!val) return
              setSelectField(val)
            }}
            sx={{ minWidth: 175 }}
          >
            {textFieldOptions.map((option) => (
              <option key={option.name} value={option.name}>
                {option.label}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          {selectField.type === 'phone' ? (
            <PhoneInputMask
              size="small"
              label={selectField.label}
              value={queryParams.ticket_phone || ''}
              onChange={(event) => onChangeQueryParams('ticket_phone', event.target.value)}
            />
          ) : (
            <SearchTextField
              label={selectField.label}
              name={selectField.name}
              placeholder={selectField.placeholder}
              defaultValue={queryParams[selectField.name] || ''}
              size="small"
              onChange={handleInputChange}
            />
          )}
        </Grid>
      </Grid>
    )
  }
)
