import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Box, MenuItem, Stack, Switch, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useQuery } from '@tanstack/react-query'

import {
  apiSorryBonusCategoryFetchList,
  SorryBonusDTO,
  sorryBonusesQueryKeys,
  ValueTypeEnum,
} from '@klr/api-connectors'
import { FormProvider, RHFNumberInput, RHFTextField } from '@klr/shared'

import { maxFixedValue, maxPercentValue, maxSmsTextLength, minValue } from '../config'
import { SorryBonusFormFields, sorryBonusValidationSchema } from '../validations'

const StyledOutlinedStack = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
}))

interface SorryBonusFormsProps {
  buttonSubmitText: string
  dataSource: SorryBonusDTO
  isLoading: boolean
  onSave(val: SorryBonusFormFields): void
  readOnly?: boolean
  setValueType?(val: ValueTypeEnum): void
  valueType: ValueTypeEnum
}

export const SorryBonusForms = ({
  buttonSubmitText,
  dataSource,
  isLoading,
  onSave,
  readOnly = false,
  setValueType,
  valueType,
}: SorryBonusFormsProps) => {
  const { t } = useTranslation()
  const [focusedSmsText, setFocusedSmsText] = useState(false)

  const { data: categoryList, isLoading: loadingCategoryList } = useQuery({
    queryKey: sorryBonusesQueryKeys.types,
    queryFn: async () => {
      const {
        data: { data },
      } = await apiSorryBonusCategoryFetchList()

      return data
    },
    initialData: [],
  })

  const methods = useForm<SorryBonusFormFields>({
    defaultValues: {
      title: '',
      type: 'type_apology',
      text: '',
      value: minValue,
      sms_text: '',
    },
    resolver: yupResolver(sorryBonusValidationSchema(t)),
    context: {
      valueType,
    },
  })

  const { clearErrors, handleSubmit, reset, watch } = methods

  const smsTextValue = watch('sms_text')

  useEffect(() => {
    reset({
      title: dataSource.title,
      type: dataSource.type,
      text: dataSource.text,
      value: dataSource.value,
      sms_text: dataSource.sms_text,
    })
  }, [dataSource, reset])

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
      <Stack sx={{ pb: 3, pt: 1 }} spacing={2}>
        <Stack spacing={1}>
          <RHFTextField
            name="title"
            label="Ім'я шаблону"
            InputProps={{
              readOnly,
            }}
          />
          <RHFTextField
            select
            disabled={loadingCategoryList}
            label="Категорія нарахування"
            name="type"
            InputProps={{
              readOnly,
            }}
          >
            {categoryList.map((option) => (
              <MenuItem key={option.type} value={option.type}>
                {option.title}
              </MenuItem>
            ))}
          </RHFTextField>
          <RHFTextField
            name="text"
            label="Текст повідомлення"
            multiline
            rows={4}
            InputProps={{
              readOnly,
            }}
          />

          <Stack direction="row" spacing={2} width="50%">
            <RHFNumberInput
              name="value"
              maxValue={valueType === ValueTypeEnum.PERCENT ? maxPercentValue : maxFixedValue}
              InputProps={{
                readOnly,
              }}
              sx={{ minWidth: 62 }}
            />
            <Box>
              <StyledOutlinedStack direction="row" alignItems="center" spacing={1}>
                <Typography color={valueType === ValueTypeEnum.FIXED ? 'success.main' : 'inherit'}>
                  грн
                </Typography>
                <Switch
                  color="success"
                  checked={valueType === ValueTypeEnum.PERCENT}
                  disabled={readOnly}
                  onChange={(_, checked) => {
                    if (setValueType) {
                      setValueType(checked ? ValueTypeEnum.PERCENT : ValueTypeEnum.FIXED)
                      clearErrors('value')
                    }
                  }}
                />
                <Typography
                  color={valueType === ValueTypeEnum.PERCENT ? 'success.main' : 'inherit'}
                >
                  %
                </Typography>
              </StyledOutlinedStack>
              <span></span>
            </Box>
          </Stack>
          <Stack spacing={1}>
            <RHFTextField
              name="sms_text"
              label="Tekst sms povidomlennya"
              multiline
              rows={4}
              onFocus={() => setFocusedSmsText(true)}
              onBlur={() => setFocusedSmsText(false)}
              InputProps={{
                readOnly,
              }}
            />
            <Typography
              variant="body2"
              color="text.disabled"
              sx={{ visibility: focusedSmsText ? 'normal' : 'hidden' }}
            >
              {`Введено ${smsTextValue.length}/${maxSmsTextLength}`}
            </Typography>
          </Stack>
        </Stack>

        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          disabled={loadingCategoryList}
          loading={isLoading}
        >
          {buttonSubmitText}
        </LoadingButton>
      </Stack>
    </FormProvider>
  )
}
