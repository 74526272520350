import { forwardRef, Ref } from 'react'
import { Grow, Slide, SlideProps, Zoom, ZoomProps } from '@mui/material'
import { GrowProps } from '@mui/material/Grow/Grow'

export const TransitionSlide = forwardRef(function Transition(
  props: SlideProps,
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} children={props.children} />
})

export const TransitionZoom = forwardRef(function Transition(props: ZoomProps, ref: Ref<unknown>) {
  return <Zoom ref={ref} {...props} children={props.children} />
})

export const TransitionGrow = forwardRef(function Transition(props: GrowProps, ref: Ref<unknown>) {
  return <Grow ref={ref} {...props} children={props.children} />
})
