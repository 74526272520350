import { ReactNode, useCallback, useEffect, useMemo } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { AxiosPromise } from 'axios'

import { apiAuthLogin, AuthReqLogin, DetailType, SystemRoleMap } from '@klr/api-connectors'
import { AppModules, handleBackendError } from '@klr/shared'

import { STORAGE_KEY } from '../auth.constants'

import { Types, useAuthStore } from './hooks/useAuthStore'
import { AuthContext } from './auth.context'
import { useSelectCurrency } from './hooks'
import { AuthHasPermission, AuthHasRoleType, AuthUserType } from './types'
import { isValidToken, setSession } from './utils'

type AuthProviderProps = {
  children: ReactNode
  apiFetchAccount(): AxiosPromise<DetailType<NonNullable<AuthUserType>>>
  callbackLogin?(user: NonNullable<AuthUserType>): boolean
}

export function AuthProvider(props: AuthProviderProps) {
  const { children, apiFetchAccount, callbackLogin } = props
  const { t } = useTranslation()
  const [state, dispatch] = useAuthStore()
  const selectCurrency = useSelectCurrency()

  const logout = useCallback(async () => {
    setSession(null)

    dispatch({
      type: Types.LOGOUT,
    })
  }, [dispatch])

  const callCallbackLogin = useCallback(
    (user: NonNullable<AuthUserType>) => {
      // Нам не потрібна перевірка на ролі, якщо додаток - агентський, буде відбуватись перевірка при отриманні /agents/account
      const isValid = callbackLogin?.(user) ?? true

      if (!isValid) {
        dispatch({
          type: Types.INITIAL,
          payload: { user: null },
        })
        toast.error(t('Notifications.Auth.permission_denied'))

        logout()
      }

      if (isValid && user.currencies) selectCurrency(user)

      return isValid
    },
    [callbackLogin, dispatch, logout, selectCurrency, t]
  )

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY)

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken)

        const response = await apiFetchAccount()
        const user = response.data.item
        const isValid = callCallbackLogin(user)
        if (!isValid) return

        dispatch({
          type: Types.INITIAL,
          payload: {
            user,
          },
        })
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: { user: null },
        })
      }
    } catch (error) {
      handleBackendError(error, AppModules.Auth)

      console.error(error)
      dispatch({
        type: Types.INITIAL,
        payload: { user: null },
      })
      logout()
    }
  }, [apiFetchAccount, callCallbackLogin, dispatch, logout])

  useEffect(() => {
    initialize()
  }, [initialize])

  const login = useCallback(
    async (payload: AuthReqLogin) => {
      try {
        const responseAuth = await apiAuthLogin(payload)
        setSession(responseAuth.data.access_token)

        const responseAccount = await apiFetchAccount()
        const user = responseAccount.data.item
        const isValid = callCallbackLogin(user)
        if (!isValid) return

        dispatch({
          type: Types.LOGIN,
          payload: { user },
        })
      } catch (error) {
        handleBackendError(error, AppModules.Auth)
        console.error(error)
        logout()
      }
    },
    [apiFetchAccount, callCallbackLogin, dispatch, logout]
  )

  const changeUserData = useCallback(
    (payload: Partial<AuthUserType>) => {
      if (!state.user) return

      const user = {
        ...state.user,
        ...payload,
      }

      dispatch({
        type: Types.MUTATE_USER,
        payload: { user },
      })
    },
    [dispatch, state.user]
  )

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated'
  const status = state.loading ? 'loading' : checkAuthenticated

  const memoizedValue = useMemo(() => {
    const userRoles: AuthHasRoleType = {
      isAccountant: false,
      isAdmin: false,
      isAgent: false,
      isApi: false,
      isCarrier: false,
      isDispatcher: false,
      isOperator: false,
      isTransport: false,
    }

    if (state.user?.roles) {
      const { roles } = state.user

      userRoles.isAccountant = roles.some((role) => role.key === SystemRoleMap.Accountant)
      userRoles.isAdmin = roles.some((role) => role.key === SystemRoleMap.Admin)
      userRoles.isAgent = roles.some((role) => role.key === SystemRoleMap.Agent)
      userRoles.isApi = roles.some((role) => role.key === SystemRoleMap.Api)
      userRoles.isCarrier = roles.some((role) => role.key === SystemRoleMap.Carrier)
      userRoles.isDispatcher = roles.some((role) => role.key === SystemRoleMap.Dispatcher)
      userRoles.isOperator = roles.some((role) => role.key === SystemRoleMap.Operators)
      userRoles.isTransport = roles.some((role) => role.key === SystemRoleMap.Transport)
    }

    const permissions: AuthHasPermission = {
      canBookTicketsWithExtendedPeriod: userRoles.isAdmin || userRoles.isDispatcher,
      canRemovePassengerFromSheetPrint: userRoles.isAdmin || userRoles.isAccountant,
      canSeeEBTrip: userRoles.isAdmin || userRoles.isDispatcher,
      canSeeSheetLink:
        userRoles.isAdmin ||
        (userRoles.isDispatcher && !userRoles.isAgent && !userRoles.isOperator),
      hasFullAccessToOrder:
        userRoles.isAdmin ||
        ((userRoles.isDispatcher || userRoles.isOperator) && !userRoles.isAgent),
      canSeeTicketActivity: userRoles.isAdmin || userRoles.isDispatcher,
      canReturnPercent: userRoles.isAdmin || (userRoles.isDispatcher && !userRoles.isAgent),
      canReturnEBTicket: userRoles.isAdmin,
    }

    return {
      ...userRoles,
      permissions,
      user: state.user,
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      logout,
      changeUserData,
    }
  }, [changeUserData, login, logout, state.user, status])

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>
}
