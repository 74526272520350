import { Drawer, drawerClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'width',
})<{ width?: number }>(({ theme, width }) => ({
  [`& .${drawerClasses.paper}`]: {
    backgroundColor: theme.palette.background.default,
    maxWidth: '100%',
    width: width || 650,
    padding: '20px 20px 60px',
    boxShadow: '0 1px 8px 0 rgba(0,0,0,0.08)',
    borderRadius: '8px',
    height: 'calc(100% - 30px)',
    overflowY: 'auto',
    margin: theme.spacing(2.5, 2.5, 2.5, 0),

    [theme.breakpoints.down('md')]: {
      height: '100%',
      width: '100%',
      margin: '0',
      borderRadius: '0',
    },
  },
}))
