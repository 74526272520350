import { IconButton, IconButtonProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { NAV } from '../config'
import { useSettings } from '../contexts/SettingsContext'
import { useResponsive } from '../hooks'
import { bgBlur } from '../theme'

import { SvgColor } from './SvgColor'

type NavToggleButtonProps = IconButtonProps

export const NavToggleButton = ({ sx, ...other }: NavToggleButtonProps) => {
  const theme = useTheme()
  const { themeLayout, onChangeLayout } = useSettings()

  const lgUp = useResponsive('up', 'lg')

  if (!lgUp) {
    return null
  }

  return (
    <IconButton
      size="small"
      onClick={() => {
        onChangeLayout(themeLayout === 'vertical' ? 'mini' : 'vertical')
      }}
      sx={{
        p: 0.5,
        top: 32,
        position: 'fixed',
        left: NAV.W_VERTICAL - 12,
        zIndex: theme.zIndex.appBar + 1,
        border: `dashed 1px ${theme.palette.divider}`,
        ...bgBlur({ opacity: 0.48, color: theme.palette.background.default }),
        '&:hover': {
          bgcolor: 'background.default',
        },
        ...sx,
      }}
      {...other}
    >
      {themeLayout === 'vertical' ? (
        <SvgColor
          src={'/assets/icons/ic_arrow_back.svg'}
          sx={{
            width: 16,
            height: 16,
          }}
        />
      ) : (
        <SvgColor
          src={'/assets/icons/ic_arrow_forward.svg'}
          sx={{
            width: 16,
            height: 16,
          }}
        />
      )}
    </IconButton>
  )
}
