import { useEffect, useState } from 'react'
import { CornerDownLeft as ReturnIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { Divider, Grid } from '@mui/material'

import { PercentInput } from '@klr/shared'

type Props = {
  defaultCustomPercent?: number
  handleCustomReturnPercent: (percent: number) => void
  isLoading: boolean
}

export const CustomReturnPercent = ({
  defaultCustomPercent,
  handleCustomReturnPercent,
  isLoading,
}: Props) => {
  const { t } = useTranslation()
  const [percent, setPercent] = useState(0)

  useEffect(() => {
    if (defaultCustomPercent) {
      setPercent(defaultCustomPercent)
    }
  }, [defaultCustomPercent])

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item sm={6} md={4}>
        <PercentInput
          label={t('Orders.percentage_of_return')}
          value={percent}
          setValue={setPercent}
          disabled={isLoading}
          size="small"
        />
      </Grid>
      <Grid item sm={6} md={8}>
        <LoadingButton
          loading={isLoading}
          variant="outlined"
          onClick={() => handleCustomReturnPercent(percent)}
          startIcon={<ReturnIcon size={16} />}
          fullWidth
        >
          Встановити відсоток для повернення
        </LoadingButton>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  )
}
