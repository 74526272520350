import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Chip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { addDays } from 'date-fns'

type Config = {
  label: string
  value: Date
}

type Props = {
  handleChange: (val: Date) => void
  configs?: Config[]
  isDisabled?: (item: Date) => boolean
}

const tomorrow = addDays(new Date(), 1)
const afterTomorrow = addDays(new Date(), 2)

const defaultConfigs: Config[] = [
  {
    label: 'Calendar.tomorrow',
    value: tomorrow,
  },
  {
    label: 'Calendar.afterTomorrow',
    value: afterTomorrow,
  },
]

export const ChipsQuickDate = ({ handleChange, configs = defaultConfigs, isDisabled }: Props) => {
  const { t } = useTranslation()

  return (
    <StyledChipWrap>
      {configs.map((item, idx) => (
        <StyledChip
          key={idx}
          tabIndex={-1}
          label={t(item.label)}
          size="small"
          variant="outlined"
          onClick={() => {
            handleChange(item.value)
          }}
          disabled={isDisabled ? isDisabled(item.value) : false}
          data-testid={`${item.label}-button`}
        />
      ))}
    </StyledChipWrap>
  )
}

const StyledChipWrap = styled('div')({
  position: 'absolute',
})

const StyledChip = styled(Chip)(({ theme }) => ({
  border: `1px dashed ${theme.palette.action.active}`,
  color: theme.palette.action.active,
  marginRight: 6,
  marginTop: 6,
}))
