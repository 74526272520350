import React from 'react'
import { CircularProgress, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Logo } from '../../Logo'

const StyledRoot = styled(Stack)(({ theme }) => ({
  // We have declared a type for background color, but it's not working on lib level
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  backgroundColor: theme.palette.background.neutral,
  height: '100%',
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100%',
  zIndex: 2000,
}))

export const SplashScreen = () => {
  return (
    <StyledRoot spacing={3} justifyContent="center" alignItems="center">
      <Logo sx={{ width: 200, height: 200 }} />
      <CircularProgress />
    </StyledRoot>
  )
}
