import { AxiosPromise } from 'axios'

import axios from '../_axios/axios'
import { ListDataType } from '../_types'

import { ReqTagSearchItems, TagItem, TagReqCreate, TagReqUpdate } from './tags.models'

export const apiTagsFetchList = (
  params: ReqTagSearchItems = {}
): AxiosPromise<ListDataType<TagItem>> => {
  return axios.get('/tags', { params })
}

export const apiTagsCreate = (data: TagReqCreate): AxiosPromise<TagItem> => {
  return axios.post('/tags', data)
}

export const apiTagsUpdate = ({ id, data }: TagReqUpdate): AxiosPromise<TagItem> => {
  return axios.put(`/tags/${id}`, data)
}
