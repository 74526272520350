import { Box, ListItemButton } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledListItemButton = styled(ListItemButton)({
  position: 'relative',
  alignItems: 'start',
})

export const StyledIcon = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  left: 0,
  width: 18,
  height: 18,
  borderRadius: '50%',
  border: `2px solid ${theme.palette.text.primary}`,
  fontSize: theme.typography.pxToRem(12),
}))
