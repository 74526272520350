import { ChevronDown as ArrowDownIcon, ChevronUp as ArrowUpIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { MoreTimeOutlined as MoreTimeOutlinedIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import { BookingOptions } from '@klr/api-connectors'
import { useAuth } from '@klr/auth'
import { ClipBoardButton, Label } from '@klr/shared'

import { useMapTripBenefits } from '../../../../hooks'

const StyledGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isShowDetail',
})<{ isShowDetail: boolean }>(({ isShowDetail, theme }) => ({
  background: theme.palette.background.neutral,
  borderRadius: theme.spacing(0, 0, 1.5, 1.5),
  ...(isShowDetail && {
    borderRadius: 0,
  }),
}))

const StyledLeftGrid = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(0.25, 1),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0.25, 2),
  },
}))

const StyledRightGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0.25, 1),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0.25, 2),
  },
}))

type CardTripFooterProps = {
  toggleDetail: VoidFunction
  isShowDetail: boolean
  carrierName: string
  transportName: string
  benefits: BookingOptions[]
  eTicket: boolean
  clipboardText: string
  earlyBooking: boolean
}

export const CardTripFooter = ({
  toggleDetail,
  isShowDetail,
  carrierName,
  transportName,
  benefits,
  eTicket,
  clipboardText,
  earlyBooking,
}: CardTripFooterProps) => {
  const { t } = useTranslation()
  const { permissions } = useAuth()

  const BENEFITS = useMapTripBenefits()

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <StyledGrid container isShowDetail={isShowDetail}>
      <StyledLeftGrid container alignItems="center" item xs={8}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" flex={1}>
          <Button
            onClick={toggleDetail}
            size="small"
            startIcon={isShowDetail ? <ArrowUpIcon size={14} /> : <ArrowDownIcon size={14} />}
            data-testid="details-toggle"
          >
            {isShowDetail ? t('Common.collapse') : t('Common.details')}
          </Button>
          <Typography variant="caption" display="block" align={matches ? 'left' : 'right'}>
            <Box component="span" sx={{ display: { xs: 'none', lg: 'inline' } }}>
              {t('Booking.trip.carrier')}:
            </Box>{' '}
            {carrierName}
          </Typography>
          <Typography variant="caption" sx={{ display: { xs: 'none', lg: 'block' } }}>
            {t('Booking.trip.bus')}: {transportName}
          </Typography>
        </Stack>
      </StyledLeftGrid>

      <StyledRightGrid item xs={4}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>
            {benefits.slice(0, 3).map((item, idx) => {
              if (item === 'no_printed_ticket') {
                return null
              }

              if (!BENEFITS[item]) {
                return null
              }

              const Icon = BENEFITS[item].icon

              return (
                <Box key={`icon_${idx}`} sx={{ mr: 1 }}>
                  <Tooltip title={BENEFITS[item].label}>
                    <IconButton aria-readonly disableTouchRipple>
                      <SvgIcon fontSize="small" color="action">
                        <Icon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                </Box>
              )
            })}
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {eTicket && (
              <Tooltip title={t('Messages.ticket_without_print')}>
                <Label color="success">{t('Common.e_ticket')}</Label>
              </Tooltip>
            )}
          </Box>
          {earlyBooking && permissions.canSeeEBTrip && (
            <Box sx={{ display: 'flex' }}>
              <Tooltip title="Квиток з раннім бронюванням">
                <SvgIcon fontSize="medium" color="success">
                  <MoreTimeOutlinedIcon />
                </SvgIcon>
              </Tooltip>
            </Box>
          )}

          <Box sx={{ display: { xs: 'flex', lg: 'none' } }} />

          <ClipBoardButton textToCopy={clipboardText} />
        </Stack>
      </StyledRightGrid>
    </StyledGrid>
  )
}
