import React, { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { createTheme, ThemeProvider, useTheme } from '@mui/material'
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { Locale } from 'date-fns'
import { enGB as en, pl, ru, uk } from 'date-fns/locale'

import { axios } from '@klr/api-connectors'

import { AvailableLanguages, SYSTEM_LANGUAGES, SystemLanguage } from '../../i18n'

interface LocalizationContextValue {
  allLang: SystemLanguage[]
  currentLang: SystemLanguage
  dateLocale: Locale

  onChangeLang(newLang: AvailableLanguages): void
}

export const LocalizationContext = createContext<LocalizationContextValue>({
  allLang: [],
  currentLang: SYSTEM_LANGUAGES[0],
  dateLocale: uk,

  onChangeLang: () => undefined,
})

interface LocalizationProviderProps {
  appLanguages?: SystemLanguage[]
  children: ReactNode
}

export const LocalizationProvider = ({ children, appLanguages }: LocalizationProviderProps) => {
  const defaultTheme = useTheme()

  const { currentLang, handleChangeLanguage } = useCurrentLang(appLanguages)

  const theme = createTheme(
    defaultTheme,
    currentLang.datePicker,
    currentLang.dataGrid,
    currentLang.coreMui
  )

  const dateLocale: Locale = useMemo(() => {
    if (!Array.isArray(appLanguages)) return uk

    return (
      [en, uk, pl, ru].find((item) =>
        item.code === 'en-GB' ? currentLang.value === 'en' : item.code === currentLang.value
      ) || uk
    )
  }, [appLanguages, currentLang.value])

  return (
    <LocalizationContext.Provider
      value={{
        allLang: appLanguages ?? [SYSTEM_LANGUAGES[0]],
        currentLang,
        dateLocale,

        onChangeLang: handleChangeLanguage,
      }}
    >
      <ThemeProvider theme={theme}>
        <MuiLocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
          {children}
        </MuiLocalizationProvider>
      </ThemeProvider>
    </LocalizationContext.Provider>
  )
}

function useCurrentLang(appLanguages: SystemLanguage[] | undefined) {
  const { i18n, t } = useTranslation()

  const [currentLang, setCurrentLang] = useState<SystemLanguage>(SYSTEM_LANGUAGES[0])

  useEffect(() => {
    // Ensure appLanguages is an array
    if (appLanguages && Array.isArray(appLanguages) && appLanguages.length > 0) {
      // Retrieve the stored language from local storage
      const langStorage = localStorage.getItem('i18nextLng')

      // If a stored language is found, return the language
      // Otherwise, return second item if exists, else return the first
      const matchingLang = appLanguages.find((_lang) => _lang.value === langStorage)

      if (matchingLang) {
        setCurrentLang(matchingLang)
      }
    }
  }, [appLanguages])

  const handleChangeLanguage = useCallback(
    (newLang: AvailableLanguages) => {
      i18n
        .changeLanguage(newLang)
        .then(() => {
          if (appLanguages && Array.isArray(appLanguages) && appLanguages.length > 0) {
            const matchingLang = appLanguages.find((_lang) => _lang.value === newLang)

            if (matchingLang) {
              setCurrentLang(matchingLang)
            }
          }

          axios.interceptors.request.use((config) => {
            config.headers['Content-Language'] = newLang

            return config
          })

          toast.success(t('Messages.language_was_changed'))
        })
        .catch(() => {
          toast.error(t('Messages.language_change_failed'))
        })
    },
    [appLanguages, i18n, t]
  )

  return { currentLang, handleChangeLanguage }
}
