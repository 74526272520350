import { forwardRef, Ref, SVGProps } from 'react'

export const RecommendCarrierIcon = forwardRef(
  (props: SVGProps<SVGSVGElement>, svgRef?: Ref<SVGSVGElement>) => {
    const { color = 'currentColor' } = props

    return (
      <svg
        width="18"
        height="36"
        viewBox="0 0 20 40"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="1.5"
        strokeLinecap="round"
        ref={svgRef}
        {...props}
      >
        <path d="M0 0.999999C0 0.447714 0.447715 0 1 0H19C19.5523 0 20 0.447715 20 1V37.6707C20 38.5493 18.9482 39.0008 18.3113 38.3957L10.6887 31.1543C10.3028 30.7876 9.69722 30.7876 9.31125 31.1543L1.68875 38.3957C1.05177 39.0008 0 38.5493 0 37.6707V0.999999Z" />
        <path
          d="M6 20.4588V15.4072C6 15.1117 6.08871 14.8224 6.25556 14.5738L9.09354 10.3455C9.36442 9.94189 9.95525 9.88109 10.3095 10.2204C10.8986 10.7846 11.2295 11.5499 11.2295 12.3479V13.4134C11.2295 14.2646 11.95 14.9546 12.8386 14.9546H15.8618C16.3754 14.9546 16.7575 15.4093 16.6478 15.8898L15.529 20.7882C15.3675 21.4957 14.7133 22 13.9571 22H7.60909C6.72041 22 6 21.31 6 20.4588Z"
          fill={color}
        />
        <path
          d="M4 15.3333V21.3333M6 15.4072V20.4588C6 21.31 6.72041 22 7.60909 22H13.9571C14.7133 22 15.3675 21.4957 15.529 20.7882L16.6478 15.8898C16.7575 15.4093 16.3754 14.9546 15.8618 14.9546H12.8386C11.95 14.9546 11.2295 14.2646 11.2295 13.4134V12.3479C11.2295 11.5499 10.8986 10.7846 10.3095 10.2204C9.95525 9.88109 9.36442 9.94189 9.09354 10.3455L6.25556 14.5738C6.08871 14.8224 6 15.1117 6 15.4072Z"
          fill={color}
          stroke={color}
        />
      </svg>
    )
  }
)
