import type { AxiosPromise } from 'axios'

import { axios } from '../_axios'

import { AuthReqLogin, AuthResLogin, AuthResRefreshToken } from './auth.models'

export const apiAuthLogin = (data: AuthReqLogin): AxiosPromise<AuthResLogin> => {
  return axios({
    url: '/login',
    method: 'post',
    data,
  })
}

export const apiAuthRefreshToken = (refreshToken: string): AxiosPromise<AuthResRefreshToken> => {
  return axios({
    url: '/refresh',
    method: 'post',
    data: {
      refresh_token: refreshToken,
    },
  })
}
