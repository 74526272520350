import { Moon as MoonIcon, Sun as SunIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { IconButton, SvgIcon, Tooltip } from '@mui/material'

import { useSettings } from '../contexts/SettingsContext'

export const SettingsMenu = () => {
  const { t } = useTranslation()
  const { themeMode, onToggleMode } = useSettings()

  return (
    <Tooltip title={t('Common.change_theme')}>
      <IconButton
        color="default"
        onClick={onToggleMode}
        size="large"
        data-testid="theme-change-button"
      >
        <SvgIcon fontSize="small">{themeMode === 'light' ? <MoonIcon /> : <SunIcon />}</SvgIcon>
      </IconButton>
    </Tooltip>
  )
}
