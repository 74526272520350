export interface SystemRole {
  id: number
  key: SystemRoleMap
  name: string
}

export const enum SystemRoleMap {
  Accountant = 'reports',
  Admin = 'admins',
  Agent = 'agents',
  Api = 'api',
  Carrier = 'carriers',
  Dispatcher = 'dispatchers',
  Transport = 'transport',
  User = 'user', // ???
  Operators = 'operators',
}
