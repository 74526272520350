import {
  TicketDetail,
  TicketReqAvailableSeats,
  TicketReqFetchSeatsForTransfer,
  TicketReqPrintPdf,
  TicketReqTransportStatuses,
} from './models'

export const ticketsQueryKeys = {
  all: ['tickets'] as const,
  statuses: ['tickets-statuses'] as const,
  detail: (id: TicketDetail['id']) => [...ticketsQueryKeys.all, id] as const,
  pdf: ({ ticketId, params = {} }: TicketReqPrintPdf) =>
    [...ticketsQueryKeys.all, 'pdf', ticketId, params?.use_main_pdf] as const,
  returnConditions: (ticketId: TicketDetail['id']) =>
    [...ticketsQueryKeys.all, ticketId, 'return_conditions'] as const,
  availableSeats: (params: TicketReqAvailableSeats) =>
    [...ticketsQueryKeys.all, params, 'available-seats'] as const,
  availableSeatsAndTransportsToDate: (params: TicketReqFetchSeatsForTransfer) =>
    [...ticketsQueryKeys.all, params, 'available_seats_and_transports-to-date'] as const,
  transportsStatuses: (params: TicketReqTransportStatuses) =>
    [
      ...ticketsQueryKeys.detail(params.ticketId),
      params.with_details,
      'transports-statuses',
    ] as const,
  availableTransportsToRoute: (ticketId: TicketDetail['id']) =>
    [...ticketsQueryKeys.detail(ticketId), 'transport-to-route'] as const,
}
