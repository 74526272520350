import { AxiosPromise } from 'axios'

import { axios } from '../_axios'
import { DetailType, ListType } from '../_types'

import { CityItem, CityReqCreateItem, CityReqFetchList, CityReqMutateItem } from './city.models'

export const apiCityFetchList = (params: CityReqFetchList): AxiosPromise<ListType<CityItem>> => {
  return axios.get(`/cities`, {
    params,
  })
}

export const apiCityCreate = (data: CityReqCreateItem): AxiosPromise<DetailType<CityItem>> => {
  return axios.post(`/cities`, data)
}

export const apiCityChange = ({
  id,
  data,
}: CityReqMutateItem): AxiosPromise<DetailType<CityItem>> => {
  return axios.put(`/cities/${id}`, data)
}

export const apiCityDelete = (id: CityItem['id']): AxiosPromise => {
  return axios.delete(`/cities/${id}`)
}
