// export const filterNonNumericValue = (value: number) => (isNaN(value) ? undefined : value)
type NumericValue = number | string | boolean | null | undefined | object

export function filterNonNumericValue(value: NumericValue): number | undefined {
  // If value is a number and not NaN, return the number else return undefined
  if (typeof value === 'number' && !isNaN(value) && value !== Infinity && value !== -Infinity) {
    return value
  }
  return undefined
}
