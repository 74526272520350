import { lazy, Suspense, useMemo } from 'react'
import { ExternalLink as LinkIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Button, Grid, Stack, SvgIcon, Typography } from '@mui/material'

import { OrderDetail, StatusTicketEnum, TicketDetail } from '@klr/api-connectors'
import { useAuth } from '@klr/auth'
import {
  Fallback,
  FieldConfig,
  formatToDateString,
  parseUkrDateString,
  TableDetailInfo,
} from '@klr/shared'

import {
  checkIsTicketCancelled,
  getDateInterval,
  getStatusByPartnerAttribute,
} from '../../../../../../tickets.utils'
import { TicketChipStatus } from '../../../../../TicketChipStatus'

import { ActionsTicket } from './components/ActionsTicket'
import { FormChangeDetail } from './components/FormChangeDetail'
import { FormChangeNote } from './components/FormChangeNote'
import { TicketsTransportInfo } from './components/TicketsTransportInfo'

const Activities = lazy(() => import('../../../../../Activities'))

type Props = {
  tripEndDateTime: OrderDetail['trip_start_datetime']
  tripStartDateTime: OrderDetail['trip_end_datetime']
  ticketData: TicketDetail
  disable: boolean
}

export const DetailTicket = ({
  ticketData,
  tripEndDateTime,
  tripStartDateTime,
  disable,
}: Props) => {
  const { t } = useTranslation()
  const { isOperator, permissions } = useAuth()

  const isCancel = useMemo(() => checkIsTicketCancelled(ticketData.status), [ticketData.status])

  const status = getStatusByPartnerAttribute(ticketData.status, ticketData.can_reserve)

  const dateTimeInterval = useMemo(
    () => getDateInterval(tripEndDateTime, tripStartDateTime),
    [tripEndDateTime, tripStartDateTime]
  )

  const routeName =
    (ticketData.trip.route.number ? `${ticketData.trip.route.number} ` : '') +
    ticketData.trip.route.name

  const fieldsDataInfo: FieldConfig[] = [
    {
      label: t('Orders.ticket_status'),
      customRender: (
        <>
          {ticketData.confirmed ? (
            <span style={{ marginRight: 16 }}>
              <TicketChipStatus status={StatusTicketEnum.CONFIRM} />
            </span>
          ) : null}

          <TicketChipStatus status={status} />
        </>
      ),
    },
    {
      label: t('Common.date'),
      text: dateTimeInterval,
    },
    ticketData.station_from?.city?.name && ticketData.station_to?.city?.name
      ? {
          label: t('Common.city'),
          text: `${ticketData.station_from.city.name} - ${ticketData.station_to.city.name}`,
        }
      : null,
    ticketData.station_from?.address && ticketData.station_to?.address
      ? {
          label: t('Auth.address'),
          text: `${ticketData.station_from.address} - ${ticketData.station_to.address}`,
        }
      : null,
    {
      label: t('Booking.checkout.payments_method'),
      text: ticketData.display_paid_form_as,
    },
    routeName
      ? {
          label: t('Booking.trip.route'),
          customRender:
            // for workspace only
            permissions.hasFullAccessToOrder && !isOperator ? (
              <Button
                component={RouterLink}
                endIcon={
                  <SvgIcon fontSize="small">
                    <LinkIcon />
                  </SvgIcon>
                }
                target="_blank"
                rel="noopener noreferrer"
                to={`/sheet/${ticketData.trip.route.id}/?date=${formatToDateString(
                  parseUkrDateString(ticketData.trip.date_start)
                )}`}
              >
                {routeName}
              </Button>
            ) : (
              <span>{routeName}</span>
            ),
        }
      : null,
    ticketData.trip.route.carrier_name
      ? {
          label: t('Booking.trip.carrier'),
          text: ticketData.trip.route.carrier_name,
        }
      : null,
    ticketData.trip.route.carrier_baggage
      ? {
          label: t('Booking.trip.baggage'),
          text: ticketData.trip.route.carrier_baggage,
        }
      : null,
  ].filter(Boolean)

  const fieldsPassengerInfo: FieldConfig[] = [
    {
      label: t('Common.place_capital'),
      text: ticketData.place,
    },
    { label: t('Common.passenger_name'), text: ticketData.first_name },
    { label: t('Auth.last_name'), text: ticketData.last_name },
    { label: t('Auth.phone_number'), text: ticketData.phone },
    { label: t('Auth.email'), text: ticketData.email },
    { label: t('Common.ticket_number'), text: ticketData.ticket_number },
  ]

  return (
    <>
      <Grid item sm={12}>
        <Box mb={3}>
          <TableDetailInfo fieldsArray={fieldsDataInfo} divider="dashed" />
        </Box>
      </Grid>
      {!isCancel ? (
        <Grid item sm={12}>
          <Box mb={3}>
            <ActionsTicket disable={disable} ticketData={ticketData} />
          </Box>
        </Grid>
      ) : null}
      <Grid item sm={12}>
        <Box>
          <Typography variant="h4">{t('Common.passenger_detail')}</Typography>
          {ticketData.status === StatusTicketEnum.DEFERRED ? (
            <FormChangeDetail isAllowEdit={!isCancel} disable={disable} ticketData={ticketData} />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TableDetailInfo fieldsArray={fieldsPassengerInfo} />
              </Grid>
              <Grid item xs={12}>
                <FormChangeNote
                  isAllowEdit={!isCancel}
                  disable={disable}
                  ticketId={ticketData.id}
                  defaultNote={ticketData.information}
                />
              </Grid>
            </Grid>
          )}
          <Grid item sm={12} mt={2}>
            <Stack spacing={3}>
              <Box ml={-2}>
                <TicketsTransportInfo ticket_id={ticketData.id} />
              </Box>

              {permissions.canSeeTicketActivity && (
                <Suspense fallback={<Fallback />}>
                  <Activities ticketId={ticketData.id} />
                </Suspense>
              )}
            </Stack>
          </Grid>
        </Box>
      </Grid>
    </>
  )
}
