import { Floor, SeatCategory, SeatType } from '@klr/api-connectors'

import { Scheme } from '../scheme.types'

export const otherAttributes = {
  type: SeatType.SEAT,
  category: SeatCategory.STANDARD,
}

export const seatMockInArray = (place: number) => ({
  place,
  price_for_category: 100,
  available: true,
  selected: false,
  ...otherAttributes,
})

export const premiumSeatMockInArray = (place: number) => ({
  type: SeatType.SEAT,
  place,
  category: SeatCategory.PREMIUM,
  price_for_category: 200,
  available: true,
  selected: false,
})

export const unavailableSeatMockInArray = (place: number) => ({
  ...seatMockInArray(place),
  available: false,
})

export const selectedSeatMockInArray = (place: number) => ({
  ...seatMockInArray(place),
  available: false,
  selected: true,
})

export const selectedPremiumSeatMockInArray = (place: number) => ({
  ...premiumSeatMockInArray(place),
  available: false,
  selected: true,
})

export const seatMockInObject = (place: number) => ({
  place,
  ...otherAttributes,
})

export const schemeArray: Floor[] = [
  [
    [seatMockInArray(1), seatMockInArray(5)],
    [seatMockInArray(2), { type: SeatType.STAIRS }],
    [{ type: SeatType.EMPTY }, seatMockInArray(6)],
    [seatMockInArray(3), seatMockInArray(7)],
    [seatMockInArray(4), seatMockInArray(8)],
  ],
  [
    [seatMockInArray(9), seatMockInArray(13)],
    [seatMockInArray(10), { type: SeatType.STAIRS }],
    [{ type: SeatType.EMPTY }, seatMockInArray(14)],
    [seatMockInArray(11), seatMockInArray(15)],
    [seatMockInArray(12), seatMockInArray(16)],
  ],
]

export const schemeArrayWithUnavailableSeats: Floor[] = [
  [
    [unavailableSeatMockInArray(1), unavailableSeatMockInArray(5)],
    [seatMockInArray(2), { type: SeatType.STAIRS }],
    [{ type: SeatType.EMPTY }, seatMockInArray(6)],
    [seatMockInArray(3), seatMockInArray(7)],
    [seatMockInArray(4), seatMockInArray(8)],
  ],
  [
    [unavailableSeatMockInArray(9), unavailableSeatMockInArray(13)],
    [seatMockInArray(10), { type: SeatType.STAIRS }],
    [{ type: SeatType.EMPTY }, seatMockInArray(14)],
    [seatMockInArray(11), seatMockInArray(15)],
    [seatMockInArray(12), seatMockInArray(16)],
  ],
]

export const schemeArrayWithNoFreeSeats: Floor[] = [
  [
    [unavailableSeatMockInArray(1), unavailableSeatMockInArray(5)],
    [unavailableSeatMockInArray(2), { type: SeatType.STAIRS }],
    [{ type: SeatType.EMPTY }, unavailableSeatMockInArray(6)],
    [unavailableSeatMockInArray(3), unavailableSeatMockInArray(7)],
    [unavailableSeatMockInArray(4), unavailableSeatMockInArray(8)],
  ],
  [
    [unavailableSeatMockInArray(9), unavailableSeatMockInArray(13)],
    [unavailableSeatMockInArray(10), { type: SeatType.STAIRS }],
    [{ type: SeatType.EMPTY }, unavailableSeatMockInArray(14)],
    [unavailableSeatMockInArray(11), unavailableSeatMockInArray(15)],
    [unavailableSeatMockInArray(12), unavailableSeatMockInArray(16)],
  ],
]

export const schemeArrayWithSelectedSeat: Floor[] = [
  [
    [selectedSeatMockInArray(1), seatMockInArray(5)],
    [seatMockInArray(2), { type: SeatType.STAIRS }],
    [{ type: SeatType.EMPTY }, seatMockInArray(6)],
    [seatMockInArray(3), seatMockInArray(7)],
    [seatMockInArray(4), seatMockInArray(8)],
  ],
  [
    [seatMockInArray(9), seatMockInArray(13)],
    [seatMockInArray(10), { type: SeatType.STAIRS }],
    [{ type: SeatType.EMPTY }, seatMockInArray(14)],
    [seatMockInArray(11), seatMockInArray(15)],
    [seatMockInArray(12), seatMockInArray(16)],
  ],
]

export const schemeArrayWithMultipleSelectedSeats: Floor[] = [
  [
    [selectedSeatMockInArray(1), seatMockInArray(5)],
    [selectedSeatMockInArray(2), { type: SeatType.STAIRS }],
    [{ type: SeatType.EMPTY }, seatMockInArray(6)],
    [seatMockInArray(3), seatMockInArray(7)],
    [seatMockInArray(4), seatMockInArray(8)],
  ],
  [
    [seatMockInArray(9), seatMockInArray(13)],
    [seatMockInArray(10), { type: SeatType.STAIRS }],
    [{ type: SeatType.EMPTY }, seatMockInArray(14)],
    [seatMockInArray(11), seatMockInArray(15)],
    [seatMockInArray(12), seatMockInArray(16)],
  ],
]

export const schemeArrayWithMultiplePremiumSelectedSeats: Floor[] = [
  [
    [selectedSeatMockInArray(1), seatMockInArray(5)],
    [premiumSeatMockInArray(2), { type: SeatType.STAIRS }],
    [{ type: SeatType.EMPTY }, seatMockInArray(6)],
    [seatMockInArray(3), seatMockInArray(7)],
    [seatMockInArray(4), seatMockInArray(8)],
  ],
  [
    [seatMockInArray(9), seatMockInArray(13)],
    [seatMockInArray(10), { type: SeatType.STAIRS }],
    [{ type: SeatType.EMPTY }, seatMockInArray(14)],
    [seatMockInArray(11), seatMockInArray(15)],
    [seatMockInArray(12), seatMockInArray(16)],
  ],
]

export const schemeArrayWithSelectedSeat2: Floor[] = [
  [
    [seatMockInArray(1), seatMockInArray(5)],
    [selectedSeatMockInArray(2), { type: SeatType.STAIRS }],
    [{ type: SeatType.EMPTY }, seatMockInArray(6)],
    [seatMockInArray(3), seatMockInArray(7)],
    [seatMockInArray(4), seatMockInArray(8)],
  ],
  [
    [seatMockInArray(9), seatMockInArray(13)],
    [seatMockInArray(10), { type: SeatType.STAIRS }],
    [{ type: SeatType.EMPTY }, seatMockInArray(14)],
    [seatMockInArray(11), seatMockInArray(15)],
    [seatMockInArray(12), seatMockInArray(16)],
  ],
]

export const schemeArrayWithToggledMultipleSelectedSeats: Floor[] = [
  [
    [selectedSeatMockInArray(1), seatMockInArray(5)],
    [seatMockInArray(2), { type: SeatType.STAIRS }],
    [{ type: SeatType.EMPTY }, seatMockInArray(6)],
    [selectedSeatMockInArray(3), seatMockInArray(7)],
    [seatMockInArray(4), seatMockInArray(8)],
  ],
  [
    [seatMockInArray(9), seatMockInArray(13)],
    [seatMockInArray(10), { type: SeatType.STAIRS }],
    [{ type: SeatType.EMPTY }, seatMockInArray(14)],
    [seatMockInArray(11), seatMockInArray(15)],
    [seatMockInArray(12), seatMockInArray(16)],
  ],
]

export const schemeObject: Scheme = {
  floorCount: 2,
  floor: {
    first: {
      seatsCount: 8,
      row: {
        left: {
          1: seatMockInObject(1),
          2: seatMockInObject(5),
        },
        left_center: {
          1: seatMockInObject(2),
          2: { type: SeatType.STAIRS },
        },
        center: {
          1: { type: SeatType.EMPTY },
          2: seatMockInObject(6),
        },
        right_center: {
          1: seatMockInObject(3),
          2: seatMockInObject(7),
        },
        right: {
          1: seatMockInObject(4),
          2: seatMockInObject(8),
        },
      },
    },
    second: {
      seatsCount: 8,
      row: {
        left: {
          1: seatMockInObject(9),
          2: seatMockInObject(13),
        },
        left_center: {
          1: seatMockInObject(10),
          2: { type: SeatType.STAIRS },
        },
        center: {
          1: { type: SeatType.EMPTY },
          2: seatMockInObject(14),
        },
        right_center: {
          1: seatMockInObject(11),
          2: seatMockInObject(15),
        },
        right: {
          1: seatMockInObject(12),
          2: seatMockInObject(16),
        },
      },
    },
  },
}

export const defaultRow = {
  left: {
    '1': { type: SeatType.EMPTY },
  },
  left_center: {
    '1': { type: SeatType.EMPTY },
  },
  center: {
    '1': { type: SeatType.EMPTY },
  },
  right_center: {
    '1': { type: SeatType.EMPTY },
  },
  right: {
    '1': { type: SeatType.EMPTY },
  },
}

export const defaultRowOnFloor1 = {
  left: {
    '1': { place: 1, ...otherAttributes },
    '2': { place: 5, ...otherAttributes },
    '3': { place: 9, ...otherAttributes },
  },
  left_center: {
    '1': { place: 2, ...otherAttributes },
    '2': { place: 6, ...otherAttributes },
    '3': { place: 10, ...otherAttributes },
  },
  center: {
    '1': { type: SeatType.EMPTY },
    '2': { type: SeatType.EMPTY },
    '3': { type: SeatType.EMPTY },
  },
  right_center: {
    '1': { place: 3, ...otherAttributes },
    '2': { place: 7, ...otherAttributes },
    '3': { place: 11, ...otherAttributes },
  },
  right: {
    '1': { place: 4, ...otherAttributes },
    '2': { place: 8, ...otherAttributes },
    '3': { place: 12, ...otherAttributes },
  },
}

export const defaultRowOnFloor2 = {
  left: {
    '1': { place: 13, ...otherAttributes },
    '2': { place: 17, ...otherAttributes },
    '3': { place: 21, ...otherAttributes },
  },
  left_center: {
    '1': { place: 14, ...otherAttributes },
    '2': { place: 18, ...otherAttributes },
    '3': { place: 22, ...otherAttributes },
  },
  center: {
    '1': { type: SeatType.EMPTY },
    '2': { type: SeatType.EMPTY },
    '3': { type: SeatType.EMPTY },
  },
  right_center: {
    '1': { place: 15, ...otherAttributes },
    '2': { place: 19, ...otherAttributes },
    '3': { place: 23, ...otherAttributes },
  },
  right: {
    '1': { place: 16, ...otherAttributes },
    '2': { place: 20, ...otherAttributes },
    '3': { place: 24, ...otherAttributes },
  },
}

export const defaultRowOnFloor1WithBackSeat = {
  left: {
    '1': { place: 1, ...otherAttributes },
    '2': { place: 5, ...otherAttributes },
    '3': { place: 9, ...otherAttributes },
  },
  left_center: {
    '1': { place: 2, ...otherAttributes },
    '2': { place: 6, ...otherAttributes },
    '3': { place: 10, ...otherAttributes },
  },
  center: {
    '1': { type: SeatType.EMPTY },
    '2': { type: SeatType.EMPTY },
    '3': { place: 11, ...otherAttributes },
  },
  right_center: {
    '1': { place: 3, ...otherAttributes },
    '2': { place: 7, ...otherAttributes },
    '3': { place: 12, ...otherAttributes },
  },
  right: {
    '1': { place: 4, ...otherAttributes },
    '2': { place: 8, ...otherAttributes },
    '3': { place: 13, ...otherAttributes },
  },
}

export const defaultRowOnFloor2WithBackSeat = {
  left: {
    '1': { place: 14, ...otherAttributes },
    '2': { place: 18, ...otherAttributes },
    '3': { place: 22, ...otherAttributes },
  },
  left_center: {
    '1': { place: 15, ...otherAttributes },
    '2': { place: 19, ...otherAttributes },
    '3': { place: 23, ...otherAttributes },
  },
  center: {
    '1': { type: SeatType.EMPTY },
    '2': { type: SeatType.EMPTY },
    '3': { place: 24, ...otherAttributes },
  },
  right_center: {
    '1': { place: 16, ...otherAttributes },
    '2': { place: 20, ...otherAttributes },
    '3': { place: 25, ...otherAttributes },
  },
  right: {
    '1': { place: 17, ...otherAttributes },
    '2': { place: 21, ...otherAttributes },
    '3': { place: 26, ...otherAttributes },
  },
}
