import { Controller } from 'react-hook-form'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import { TextField, TextFieldProps } from '@mui/material'

type RHFNumberInputProps = {
  name: string
  minValue?: number
  maxValue?: number
} & Omit<TextFieldProps, 'defaultValue' | 'value' | 'onChange' | 'type'> &
  Omit<NumericFormatProps, 'customInput' | 'size' | 'ref'>

const getMinValue = (minValue: number | undefined, allowNegative: boolean) => {
  if (minValue) {
    return minValue
  }

  return allowNegative ? -Infinity : 0
}

export const RHFNumberInput = (props: RHFNumberInputProps) => {
  const {
    allowNegative = false,
    name,
    minValue,
    maxValue = Infinity,
    suffix = '',
    ...other
  } = props

  const minInputValue = getMinValue(minValue, allowNegative)

  return (
    <Controller
      name={name}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <NumericFormat
          {...field}
          onChange={() => undefined}
          customInput={TextField}
          isAllowed={({ floatValue }) =>
            floatValue === undefined || (floatValue <= maxValue && floatValue >= minInputValue)
          }
          onValueChange={({ floatValue }) => {
            field.onChange(floatValue ?? null)
          }}
          allowNegative={allowNegative}
          decimalScale={0}
          suffix={suffix}
          fullWidth
          error={!!error}
          helperText={error?.message}
          getInputRef={ref}
          {...other}
        />
      )}
    />
  )
}
