import { ByQuery } from '../_byQuery'

import { NotificationDTO } from './notifications.models'

export const notificationQueryKeys = {
  all: ['notifications'] as const,
  list: (filters: ByQuery.Request<NotificationDTO>) =>
    [...notificationQueryKeys.all, filters] as const,
  templates: () => [...notificationQueryKeys.all, 'templates'] as const,
  telegramChatIds: ['telegram-chat-ids'] as const,
}

export type NotificationQueryKeys = {
  all: readonly ['notifications']
  list: readonly [...NotificationQueryKeys['all'], ByQuery.Request<NotificationDTO>]
  templates: readonly [...NotificationQueryKeys['all'], 'templates']
  telegramChatIds: readonly ['telegram-chat-ids']
}
