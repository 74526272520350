import { useCallback } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'

import {
  apiTicketsCustomReturnPercent,
  TicketDetail,
  TicketReqCustomReturnPercent,
} from '@klr/api-connectors'
import { handleBackendError } from '@klr/shared'

interface UseCustomReturnTicketProps {
  ticketId: TicketDetail['id']
}

export const useCustomReturnTicket = ({ ticketId }: UseCustomReturnTicketProps) => {
  const mutation = useMutation({
    mutationFn: async ({ percent }: TicketReqCustomReturnPercent['data']) => {
      const { data } = await apiTicketsCustomReturnPercent({
        ticketId,
        data: { percent },
      })

      return data.item
    },
    mutationKey: ['tickets', ticketId, 'custom-return'],
    onError: (error) => {
      handleBackendError(error)
    },
    onSuccess: async () => {
      toast.success(`Messages.the_percentage_of_return_is_set`)
    },
  })

  const handleCustomReturnPercent = useCallback(
    (percent: TicketReqCustomReturnPercent['data']['percent']): void => {
      mutation.mutate({ percent })
    },
    [mutation]
  )

  return {
    handleCustomReturnPercent,
    ...mutation,
  }
}
