import { forwardRef, MouseEvent } from 'react'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { TagItem } from '@klr/api-connectors'
import { Label } from '@klr/shared'

import { StyledLabelIcon } from '../../../../../customer-card.styles'

const StyledLabelButton = styled(Label)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  cursor: 'pointer',
}))

interface TagItemProps {
  item: TagItem
  onEdit(event: MouseEvent): void
}

export const CustomerTagItem = forwardRef<HTMLButtonElement, TagItemProps>(
  ({ item, onEdit }, ref) => {
    return (
      <StyledLabelButton
        ref={ref}
        variant="soft"
        color="default"
        startIcon={<StyledLabelIcon ownerState={{ color: item.color || undefined }} />}
        onClick={onEdit}
      >
        <Typography variant="body2">{item.name}</Typography>
      </StyledLabelButton>
    )
  }
)
