import { AxiosPromise } from 'axios'

import { axios } from '../../_axios'
import { DetailType } from '../../_types'
import {
  TicketDetail,
  TicketReqChange,
  TicketReqCreate,
  TicketReqDelete,
  TicketResConfirm,
} from '../models'

export const apiTicketsFetchDetail = (
  ticketId: number | string
): AxiosPromise<DetailType<TicketDetail>> => {
  return axios.get(`/tickets/${ticketId}`)
}

export const apiTicketsChange = ({
  ticketId,
  data,
}: TicketReqChange): AxiosPromise<DetailType<TicketDetail>> => {
  return axios.put(`/tickets/${ticketId}`, data)
}

export const apiTicketsCreate = (data: TicketReqCreate): AxiosPromise<DetailType<TicketDetail>> => {
  return axios.post(`/tickets/`, data)
}

export const apiTicketsDelete = ({ ticketId, params = {} }: TicketReqDelete): AxiosPromise => {
  return axios.delete(`/tickets/${ticketId}`, {
    params,
  })
}

/**
 * Підтвердити квиток
 *
 * @param { TicketReqChange }
 */
export const apiTicketsConfirm = ({
  ticketId,
  data,
}: TicketReqChange): AxiosPromise<TicketResConfirm> => {
  return axios.post(`/tickets/${ticketId}/confirm`, data)
}
