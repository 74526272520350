import { createRouterReducer, ReduxRouterState } from '@lagunovsky/redux-react-router'
import { combineReducers } from '@reduxjs/toolkit'
import { History } from 'history'

import { accountReducer, AccountState } from '@klr/auth'

const rootReducer = (history: History) =>
  combineReducers({
    account: accountReducer,
    router: createRouterReducer(history),
  })

export type RootState = {
  account: AccountState
  router: ReduxRouterState
}
export default rootReducer
