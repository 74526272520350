import { apiAgentsFetchAccount } from '@klr/api-connectors'
import { AuthConsumer, AuthProvider } from '@klr/auth'

import Router from './router'

const App = () => {
  console.log('Welcome to the KLR Bus - Agents App!')

  return (
    <AuthProvider apiFetchAccount={apiAgentsFetchAccount}>
      <AuthConsumer>
        <Router />
      </AuthConsumer>
    </AuthProvider>
  )
}

export default App
