import { memo } from 'react'
import { Stack } from '@mui/material'

import { NavProps } from '../../../shared/types'

import { NavList } from './components/nav-list'

export const NavSectionVertical = memo(({ data, slotProps, ...other }: NavProps) => (
  <Stack component="nav" id="nav-section-vertical" {...other}>
    {data.map((navItem) => (
      <Stack sx={{ px: 2 }} key={navItem.title}>
        <NavList data={navItem} depth={1} slotProps={slotProps} />
      </Stack>
    ))}
  </Stack>
))
