import { NavLink } from 'react-router-dom'
import { Box, Button, ListItem } from '@mui/material'
import { styled } from '@mui/material/styles'

interface StyledButtonLeafProps {
  depth: number
  active: boolean
  component: typeof NavLink
  to: string
}

export const StyledListItem = styled(ListItem)(() => ({
  display: 'block',
  paddingTop: 0,
  paddingBottom: 0,
}))

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'depth',
})<{ active: boolean; depth: number }>(({ theme, active, depth }) => ({
  color: theme.palette.text.secondary,
  padding: theme.spacing(1.25, 1, 1.25, 1 + depth),
  justifyContent: 'flex-start',
  textTransform: 'none',
  letterSpacing: 0,
  width: '100%',
  fontWeight: theme.typography.fontWeightRegular,
  ...(active && {
    color: theme.palette.primary.main,
  }),
}))

export const StyledButtonLeaf = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'depth',
})<StyledButtonLeafProps>(({ theme, active, depth }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: theme.palette.text.secondary,
  padding: theme.spacing(1.25, 1, 1.25, 1 + depth),
  justifyContent: 'flex-start',
  textTransform: 'none',
  letterSpacing: 0,
  width: '100%',
  fontWeight: theme.typography.fontWeightRegular,
  ...(active && {
    color: theme.palette.primary.light,
    fontWeight: theme.typography.fontWeightMedium,
  }),
}))

export const StyledTitle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'depth',
})<{ depth: number }>(({ depth, theme }) => ({
  marginRight: 'auto',
  fontSize: theme.spacing(1.75),
  ...(!depth && {
    fontWeight: theme.typography.fontWeightMedium,
  }),
}))
