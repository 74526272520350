import { create } from 'zustand'

interface SearchCustomerUIStore {
  showDrawer: boolean
  phoneNumber: string
}

export const useSearchCustomerUIStore = create<SearchCustomerUIStore>()((_) => ({
  showDrawer: false,
  phoneNumber: '',
}))

export const toggleSearchCustomerDrawer = (payload: boolean) =>
  useSearchCustomerUIStore.setState(() => ({ showDrawer: payload }))

export const setSearchCustomerPhoneNumber = (payload: string) =>
  useSearchCustomerUIStore.setState(() => ({ phoneNumber: payload }))

export const clearAllSearchCustomer = () =>
  useSearchCustomerUIStore.setState(() => ({ showDrawer: false, phoneNumber: '' }))
