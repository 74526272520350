/**
 * https://mui.com/x/react-data-grid/column-definition/#expand-cell-renderer
 */
import React, { memo, useEffect, useRef, useState } from 'react'
import { Box, Paper, Popper, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledRoot = styled('div')({
  alignItems: 'center',
  lineHeight: '24px',
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
})

interface GridCellExpandProps {
  value: string
  width: number
}

function isOverflown(element: Element): boolean {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth
}

export const GridCellExpand = memo(function GridCellExpand(props: GridCellExpandProps) {
  const { width, value } = props
  const wrapper = useRef<HTMLDivElement | null>(null)
  const cellDiv = useRef(null)
  const cellValue = useRef(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [showFullCell, setShowFullCell] = useState(false)
  const [showPopper, setShowPopper] = useState(false)

  const handleMouseEnter = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const isCurrentlyOverflown = isOverflown(cellValue.current!)
    setShowPopper(isCurrentlyOverflown)
    setAnchorEl(cellDiv.current)
    setShowFullCell(true)
  }

  const handleMouseLeave = () => {
    setShowFullCell(false)
  }

  useEffect(() => {
    if (!showFullCell) {
      return undefined
    }

    function handleKeyDown(nativeEvent: KeyboardEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [setShowFullCell, showFullCell])

  return (
    <StyledRoot ref={wrapper} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Box
        ref={cellDiv}
        sx={{
          height: '100%',
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            style={{ minHeight: wrapper.current!.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </StyledRoot>
  )
})
