import { useMutation, useQueryClient } from '@tanstack/react-query'

import { apiCustomerTagsMutate, customerQueryKeys, TagItem } from '@klr/api-connectors'
import { handleBackendError } from '@klr/shared'

import { useCustomerUIStore } from '../../../../../../../../../../store'

interface UseMutateCustomerTagsProps {
  customerTagIds: TagItem['id'][]
  successCallback(): void
}

export const useMutateCustomerTags = ({
  customerTagIds,
  successCallback,
}: UseMutateCustomerTagsProps) => {
  const customerId = useCustomerUIStore((state) => state.customerId)
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationKey: [...customerQueryKeys.tags(customerId), 'update'],
    mutationFn: apiCustomerTagsMutate,
    onSuccess: (response) => {
      successCallback()
      queryClient.setQueryData(customerQueryKeys.tags(customerId), response.data.data)
    },
    onError: (error) => handleBackendError(error),
  })

  const handleMutate = (tagId: TagItem['id'], isTagAdded: boolean) => {
    if (customerId) {
      mutation.mutate({
        id: customerId,
        data: {
          tags: isTagAdded
            ? [...customerTagIds, tagId]
            : customerTagIds.filter((item) => item !== tagId),
        },
      })
    }
  }

  return { handleMutate, ...mutation }
}
