import { useRef } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosPromise } from 'axios'

import { DetailType, KlrRecord } from '@klr/api-connectors'

import { AppModules } from '../constants'
import { handleBackendError } from '../utils'

interface UseMutateCreateParams<Values, Option extends KlrRecord> {
  queryKey: QueryKey
  createFn: (values: Values) => AxiosPromise<DetailType<Option>>
  toastSuccessMessages?: string
  backendErrorModule?: AppModules
}

export function useMutateCreate<Values, Option extends KlrRecord>({
  queryKey,
  createFn,
  toastSuccessMessages,
  backendErrorModule,
}: UseMutateCreateParams<Values, Option>) {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const loadingToastId = useRef<string>()

  return useMutation({
    mutationFn: createFn,
    mutationKey: [...queryKey, 'create'],
    onMutate: () => {
      loadingToastId.current = toast.loading(t('Common.loading'))
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey).then(() => {
        toast.success(toastSuccessMessages ?? t('Messages.record_successfully_created'))
      })
    },
    onError: (error) => {
      handleBackendError(error, backendErrorModule)
    },
    onSettled: () => {
      if (loadingToastId.current) {
        toast.dismiss(loadingToastId.current)
        loadingToastId.current = undefined
      }
    },
  })
}
