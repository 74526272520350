import { useCallback } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  apiTicketsReturn,
  OrderDetail,
  ordersQueryKeys,
  TicketDetail,
  TicketReqReturn,
  ticketsQueryKeys,
} from '@klr/api-connectors'
import { useConfirm } from '@klr/shared'

export const useReturnTicket = (orderId: OrderDetail['id'], ticketId: TicketDetail['id']) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const confirm = useConfirm()

  const mutation = useMutation({
    mutationFn: async (variables: TicketReqReturn) => {
      const { data } = await apiTicketsReturn(variables)

      return data.item
    },
    mutationKey: ['tickets', 'return'],
    onError: () => {
      // TODO: move message to the module
      toast.error(t('Messages.failed_to_return_ticket'))
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries(ordersQueryKeys.detail(orderId))
      await queryClient.invalidateQueries(ticketsQueryKeys.returnConditions(variables.ticketId))

      toast.success(t('Messages.ticket_returned_with_sum', { sum: data.price_returned }))
    },
  })

  const handleReturnTicket = useCallback(
    (percent: number | null = null): void => {
      const request: TicketReqReturn = {
        ticketId,
      }

      let message = t('Messages.return_according_to_the_return_rules')

      if (percent) {
        request.data = {
          percent,
        }

        message = t('Messages.will_partial_returning', {
          percent,
        })
      }

      confirm({
        title: t('Messages.should_return_ticket'),
        description: message,
      }).then((success) => {
        if (success) {
          mutation.mutate(request)
        }
      })
    },
    [confirm, mutation, t, ticketId]
  )

  return {
    handleReturnTicket,
    ...mutation,
  }
}
