import { X as CloseIcon } from 'react-feather'
import { DialogTitle, IconButton, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}))

interface DialogHeaderProps {
  title: string
  closeDrawer?: VoidFunction
}

export const DialogHeader = ({ closeDrawer, title }: DialogHeaderProps) => (
  <DialogTitle sx={{ m: 0, p: 2 }}>
    <Typography variant="h4" component="span">
      {title}
    </Typography>
    <Tooltip title="Закрити">
      <StyledCloseButton aria-label="close" onClick={closeDrawer} size="large">
        <CloseIcon fontSize="large" />
      </StyledCloseButton>
    </Tooltip>
  </DialogTitle>
)
