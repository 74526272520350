import { Pagination } from '../_types'

export enum Operators {
  EQUAL = 'eq',
  NOT_EQUAL = 'ne',
  GREATER_THEN = 'gt',
  LESS_THEN = 'lt',
  IN = 'in',
  NOT_IN = 'nin',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'ncontains',
  STARTS_WITH = 'startswith',
  ENDS_WITH = 'endswith',
  IS_NULL = 'isnull',
  NOT_IS_NULL = 'notisnull',
}

export type FilterValue = string | number | boolean | Date | number[] | string[]

export interface Filter<R> {
  field: keyof R
  operator: Operators
  value: FilterValue
}

export type Filters<R> = Filter<R>[]

export type Order = 'asc' | 'desc'

export interface Sort<R> {
  field: keyof R
  order: Order
}

export interface Request<R> extends Partial<Pagination> {
  filters?: Filters<R>
  orderBy?: Sort<R>
}

export interface ApiResponse<R> {
  count: number
  items: R[]
}
