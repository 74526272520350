import { Theme } from '@mui/material'

export const customScrollbar = (theme: Theme) => ({
  width: 8,
  height: 8,
  '&-track': {
    backgroundColor: theme.palette.grey[500],
  },
  '&-thumb': {
    backgroundColor: theme.palette.grey[700],

    '&:hover': {
      backgroundColor: theme.palette.grey[800],
    },
  },
})
