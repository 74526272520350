import { forwardRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Popover, Stack, Tooltip } from '@mui/material'

import { colors } from './colors.config'
import { StyledColorPickButton, StyledIconButton, StyledLabelIcon } from './tag-colors.styles'

export const TagColorPicker = forwardRef<HTMLDivElement>((_, ref) => {
  const [anchorColor, setAnchorColor] = useState<HTMLButtonElement | null>(null)
  const { setValue, watch } = useFormContext()
  const currentColor = watch('color')

  return (
    <div ref={ref}>
      <Tooltip title="Виберіть колір">
        <StyledIconButton
          onClick={(event) => {
            setAnchorColor(event.currentTarget)
          }}
        >
          <StyledLabelIcon ownerState={{ color: currentColor }} />
        </StyledIconButton>
      </Tooltip>
      <Popover
        open={!!anchorColor}
        anchorEl={anchorColor}
        onClose={() => setAnchorColor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Stack direction="row" spacing={2} sx={{ p: 2 }}>
          {colors.map((item) => (
            <StyledColorPickButton
              key={item}
              ownerState={{ bgColor: item }}
              onClick={() => {
                setValue('color', item)
                setAnchorColor(null)
              }}
            />
          ))}
        </Stack>
      </Popover>
    </div>
  )
})
