import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'

type Props = {
  activeFloor: number
  floorCount: number
  floors: number[]
  setActiveFloor: (floorIndex: number) => void
}

export const ToggleFloors = ({ activeFloor, floorCount, floors, setActiveFloor }: Props) => {
  const { t } = useTranslation()
  const handleChange = (_: MouseEvent<HTMLElement>, newAlignment: string) => {
    setActiveFloor(Number(newAlignment))
  }

  if (floorCount < 1) {
    return null
  }

  return (
    <ToggleButtonGroup
      color="primary"
      value={activeFloor.toString()}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      {floors.map((floor) => (
        <ToggleButton size="small" key={floor} value={floor.toString()}>
          {t('Common.n_floor', { floor: floor + 1 })}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
