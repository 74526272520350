import React from 'react'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'hasDivider',
})<{ hasDivider: boolean }>(({ hasDivider, theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  borderBottom: hasDivider ? '1px dashed #ccc' : 'none',
}))

type Props = {
  navFieldsArray: string[]
  fieldsArray: (string | number)[][]
  hasDivider: boolean
}

export const TableDetailInfoWithMultipleColumn = ({
  navFieldsArray,
  fieldsArray,
  hasDivider,
}: Props) => {
  const _renderTableRows = (tableFields: (string | number)[][]) => {
    return tableFields.map((items, idx) => (
      <TableRow key={idx}>
        {items.map((item, sub_idx) => (
          <StyledTableCell key={`${idx}_${sub_idx}`} hasDivider={hasDivider}>
            {item}
          </StyledTableCell>
        ))}
      </TableRow>
    ))
  }

  return (
    <Table size="small">
      <TableBody>
        {_renderTableRows([navFieldsArray])}
        {_renderTableRows(fieldsArray)}
      </TableBody>
    </Table>
  )
}
