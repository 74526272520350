import { format, isValid } from 'date-fns'
import { enGB as en, pl, ru, uk } from 'date-fns/locale'

import { DateType } from '@klr/api-connectors'

const getLocale = () => {
  const langStorage = localStorage.getItem('i18nextLng')

  return [en, uk, pl, ru].find((item) => {
    if (item.code === 'en-GB') {
      return langStorage === 'en'
    }

    return item.code === langStorage
  })
}

// format Date or Timestamp to 'yyyy-MM-dd' | '2023-12-01'
export const formatToDateString = (date: Date): string =>
  format(new Date(date), 'yyyy-MM-dd', { locale: getLocale() })

// to 'dd.MM.yyyy' | '01.12.2023'
export const formatToUkrDateString = (date: Date | DateType): string => {
  const dateObj = new Date(date)
  if (!isValid(dateObj)) return 'Invalid date format'

  return format(dateObj, 'dd.MM.yyyy', { locale: getLocale() })
}

// to 'dd.MM HH:mm' | "01.12 00:00"
export const formatToUkrShortDateTimeString = (date: Date): string =>
  format(new Date(date), 'dd.MM HH:mm', { locale: getLocale() })

// to 'dd.MM.yyyy HH:mm' | "01.12.2023 00:00"
export const formatToUkrDateTimeString = (date: Date | DateType): string => {
  const dateObj = new Date(date)
  if (!isValid(dateObj)) return 'Invalid date format'

  return format(dateObj, 'dd.MM.yyyy HH:mm', { locale: getLocale() })
}

// to 'yyyy-MM-dd HH:mm:ss | '2023-12-01 00:00:00'
export const formatToFullDateTimeString = (date: Date | DateType): string =>
  format(new Date(date), 'yyyy-MM-dd HH:mm:ss', { locale: getLocale() })

// to 'dd MMM.' | '01 dec.'
export const formatToShortDayMonthString = (date: Date): string =>
  format(new Date(date), 'dd MMM', { locale: getLocale() })

// to 'dd MMMM' | '01 december'
export const formatToDayMonthString = (date: Date): string =>
  format(new Date(date), 'dd MMMM', { locale: getLocale() })

// to 'dd MMMM yyyy' | '01 december 2023'
export const formatToFullDateString = (date: Date | DateType): string =>
  format(new Date(date), 'dd MMMM yyyy', { locale: getLocale() })

// to 'dd MMMM HH:mm' | '01 december 00:00'
export const formatToDateTimeString = (date: Date): string =>
  format(new Date(date), 'dd MMMM HH:mm', { locale: getLocale() })

// to 'HH:mm dd.MM.yy' | "00:00 01.12.23"
export const formatToUkrTimeDateString = (date: Date): string =>
  format(new Date(date), 'HH:mm dd.MM.yy', { locale: getLocale() })

// to 'dd MMMM HH:mm:ss' | '01 december 00:00:00'
export const formatToUkrFullDateTimeString = (date: Date | DateType): string =>
  format(new Date(date), 'dd MMMM HH:mm', { locale: getLocale() })

// to "HH:mm" | "00:00"
export const formatToTimeString = (date: Date): string => format(new Date(date), 'HH:mm')

/**
 * @description validate date for queryParam
 * @param date {Date | null} - queryParam date
 * @returns {string | undefined} - 'yyyy-MM-dd' | '2023-12-01' or undefined
 */
export const validateQueryParamDate = (date: Date | null): string | undefined => {
  if (!date || !isValid(date)) {
    return undefined
  }
  return formatToDateString(date)
}

// check is given date exists or valid, otherwise return current date
export const checkDateIsValid = (date: Date | null): Date => {
  return date && isValid(date) ? date : new Date()
}

/**
 * @description get a date format for table column
 * @param date - Date that comes from server in format ISO 8601 - 2023-07-31T00:30:00.000000Z
 * @param shortYearMarker - we add short year marker to the end of the formatted date result
 * @returns {string} - "HH:mm dd.MM.yy" | "00:00 01.12.23" and short year marker
 */
export const formatColumnDate = (date: Date, shortYearMarker: string): string => {
  return isValid(new Date(date)) ? formatToUkrTimeDateString(date) + shortYearMarker : ''
}
