import { SystemCodeLanguages } from '../../i18n'

export const formatCurrency = (
  int: number,
  currency: string,
  codeLang: SystemCodeLanguages = 'uk-UA'
): string => {
  try {
    const formatter = new Intl.NumberFormat(codeLang, {
      style: 'currency',
      currency,
    })

    return formatter.format(int)
  } catch (e) {
    console.error('Error in formatCurrency:', e)
    return int.toString()
  }
}
