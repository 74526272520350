import React from 'react'
import { Drawer as MuiDrawer, drawerClasses, DrawerProps as MuiDrawerProps } from '@mui/material'
import { styled } from '@mui/material/styles'

interface DrawerProps extends Omit<MuiDrawerProps, 'open'> {
  open: boolean
  width?: number
}

const StyledDrawer = styled(MuiDrawer)<DrawerProps>(({ theme, width }) => ({
  [`& .${drawerClasses.paper}`]: {
    maxWidth: '100%',
    width: width,
    padding: theme.spacing(2.5, 3, 7.5),
    boxShadow: '0 1px 8px 0 rgba(0,0,0,0.08)',
    borderRadius: theme.spacing(1),
    height: 'calc(100% - 24px)',
    overflowY: 'auto',
    margin: '10px 10px 10px 0',
    [theme.breakpoints.down('md')]: {
      maxHeight: '100%',
      height: '100%',
      width: '100%',
      margin: 0,
      borderRadius: 0,
    },
  },
}))

export const Drawer = (props: DrawerProps) => {
  const { children, open, width = 650, ...other } = props

  return (
    <StyledDrawer anchor="right" {...other} open={open} width={width}>
      {open && children}
    </StyledDrawer>
  )
}
