import {
  enUS as coreEnUS,
  plPL as corePlPL,
  ruRU as coreRuRU,
  ukUA as coreUkUA,
} from '@mui/material/locale'
import {
  enUS as dataGridEnUS,
  plPL as dataGridPlPL,
  ruRU as dataGridRuRU,
  ukUA as dataGridUkUA,
} from '@mui/x-data-grid-premium'
import {
  enUS as datePickerEnUS,
  plPL as datePickerPlPL,
  ruRU as datePickerRuRU,
  ukUA as datePickerUkUA,
} from '@mui/x-date-pickers-pro'

import { SystemLanguage } from './i18n.types'

export const SYSTEM_LANGUAGES: SystemLanguage[] = [
  {
    label: 'Українська',
    value: 'uk',
    code: 'uk-UA',
    coreMui: coreUkUA,
    dataGrid: dataGridUkUA,
    datePicker: datePickerUkUA,
    icon: '/static/icons/ic_flag_ukraine.svg',
  },
  {
    label: 'English',
    value: 'en',
    code: 'en-US',
    coreMui: coreEnUS,
    dataGrid: dataGridEnUS,
    datePicker: datePickerEnUS,
    icon: '/static/icons/ic_flag_united-kingdom.svg',
  },
  {
    label: 'Polskie',
    value: 'pl',
    code: 'pl-PL',
    coreMui: corePlPL,
    dataGrid: dataGridPlPL,
    datePicker: datePickerPlPL,
    icon: '/static/icons/ic_flag_poland.svg',
  },
  {
    label: 'Русский',
    value: 'ru',
    code: 'ru-RU',
    coreMui: coreRuRU,
    dataGrid: dataGridRuRU,
    datePicker: datePickerRuRU,
    icon: '',
  },
]
