import { QueryFunctionContext } from '@tanstack/react-query'

import { getQueryPagination } from '../_byQuery'
import { ListType } from '../_types'

import { getActivityLogs } from './activity-logs.api'
import { ActivityLogItem, ReqGetActivityLogs } from './activity-logs.models'

export const activityLogsQueryKeys = {
  all: ['activity-logs'] as const,
  list: (request: Partial<ReqGetActivityLogs> = {}) =>
    [...activityLogsQueryKeys.all, request] as const,
  events: ['activity-logs-events'] as const,
  subjects: ['activity-logs-subjects'] as const,
  causers: ['activity-logs-causers'] as const,
}

export type ActivityLogsQueryKeys = {
  all: readonly ['activity-logs']
  list: readonly [...ActivityLogsQueryKeys['all'], Partial<ReqGetActivityLogs>]
  events: readonly ['activity-logs-events']
  subjects: readonly ['activity-logs-subjects']
  causers: readonly ['activity-logs-causers']
}

export const activityLogsQueryFns = {
  getActivityLogsList: async (
    context: QueryFunctionContext<ActivityLogsQueryKeys['list']>
  ): Promise<ListType<ActivityLogItem>> => {
    const {
      pageParam = 1,
      queryKey: [, request],
    } = context

    const payload: Partial<ReqGetActivityLogs> = {
      ...request,
      ...getQueryPagination({ pageParam }),
    }

    const { data } = await getActivityLogs(payload)
    return data
  },
}
