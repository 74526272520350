import { BusCell } from '@klr/api-connectors'
import { AxisPosition, StyledPlace, TransportPlaceLock } from '@klr/schemes'

import { PlaceSwitch } from './PlaceSwitch'

export type PlaceProps = {
  axisPosition: AxisPosition
  onToggle: TransportPlaceLock
  seat: BusCell
  index: string
  isSingleSelected: boolean
  selectedByCurrentPassenger: boolean
  selectedByOtherPassenger: boolean
  transportId: number
}

export const Place = (props: PlaceProps) => {
  return (
    <StyledPlace>
      <PlaceSwitch {...props} />
    </StyledPlace>
  )
}
