import { AxiosPromise } from 'axios'

import { axios } from '../_axios'
import { DetailType, InfoType, ListType } from '../_types'

import {
  BookingCheckedPlaces,
  BookingItem,
  BookingPriceItem,
  BookingReqFetchDetail,
  BookingReqFetchList,
  BookingReqFetchPrice,
  BookingReqFetchTicketTransportInfo,
  BookingTicketsTransportInfo,
} from './models'

export const apiBookingFetchList = (
  params: BookingReqFetchList
): AxiosPromise<ListType<BookingItem>> => {
  return axios({
    url: '/booking/search',
    method: 'get',
    params,
  })
}

export const apiBookingFetchDetail = ({
  search_id,
  params,
}: BookingReqFetchDetail): AxiosPromise<DetailType<BookingItem>> => {
  return axios({
    url: `/booking/search/${search_id}`,
    method: 'get',
    params,
  })
}

export const apiBookingFetchPrice = ({
  search_id,
  params,
}: BookingReqFetchPrice): AxiosPromise<DetailType<BookingPriceItem>> => {
  return axios({
    url: `/booking/search/${search_id}/price`,
    method: 'get',
    params,
  })
}

export const apiBookingCheckPlaces = (
  searchId: string
): AxiosPromise<ListType<BookingCheckedPlaces>> => {
  return axios({
    url: `/booking/search/${searchId}/check-places`,
    method: 'get',
  })
}

export const apiBookingFetchTicketTransportInfo = (
  params: BookingReqFetchTicketTransportInfo
): AxiosPromise<InfoType<BookingTicketsTransportInfo>> => {
  return axios({
    url: '/booking/get_ticket_transport_info',
    method: 'post',
    params,
  })
}
