import { forwardRef, Ref } from 'react'
import { useTranslation } from 'react-i18next'

import { AgentItem, agentQueryFns, AgentQueryKeys, agentQueryKeys } from '@klr/api-connectors'
import { ConnectedAutocomplete, ConnectedAutocompleteFabricProps } from '@klr/shared'

export const ConnectedAutocompleteAgents = forwardRef(
  (
    props: ConnectedAutocompleteFabricProps<AgentItem, AgentQueryKeys['list']>,
    ref: Ref<AgentItem>
  ) => {
    const { t } = useTranslation()
    const { textFieldProps, label = t('Common.agent'), value, onChange, ...rest } = props

    return (
      <ConnectedAutocomplete<AgentItem, AgentQueryKeys['list']>
        queryKey={agentQueryKeys.list({ is_active: 1 })}
        queryFn={agentQueryFns.getAgentList}
        queryOptions={{
          retryOnMount: false,
          refetchOnMount: false,
        }}
        getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
        isOptionEqualToValue={(option, _value) => option.id === _value.id}
        value={value}
        onChange={(_, newValue) => {
          onChange(newValue)
        }}
        {...rest}
        textFieldProps={{
          ...textFieldProps,
          label,
          inputRef: ref,
        }}
      />
    )
  }
)
