import React, { ReactNode, useMemo } from 'react'
import { Toaster } from 'react-hot-toast'
import { CssBaseline } from '@mui/material'
import {
  createTheme,
  StyledEngineProvider,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles'

import { useSettings } from '../contexts/SettingsContext'

import { customShadows } from './custom-shadows'
import { componentsOverrides } from './overrides'
import { palette } from './palette'
import { shadows } from './shadows'
import { typography } from './typography'

interface Props {
  isLight?: boolean
  children: ReactNode
}

export const ThemeProvider = ({ children }: Props) => {
  const { themeMode } = useSettings()

  const memoizedValue = useMemo(
    () => ({
      palette: palette(themeMode),
      typography,
      shadows: shadows(themeMode),
      shape: { borderRadius: 8 },
      customShadows: customShadows(themeMode),
    }),
    [themeMode]
  )

  const theme = createTheme(memoizedValue as ThemeOptions)
  theme.components = componentsOverrides(theme)

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster
          toastOptions={{
            style: {
              borderRadius: 10,
              background: theme.palette.background.paper,
              color: theme.palette.text.primary,
              fontFamily: theme.typography.fontFamily,
            },
          }}
        />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}
