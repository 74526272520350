import { ReactNode } from 'react'
import { Box, Typography } from '@mui/material'
import { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  border: `2px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
}))

const StyledTypography = styled(Typography)(({ theme }) => {
  const isLight = theme.palette.mode === 'light'

  return {
    color: isLight ? theme.palette.grey[200] : theme.palette.text.primary,
    borderRadius: '10px',
    padding: theme.spacing(0, 1),
    fontSize: theme.typography.pxToRem(12),
    backgroundColor: theme.palette.grey[700],
  }
})

interface PanelProps extends Omit<BoxProps, 'component'> {
  title: string
  children: ReactNode
}

export const MuiLegend = ({ title, children, ...boxProps }: PanelProps) => (
  <StyledBox component="fieldset" {...boxProps}>
    <StyledTypography as="legend">{title}</StyledTypography>
    {children}
  </StyledBox>
)
