import { ReactNode } from 'react'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const Root = styled('div')(({ theme }) => ({
  width: theme.spacing(7),
  height: theme.spacing(7),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const StyledIconContainer = styled('div')(({ theme }) => ({
  width: 36,
  height: 36,
  marginBottom: theme.spacing(0.5),
  position: 'relative',
}))

const StyledFirstSvgPath = styled('path')(({ theme }) => ({
  stroke: theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.15)' : 'rgba(0,0,0,0.05)',
  fill: 'none',
  strokeWidth: 4,
}))

const StyledSecondSvgPath = styled('path', {
  shouldForwardProp: (prop) => prop !== 'value',
})<{ value: number }>(({ value, theme }) => ({
  stroke: theme.palette.success.main,
  fill: 'none',
  strokeWidth: 4,
  animation: '$progress 1s ease-out forwards',

  '@keyframes progress': {
    '0%': {
      strokeDasharray: '0 100',
    },
  },
  ...(value < 70 && {
    stroke: theme.palette.warning.main,
  }),
}))

const StyledTypography = styled(Typography)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}))

interface CircularProgressProps {
  value: number
  centerValue: number
  renderContent?: ReactNode | null
}

export const CircularProgress = ({
  value,
  centerValue,
  renderContent = null,
}: CircularProgressProps) => {
  return (
    <Root>
      <StyledIconContainer>
        <svg viewBox="0 0 36 36">
          <StyledFirstSvgPath
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            strokeDasharray="100, 100"
          />

          <StyledSecondSvgPath
            value={value}
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            strokeDasharray={`${value}, 100`}
          />
        </svg>
        <StyledTypography variant="caption">{centerValue}</StyledTypography>
      </StyledIconContainer>
      {renderContent}
    </Root>
  )
}
