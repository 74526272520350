import { lazy } from 'react'

import { Loadable } from '../router-helpers/components'

const NotFound = Loadable(lazy(() => import('./404')))

export const sharedRoutes = {
  path: '404',
  element: <NotFound />,
}
