import { TFunction } from 'react-i18next'
import * as Yup from 'yup'

import { validatePhone, validateRequiredStringField } from '@klr/shared'

export const formSchema = (t: TFunction) =>
  Yup.object().shape({
    phone: validatePhone(t),
    message: validateRequiredStringField(t),
  })

export interface FieldValues {
  phone: string
  message: string
}
