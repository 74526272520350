import { useCallback, useEffect } from 'react'
import toast from 'react-hot-toast'
import { Button, Snackbar } from '@mui/material'
import { useRegisterSW } from 'virtual:pwa-register/react'

const intervalMS = 60 * 60 * 1000

const SW = () => {
  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      r &&
        setInterval(async () => {
          if (!(!r.installing && navigator)) return

          if ('connection' in navigator && !navigator.onLine) return

          const resp = await fetch(swUrl, {
            cache: 'no-store',
            headers: {
              cache: 'no-store',
              'cache-control': 'no-cache',
            },
          })

          if (resp?.status === 200) await r.update()
        }, intervalMS)
    },
  })

  const close = useCallback(() => {
    setOfflineReady(false)
    setNeedRefresh(false)
  }, [setOfflineReady, setNeedRefresh])

  useEffect(() => {
    if (offlineReady) {
      toast('App has been installed, it now works!', {
        icon: '🥳',
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      })
    }
  }, [offlineReady])

  const _updateServiceWorker = () => {
    updateServiceWorker()
    close()
  }

  return (
    <Snackbar
      open={needRefresh}
      message="Доступна нова версія!"
      onClick={_updateServiceWorker}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={
        <Button color="inherit" size="small" onClick={_updateServiceWorker}>
          Оновити
        </Button>
      }
    />
  )
}

export default SW
