import { forwardRef, Ref } from 'react'

type Props = {
  color?: string
  size?: number
}

export const DriverWheelIcon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }: Props, svgRef?: Ref<SVGSVGElement>) => {
    return (
      <svg
        ref={svgRef}
        width={size}
        height={size}
        viewBox="0 0 50 50"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke={color}
        strokeWidth="1"
        {...rest}
      >
        <path d="M 25 2 C 12.354545 2 2 12.354545 2 25 C 2 37.645455 12.354545 48 25 48 C 37.645455 48 48 37.645455 48 25 C 48 12.354545 37.645455 2 25 2 z M 25 4 C 36.554545 4 46 13.445455 46 25 C 46 36.554545 36.554545 46 25 46 C 13.445455 46 4 36.554545 4 25 C 4 13.445455 13.445455 4 25 4 z M 25 7 C 15.070719 7 7 15.070719 7 25 C 7 34.929281 15.070719 43 25 43 C 34.929281 43 43 34.929281 43 25 C 43 15.070719 34.929281 7 25 7 z M 25 9 C 32.457069 9 38.690911 14.086769 40.472656 20.980469 C 37.022042 20.877633 35.022012 19.810604 32.972656 18.632812 C 30.817748 17.39436 28.552525 16 25 16 C 21.447475 16 19.287355 17.4054 17.203125 18.638672 C 15.214866 19.815156 13.223927 20.883436 9.5273438 20.980469 C 11.309089 14.086769 17.542931 9 25 9 z M 25 18 C 28.047475 18 29.781471 19.10564 31.976562 20.367188 C 34.146385 21.614211 36.737437 22.963126 40.861328 22.994141 C 40.943513 23.652355 41 24.319213 41 25 C 41 26.026543 40.894932 27.02765 40.710938 28 L 34 28 C 30.732099 28 28 30.732099 28 34 L 28 40.710938 C 27.02765 40.894932 26.026543 41 25 41 C 23.973457 41 22.97235 40.894932 22 40.710938 L 22 34 C 22 30.7 19.405747 28 16 28 L 9.2890625 28 C 9.1050679 27.02765 9 26.026543 9 25 C 9 24.319213 9.0564869 23.652355 9.1386719 22.994141 C 13.497158 22.964648 16.105288 21.614209 18.222656 20.361328 C 20.363426 19.0946 21.952525 18 25 18 z M 25 20 C 22.250421 20 20 22.250421 20 25 C 20 27.749579 22.250421 30 25 30 C 27.749579 30 30 27.749579 30 25 C 30 22.250421 27.749579 20 25 20 z M 25 22 C 26.668699 22 28 23.331301 28 25 C 28 26.668699 26.668699 28 25 28 C 23.331301 28 22 26.668699 22 25 C 22 23.331301 23.331301 22 25 22 z M 9.8066406 30 L 16 30 C 18.394253 30 20 31.7 20 34 L 20 40.193359 C 15.1836 38.61274 11.38726 34.8164 9.8066406 30 z M 34 30 L 40.193359 30 C 38.61274 34.8164 34.8164 38.61274 30 40.193359 L 30 34 C 30 31.867901 31.867901 30 34 30 z" />
      </svg>
    )
  }
)
