import { lazy } from 'react'

import { Loadable } from '@klr/shared'

const OrderList = Loadable(lazy(() => import('./pages/Orders')))

export const orderTicketToolkitRoutes = {
  path: 'order-list',
  element: <OrderList />,
}
