import React, { FC } from 'react'
import { ArrowRight as ArrowRightIcon, IconProps } from 'react-feather'
import { Link as RouterLink } from 'react-router-dom'
import { IconButton, SvgIcon, Tooltip } from '@mui/material'

type Props = {
  onClick?: VoidFunction
  to?: string
  label?: string
  icon?: FC<IconProps>
}

export const SelectionCell = React.memo(function SelectionCell({
  onClick,
  to,
  label = '',
  icon,
}: Props) {
  const Icon = icon ? icon : ArrowRightIcon

  const IconSvg = (
    <SvgIcon fontSize="small">
      <Icon />
    </SvgIcon>
  )

  if (to) {
    return (
      <Tooltip title={label} aria-label={label}>
        <IconButton component={RouterLink} to={to} size="large">
          {IconSvg}
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <Tooltip title={label} aria-label={label}>
      <IconButton onClick={onClick} size="large">
        {IconSvg}
      </IconButton>
    </Tooltip>
  )
})
