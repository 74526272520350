import { useState } from 'react'
import { ExternalLink as LinkIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { Mail as MailIcon } from '@mui/icons-material'
import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material'

import { BookingDiscounts, BookingItem, BookingOptions } from '@klr/api-connectors'
import { useAuth } from '@klr/auth'
import { TicketSendMessageModal } from '@klr/message-dispatch'

import { useMapTripBenefits } from '../../../hooks/useMapTripBenefits'

interface DiscountProps {
  discounts: BookingDiscounts[]
}
interface BenefitProps {
  benefits: BookingOptions[]
}

type Props = {
  hasSmsForm: boolean
  phoneNumber: string
  tripData: BookingItem
}

export const MainInfo = ({ hasSmsForm, phoneNumber, tripData }: Props) => {
  const [openSendMessageModal, setOpenSendMessageModal] = useState(false)
  const { t } = useTranslation()
  const { permissions } = useAuth()

  const routeName = (tripData.route_number ? `${tripData.route_number} ` : '') + tripData.route_name

  const data = [
    {
      label: t('Booking.trip.route'),
      value: !permissions.canSeeSheetLink ? (
        routeName
      ) : (
        <Button
          component={RouterLink}
          endIcon={
            <SvgIcon fontSize="small">
              <LinkIcon />
            </SvgIcon>
          }
          target="_blank"
          to={`/sheet/${tripData.route_id}/?date=${tripData.trip_date_start}`}
        >
          {routeName}
        </Button>
      ),
    },
    { label: t('Booking.trip.periodicity'), value: tripData.frequency },
    { label: t('Booking.trip.luggage_available'), value: tripData.carrier_baggage },
    {
      label: '',
      value: t('Booking.trip.departure_and_arrival_according_to_local_time'),
    },
  ]

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <List
            subheader={<ListSubheader component="div">{t('Common.main_info')}</ListSubheader>}
            dense
          >
            {data.map(
              (item, idx) =>
                !!item.value && (
                  <ListItem key={idx}>
                    <ListItemText primary={item.label} secondary={item.value} />
                  </ListItem>
                )
            )}
          </List>
        </Grid>
        <Grid item xs={12} md={4}>
          <BenefitList benefits={tripData.options} />
        </Grid>

        <Grid item xs={12} md={4}>
          <DiscountList discounts={tripData.discounts} />
        </Grid>

        {hasSmsForm && (
          <Grid item>
            <Tooltip title={t('Orders.send_message')} sx={{ mt: 2 }}>
              <IconButton color="primary" onClick={() => setOpenSendMessageModal(true)}>
                <MailIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      {hasSmsForm && (
        <TicketSendMessageModal
          handleClose={() => setOpenSendMessageModal(false)}
          open={openSendMessageModal}
          phoneNumber={phoneNumber}
        />
      )}
    </>
  )
}

const BenefitList = ({ benefits }: BenefitProps) => {
  const { t } = useTranslation()
  const BENEFITS = useMapTripBenefits()

  if (!benefits?.length) {
    return null
  }

  return (
    <List
      subheader={<ListSubheader component="div">{t('Booking.trip.trip_benefits')}:</ListSubheader>}
      dense
    >
      {benefits.map((item, idx) => {
        if (item === 'no_printed_ticket') {
          return null
        }

        if (!BENEFITS[item]) return null

        const Icon = BENEFITS[item].icon

        return (
          <ListItem key={idx}>
            <ListItemIcon>
              <SvgIcon fontSize="small" color="action">
                <Icon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText primary={BENEFITS[item].label} />
          </ListItem>
        )
      })}
    </List>
  )
}

const DiscountList = ({ discounts }: DiscountProps) => {
  const { t } = useTranslation()

  if (!discounts?.length) {
    return null
  }

  return (
    <List subheader={<ListSubheader component="div">{t('Common.discounts')}:</ListSubheader>} dense>
      {discounts.map((item) => {
        return (
          !!item.value && (
            <ListItem key={item.discount_id}>
              <ListItemAvatar>
                <Typography variant="body2">{item.value}%</Typography>
              </ListItemAvatar>
              <ListItemText primary={item.title} />
            </ListItem>
          )
        )
      })}
    </List>
  )
}
