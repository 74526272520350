import { Operators } from '../query.types'

export enum FilterSelectOperator {
  EQUALS = 'is',
  NOT_EQUALS = 'not',
}

export enum FilterOperator {
  EQUALS = 'equals',
  CONTAINS = 'contains',
  STARTS_WITH = 'startsWith',
  ENDS_WITH = 'endsWith',
  IS_EMPTY = 'isEmpty',
  IS_NOT_EMPTY = 'isNotEmpty',
}

export const mapOperatorMuiToServer: Record<FilterOperator | FilterSelectOperator, Operators> = {
  [FilterOperator.CONTAINS]: Operators.CONTAINS,
  [FilterOperator.EQUALS]: Operators.EQUAL,
  [FilterOperator.STARTS_WITH]: Operators.STARTS_WITH,
  [FilterOperator.ENDS_WITH]: Operators.ENDS_WITH,
  [FilterOperator.IS_EMPTY]: Operators.IS_NULL,
  [FilterOperator.IS_NOT_EMPTY]: Operators.NOT_IS_NULL,

  [FilterSelectOperator.EQUALS]: Operators.EQUAL,
  [FilterSelectOperator.NOT_EQUALS]: Operators.NOT_EQUAL,
}
