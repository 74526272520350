import React, { FormHTMLAttributes, ReactNode } from 'react'
import { FormProvider as RHFFormProvider, UseFormReturn } from 'react-hook-form'

interface IFormProviderProps
  extends Omit<FormHTMLAttributes<HTMLFormElement>, 'onSubmit' | 'children'> {
  children: ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  methods: UseFormReturn<any>
  onSubmit?: VoidFunction
}

export const FormProvider = ({ children, methods, onSubmit, ...other }: IFormProviderProps) => (
  <RHFFormProvider {...methods}>
    <form {...other} noValidate onSubmit={onSubmit} style={{ height: '100%' }}>
      {children}
    </form>
  </RHFFormProvider>
)
