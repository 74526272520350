import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { CurrencyDTO } from '@klr/api-connectors'

import { accountActions } from '../../account-state'
import { LS_SELECT_CURRENCY } from '../../auth.constants'
import { AuthUserType } from '../types'

export function useSelectCurrency() {
  const dispatch = useDispatch()

  return useCallback(
    (user: NonNullable<AuthUserType>) => {
      let currency: CurrencyDTO | null

      const value = localStorage.getItem(LS_SELECT_CURRENCY)

      if (value) {
        // TODO: add try catch
        currency = JSON.parse(value) as CurrencyDTO | null
      } else {
        currency = user.currencies[0]
        const saveData = JSON.stringify(user.currencies[0])

        localStorage.setItem(LS_SELECT_CURRENCY, saveData)
      }

      dispatch(accountActions.changeSelectCurrency(currency))
    },
    [dispatch]
  )
}
