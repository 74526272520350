import { forwardRef, Ref } from 'react'
import { useTranslation } from 'react-i18next'

import { CountryDto } from '@klr/api-connectors'
import { ConnectedAutocomplete } from '@klr/shared'

import {
  countryQueryFns,
  CountryQueryKeys,
  countryQueryKeys,
} from '../../location.query-key-factory'

import { AutocompleteCountriesProps } from './AutocompleteCountries.types'

export const AutocompleteCountries = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: AutocompleteCountriesProps, ref: Ref<any>) => {
    const { t } = useTranslation()
    const { textFieldProps, label = t('Labels.countries'), value, onChange, ...rest } = props

    return (
      <ConnectedAutocomplete<CountryDto, CountryQueryKeys['list']>
        queryKey={countryQueryKeys.list}
        queryFn={countryQueryFns.getCountryList}
        queryOptions={{
          retryOnMount: false,
          refetchOnMount: false,
          staleTime: Infinity,
        }}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, _value) => option.id === _value.id}
        value={value}
        onChange={(_, _value) => {
          onChange(_value)
        }}
        {...rest}
        textFieldProps={{ ...textFieldProps, label, inputRef: ref }}
      />
    )
  }
)
