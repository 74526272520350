import * as React from 'react'
import { X as CloseIcon } from 'react-feather'
import { AppBar, Dialog, dialogClasses, IconButton, Toolbar, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { customScrollbar } from '../../theme'
import { TransitionSlide } from '../MuiTransitions'

const StyledDialogFullScreen = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.paper}`]: {
    '&::-webkit-scrollbar': customScrollbar(theme),
  },
}))
const StyledTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  flex: 1,
}))

const StyledAppBar = styled(AppBar)({
  position: 'relative',
})

type Props = {
  title: string
  isShowModal: boolean
  handleCloseModal: VoidFunction
  children: React.ReactNode
}

export const DialogFullScreen = ({ children, title, isShowModal, handleCloseModal }: Props) => {
  return (
    <StyledDialogFullScreen
      fullScreen
      open={isShowModal}
      onClose={handleCloseModal}
      TransitionComponent={TransitionSlide}
    >
      <StyledAppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseModal}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <StyledTypography variant="h4">{title}</StyledTypography>
        </Toolbar>
      </StyledAppBar>

      {children}
    </StyledDialogFullScreen>
  )
}
