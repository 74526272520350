import { useCallback } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { CloseOutlined as CloseIcon } from '@mui/icons-material'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import {
  Box,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import { bytesToSize } from '../../../utils'

import Image from './AddFile.svg'
import { useDropZone } from './useDropZone'

const StyledGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isDragActive',
})<{ isDragActive: boolean }>(({ isDragActive, theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px dashed ${theme.palette.divider}`,
  padding: theme.spacing(4),
  outline: 'none',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    opacity: 0.5,
    cursor: 'pointer',
  },
  ...(isDragActive && {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5,
  }),
}))

const StyledImage = styled('img')({
  width: 80,
})

type Props = {
  files: File[]
  onChange: (value: File[]) => void
}

export const FileDropzone = ({ files, onChange }: Props) => {
  const { t } = useTranslation()

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      onChange(acceptedFiles)
    },
    [onChange]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropZone({
    onDrop: handleDrop,
    maxSize: 1000000,
    onDropAccepted: () => {
      toast.success(t('Messages.file_uploaded'))
    },
  })

  return (
    <>
      <StyledGrid container isDragActive={isDragActive} {...getRootProps()}>
        <input {...getInputProps()} />
        <Grid item md={3}>
          <StyledImage alt={t('Common.select_file')} src={Image} />
        </Grid>
        <Grid item md={9}>
          <Typography gutterBottom variant="h4">
            {t('Common.select_file')}
          </Typography>
          <Box mt={2}>
            <Typography color="textPrimary" variant="body1">
              {t('Common.file_action_description_1')}{' '}
              <Link underline="always">{t('Common.file_action_description_2')}</Link>{' '}
              {t('Common.file_action_description_3')}
            </Typography>
          </Box>
        </Grid>
      </StyledGrid>
      {files?.length > 0 && (
        <List sx={{ maxHeight: 25 }}>
          {files.map((file, i) => (
            <ListItem divider={i < files.length - 1} key={i}>
              <ListItemIcon>
                <FileCopyIcon />
              </ListItemIcon>
              <ListItemText primary={file.name} secondary={bytesToSize(file.size)} />
              <Tooltip title={t('Common.delete')}>
                <IconButton edge="end" onClick={() => onChange([])} size="large">
                  <CloseIcon color="error" />
                </IconButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      )}
    </>
  )
}
