import { ReactNode, useCallback, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { globalPaths } from '@klr/shared'

import { useAuth } from '../context'

type Props = {
  children: ReactNode
}

export const GuestGuard = ({ children }: Props) => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const returnTo = searchParams.get('returnTo') || globalPaths.app.index

  const { authenticated } = useAuth()

  const check = useCallback(() => {
    if (authenticated) {
      navigate(returnTo, { replace: true })
    }
  }, [authenticated, returnTo, navigate])

  useEffect(() => {
    check()
  }, [check])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
