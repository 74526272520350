import { SyntheticEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Stack, styled, Tab, Typography } from '@mui/material'

import { BookingItem, Seat, TransportsSchemeItem } from '@klr/api-connectors'
import { AxisPosition, ToggleFloors, useSchemePaintingParams } from '@klr/schemes'

import { ToggledPlace } from '../../../booking.types'
import { PassengerItem, useBookingCheckoutStore } from '../../../store'

import { AdditionalPricesInfo } from './AdditionalPricesInfo'
import { BusContainer } from './BusContainer'

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  [theme.breakpoints.down('sm')]: {
    padding: 5,
  },
}))

const StackItem = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: '5px 0',
})

interface BusSchemeProps {
  axisPosition?: AxisPosition
  currencyTitle: BookingItem['price']['currency']
  busTab: string
  setBusTab: (value: string) => void
  passengerIndex?: number
  setToggledPlace: (value: ToggledPlace) => void
}

const findSeatByTransportId = (
  selectedPlaces: PassengerItem['selectedPlaces'],
  transportId: number
) => selectedPlaces.find((place) => place.transportId === transportId)?.seat

export const BusScheme = ({
  axisPosition: axisPositionProp,
  currencyTitle,
  busTab,
  setBusTab,
  passengerIndex,
  setToggledPlace,
}: BusSchemeProps) => {
  const { t } = useTranslation()
  const [activeFloor, setActiveFloor] = useState(0)
  const passengerForms = useBookingCheckoutStore((state) => state.passengers)
  const transportsSchemeItems = useBookingCheckoutStore((state) => state.transportData)

  const { axisPosition, direction } = useSchemePaintingParams(axisPositionProp)

  const onToggle = (transportId: TransportsSchemeItem['transportId'], seat: Seat) => {
    setToggledPlace({ transportId, place: seat })
  }

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setBusTab(newValue)
  }

  const getSelectedSeat = useCallback(
    (transportId: TransportsSchemeItem['transportId']) => {
      if (passengerIndex === undefined) return

      return findSeatByTransportId(
        passengerForms[passengerIndex]?.selectedPlaces ?? [],
        transportId
      )
    },
    [passengerForms, passengerIndex]
  )

  const getOtherSelectedSeats = useCallback(
    (transportId: number) => {
      if (passengerIndex === undefined) return []

      return passengerForms
        .filter((item, index) => index !== passengerIndex)
        .map((item) => findSeatByTransportId(item.selectedPlaces, transportId))
        .filter(Boolean) as number[]
    },
    [passengerForms, passengerIndex]
  )

  return (
    <TabContext value={busTab || transportsSchemeItems[0].transportId.toString()}>
      <Box
        sx={{
          borderBottom: 1,
          paddingTop: axisPosition === AxisPosition.HORIZONTAL ? 3 : 8,
          borderColor: 'divider',
        }}
      >
        <TabList onChange={handleChange} aria-label="bus name">
          {transportsSchemeItems.map((bus, busIndex) => (
            <Tab
              key={`bus-tab-${bus.transportId}`}
              label={bus.routeName || `№ ${busIndex + 1}`}
              value={bus.transportId.toString()}
            />
          ))}
        </TabList>
      </Box>
      {transportsSchemeItems.map((bus) => (
        <StyledTabPanel
          value={bus.transportId.toString()}
          key={`bus-panel-${bus.transportId.toString()}`}
        >
          <Stack>
            <StackItem>
              <Typography>{t('Schemes.select_bus_places')}</Typography>
            </StackItem>

            {bus.premiumPrices && (
              <Stack spacing={1} px={6}>
                <AdditionalPricesInfo currencyTitle={currencyTitle} prices={bus.premiumPrices} />
              </Stack>
            )}

            <StackItem>
              <ToggleFloors
                activeFloor={activeFloor}
                floorCount={bus.transportScheme.length}
                floors={Object.keys(bus.transportScheme).map(Number)}
                setActiveFloor={setActiveFloor}
              />
            </StackItem>
            <StackItem>
              <BusContainer
                activeFloor={activeFloor}
                axisPosition={axisPosition}
                busSeatsFloor={bus.transportScheme[activeFloor]}
                direction={direction}
                onToggle={onToggle}
                selectedSeat={getSelectedSeat(bus.transportId)}
                isSingleSelected={passengerIndex !== undefined}
                transportId={bus.transportId}
                otherSelectedSeats={getOtherSelectedSeats(bus.transportId)}
              />
            </StackItem>
          </Stack>
        </StyledTabPanel>
      ))}
    </TabContext>
  )
}
