import type { SettingsValueProps } from './contexts/SettingsContext/types'

// SETTINGS
// Please remove `localStorage` when you set settings.

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeLayout: 'vertical',
}

export const HEADER = {
  H_MOBILE: 64,
  H_DESKTOP: 80,
  H_DESKTOP_OFFSET: 80 - 16,
}

export const NAV = {
  W_VERTICAL: 280,
  W_MINI: 88,
}
