import React, { ReactNode } from 'react'
import { Controller } from 'react-hook-form'
import { Select, SelectProps } from '@mui/material'

type Props = {
  name: string
  children: ReactNode
  label: string
  labelId?: string
  disabled?: boolean
} & SelectProps

export const RHFSelect = ({
  name,
  children,
  label,
  labelId,
  disabled = false,
  ...other
}: Props) => (
  <Controller
    name={name}
    render={({ field }) => (
      <Select {...field} labelId={labelId} label={label} disabled={disabled} {...other}>
        {children}
      </Select>
    )}
  />
)
