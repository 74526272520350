import { IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledExportIconButton = styled(IconButton)(({ theme }) => ({
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.divider,
  borderRadius: 5,
  padding: theme.spacing(0.75),
}))
