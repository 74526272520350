import { Theme } from '@mui/material/styles'

import { customShadows } from '../../custom-shadows'

export function card(theme: Theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          // it's contrast mode
          boxShadow: customShadows(theme.palette.mode).z1,
          // boxShadow: theme.customShadows.card,
          position: 'relative',
          borderRadius: theme.shape.borderRadius * 2,
          zIndex: 0, // Fix Safari overflow: hidden with border radius
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 3, 0),
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
        },
      },
    },
  }
}
