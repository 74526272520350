import { ReactNode } from 'react'

import { SplashScreen } from '@klr/shared'

import { AuthContext } from './auth.context'

type Props = {
  children: ReactNode
}

export const AuthConsumer = ({ children }: Props) => (
  <AuthContext.Consumer>
    {(auth) => (auth.loading ? <SplashScreen /> : children)}
  </AuthContext.Consumer>
)
