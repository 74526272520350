import { Dialog, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .MuiDialog-paper`]: {
    backgroundColor: theme.palette.background.default,
    maxWidth: '100%',
    width: 650,
    boxShadow: '0 1px 8px 0 rgba(0,0,0,0.08)',
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      maxHeight: '100%',
      height: '100%',
      width: '100%',
      margin: 0,
      borderRadius: 0,
    },
  },
}))

export const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}))
