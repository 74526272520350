import { useState } from 'react'
import { useDebounce } from 'react-use'
import { useQuery } from '@tanstack/react-query'

import { apiCityFetchList, CityItem, CityReqFetchList } from '@klr/api-connectors'

type HookReturn = {
  searchInput: string
  setSearchInput: (val: string) => void
  dataSource: CityItem[]
  loading: boolean
}

export const useFetchCityList = (value: CityItem | null, countryId?: number): HookReturn => {
  const [searchInput, setSearchInput] = useState<string>('')
  const [debouncedSearchInput, setDebouncedSearchInput] = useState<string>('')

  useDebounce(
    () => {
      setDebouncedSearchInput(searchInput)
    },
    400,
    [searchInput]
  )

  const { data: dataSource, isFetching: loading } = useQuery({
    enabled: !!debouncedSearchInput && debouncedSearchInput !== value?.name,
    initialData: [],
    queryFn: async () => {
      const request: CityReqFetchList = {
        query: debouncedSearchInput,
        country_id: countryId,
      }

      const { data } = await apiCityFetchList(request)
      return data.items
    },
    queryKey: ['cities', countryId, debouncedSearchInput],
  })

  return {
    searchInput,
    setSearchInput,
    dataSource,
    loading,
  }
}
