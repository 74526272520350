import { forwardRef } from 'react'

import {
  SorryBonusDTO,
  sorryBonusesQueryFns,
  SorryBonusesQueryKeys,
  sorryBonusesQueryKeys,
  SorryBonusReqFetchList,
} from '@klr/api-connectors'
import { ConnectedAutocomplete, ConnectedAutocompleteFabricProps } from '@klr/shared'

interface SorryBonusTemplatesAutocompleteProps
  extends ConnectedAutocompleteFabricProps<SorryBonusDTO> {
  params?: SorryBonusReqFetchList
}

export const SorryBonusTemplatesAutocomplete = forwardRef<
  HTMLInputElement,
  SorryBonusTemplatesAutocompleteProps
>((props, ref) => {
  const { value, defaultValue, onChange, params = {} } = props

  return (
    <ConnectedAutocomplete<SorryBonusDTO, SorryBonusesQueryKeys['list']>
      autocompleteProps={{
        autoHighlight: true,
      }}
      queryFn={sorryBonusesQueryFns.getList}
      queryKey={sorryBonusesQueryKeys.list(params)}
      queryOptions={{
        retryOnMount: false,
        refetchOnMount: false,
      }}
      value={value}
      defaultValue={defaultValue}
      textFieldProps={{
        inputRef: ref,
      }}
      getOptionLabel={(option) => option.title}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(_, value) => onChange(value)}
      limit={params.limit}
    />
  )
})
