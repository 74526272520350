import { AxiosPromise } from 'axios'

import axios from '../_axios/axios'
import { DetailType, ListType } from '../_types'

import {
  SchemeDetailDTO,
  SchemeDTO,
  SchemeReqChange,
  SchemeReqCreate,
  SchemeReqFetchItems,
} from './schemes.models'

export const apiFetchSchemes = (
  params?: SchemeReqFetchItems
): AxiosPromise<ListType<SchemeDTO>> => {
  return axios.get('/schemes', { params })
}

export const apiFetchScheme = (id: SchemeDTO['id']): AxiosPromise<DetailType<SchemeDetailDTO>> => {
  return axios.get(`/schemes/${id}`)
}

export const apiCreateScheme = (
  data: SchemeReqCreate
): AxiosPromise<DetailType<SchemeDetailDTO>> => {
  return axios.post('/schemes', data)
}

export const apiUpdateScheme = ({
  id,
  data,
}: SchemeReqChange): AxiosPromise<DetailType<SchemeDetailDTO>> => {
  return axios.put(`/schemes/${id}`, data)
}

export const apiDeleteScheme = (id: SchemeDTO['id']): AxiosPromise => {
  return axios.delete(`/schemes/${id}`)
}
