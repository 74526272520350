import { useCallback } from 'react'
import LanguageIcon from '@mui/icons-material/Language'
import { IconButton, MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles'
import { m } from 'framer-motion'

import { varHover } from '../components/animate'
import { CustomPopover } from '../components/CustomPopover'
import { useLocales } from '../contexts/LocalizationContext'
import { usePopover } from '../hooks'
import { AvailableLanguages } from '../i18n'

const StyledIcon = styled('img')({
  width: 28,
  height: 28,
  borderRadius: 0.65,
})

export const LanguagePopover = () => {
  const popover = usePopover()

  const { allLang, currentLang, onChangeLang } = useLocales()

  const handleChangeLang = useCallback(
    (val: AvailableLanguages) => {
      onChangeLang(val)
      popover.onClose()
    },
    [onChangeLang, popover]
  )

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        data-testid="language-selector"
        sx={{
          width: 40,
          height: 40,
          ...(popover.open && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        {currentLang.icon ? <StyledIcon src={currentLang.icon} /> : <LanguageIcon />}
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 160 }}>
        {allLang.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === currentLang.value}
            onClick={() => handleChangeLang(option.value)}
            data-testid={`lang-selector-${option.code}`}
          >
            {option.icon ? <StyledIcon src={option.icon} sx={{ mr: 1 }} /> : <LanguageIcon />}

            {option.label}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  )
}
