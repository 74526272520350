import { forwardRef, Ref } from 'react'
import { useTranslation } from 'react-i18next'

import { SchemeDTO, SchemeReqFetchItems } from '@klr/api-connectors'
import { ConnectedAutocomplete, ConnectedAutocompleteFabricProps } from '@klr/shared'

import { schemeQueryFns, SchemeQueryKeys, schemeQueryKeys } from '../../scheme.query-key-factory'

export const AutocompleteSchemes = forwardRef(
  (
    props: ConnectedAutocompleteFabricProps<
      SchemeDTO,
      SchemeQueryKeys['list'],
      Pick<SchemeReqFetchItems, 'with_trashed'>
    >,
    ref: Ref<SchemeDTO>
  ) => {
    const { t } = useTranslation()
    const { textFieldProps, label = t('Labels.schemes'), value, onChange, filters, ...rest } = props

    return (
      <ConnectedAutocomplete<SchemeDTO, SchemeQueryKeys['list']>
        queryKey={schemeQueryKeys.list(filters)}
        queryFn={schemeQueryFns.getSchemeList}
        queryOptions={{
          retryOnMount: false,
          refetchOnMount: false,
        }}
        getOptionLabel={(option) => option.description}
        isOptionEqualToValue={(option, _value) => option.id === _value.id}
        value={value}
        onChange={(_, _value) => {
          onChange(_value)
        }}
        {...rest}
        textFieldProps={{
          placeholder: t('Schemes.scheme_description_search'),
          ...textFieldProps,
          label,
          inputRef: ref,
        }}
      />
    )
  }
)
