import { useTranslation } from 'react-i18next'
import { Box, styled, Typography } from '@mui/material'

import { copyToClipboard } from '@klr/shared'

const StyledAddress = styled(Typography)(({ theme }) => ({
  maxWidth: 140,
  paddingRight: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    maxWidth: 310,
  },
}))

type PropsCityAddress = {
  city: string
  station: string
  coordinates: string
}

export const CityAddress = ({ city, station, coordinates }: PropsCityAddress) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{ cursor: 'pointer' }}
      onClick={() => copyToClipboard(coordinates, t('Messages.station_coords_copied'))}
    >
      <Typography sx={{ display: { xs: 'none', lg: 'inline' } }}>{city}</Typography>
      <StyledAddress variant="caption" display="block">
        {station}
      </StyledAddress>
    </Box>
  )
}
