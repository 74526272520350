import { BusPlace, Seat, SeatCategory, SeatType } from '@klr/api-connectors'

export enum AxisPosition {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export type TransportPlaceLock = (transportId: number, seat: Seat) => void

export type Direction = 'column' | 'row-reverse'

export type NumberOfFloor = 1 | 2

export type SeatsCount = {
  firstFloorSeats: number
  secondFloorSeats: number
}

export type SchemeFloor = 'first' | 'second'
export type RowPosition = 'left' | 'left_center' | 'center' | 'right_center' | 'right'
export interface SchemeCoords {
  floor: SchemeFloor
  row: RowPosition
  cell: number
}

export interface SchemeSeat {
  place: number | null
  type: SeatType.SEAT | SeatType.SEAT_REVERSE
  category: SeatCategory
}

export type SchemeCell = SchemeSeat | BusPlace

export interface SchemeRow {
  left: Record<string, SchemeCell>
  left_center: Record<string, SchemeCell>
  center: Record<string, SchemeCell>
  right_center: Record<string, SchemeCell>
  right: Record<string, SchemeCell>
}

export interface RowType {
  seatsCount: number
  row: SchemeRow
}

export interface Scheme {
  floorCount: NumberOfFloor
  floor: {
    first: RowType | null
    second: RowType | null
  }
}

export interface PlaceInsert {
  coords: SchemeCoords
  cell: SchemeCell
}
