import React from 'react'
import { Controller } from 'react-hook-form'
import { TextField, TextFieldProps } from '@mui/material'

type IProps = {
  name: string
}
type Props = IProps & TextFieldProps

export const RHFTextField = (props: Props) => {
  const { name, children, ...other } = props

  return (
    <Controller
      name={name}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <TextField
          {...field}
          inputRef={ref}
          fullWidth
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          error={!!error}
          helperText={error?.message}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  )
}
