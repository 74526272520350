import { styled, Theme } from '@mui/material'

import { AxisPosition, StyledSeatPlace } from '@klr/schemes'

type PlaceAvailableProps = {
  axisPosition: AxisPosition
  selected: boolean
  inverted: boolean
  isPremium: boolean
}

type ColorProps = Pick<PlaceAvailableProps, 'selected' | 'isPremium'> & {
  theme: Theme
}

const getBackgroundColor = ({ theme, isPremium, selected }: ColorProps) => {
  if (selected) {
    return isPremium ? theme.palette.warning.main : theme.palette.success.main
  }

  return isPremium ? theme.palette.warning.light : theme.palette.grey[100]
}

const getBorderColor = ({ theme, isPremium }: ColorProps) => {
  return isPremium ? theme.palette.warning.dark : theme.palette.grey[400]
}

const getBottomBackgroundColor = ({ theme, isPremium, selected }: ColorProps) => {
  if (selected) {
    return isPremium ? theme.palette.warning.main : theme.palette.success.main
  }

  return isPremium ? theme.palette.warning.light : theme.palette.grey[300]
}

const getBottomBorderColor = ({ theme, isPremium, selected }: ColorProps) => {
  if (selected) {
    return isPremium ? theme.palette.warning.dark : theme.palette.success.light
  }

  return isPremium ? theme.palette.warning.dark : theme.palette.grey[400]
}

const getHoverBgColor = ({ theme, isPremium, selected }: ColorProps) => {
  if (selected) {
    return isPremium ? theme.palette.warning.dark : theme.palette.success.dark
  }

  return isPremium ? theme.palette.warning.dark : theme.palette.grey[700]
}

export const StyledSeatPlaceAvailable = styled(StyledSeatPlace)<{
  ownerState: PlaceAvailableProps
}>(({ theme, ownerState }) => {
  const { axisPosition, selected, inverted, isPremium } = ownerState

  const borderRadiusStyle = {
    [AxisPosition.HORIZONTAL]: '6px 18px 18px 6px',
    [AxisPosition.VERTICAL]: '18px 18px 6px 6px',
  }

  const afterWidth = {
    [AxisPosition.HORIZONTAL]: 8,
    [AxisPosition.VERTICAL]: 'calc(100% + 4px)',
  }

  const afterHeight = {
    [AxisPosition.HORIZONTAL]: 'calc(100% + 4px)',
    [AxisPosition.VERTICAL]: 8,
  }

  return {
    borderColor: getBorderColor({ theme, isPremium, selected }),
    borderRadius: borderRadiusStyle[axisPosition],
    color: isPremium
      ? theme.palette.grey[700]
      : selected
        ? theme.palette.grey[100]
        : theme.palette.grey[700],
    backgroundColor: getBackgroundColor({ theme, isPremium, selected }),
    transform: inverted ? 'rotate(180deg)' : 'none',
    cursor: 'pointer',
    '&:after': {
      bottom: -2,
      left: -2,
      borderRadius: '9px',
      borderColor: getBottomBorderColor({ theme, isPremium, selected }),
      backgroundColor: getBottomBackgroundColor({ theme, isPremium, selected }),
      width: afterWidth[axisPosition],
      height: afterHeight[axisPosition],
    },
    '&:hover': {
      borderColor: getBorderColor({ theme, isPremium, selected }),
      backgroundColor: getHoverBgColor({ theme, isPremium, selected }),
      color: isPremium ? theme.palette.warning.light : theme.palette.grey[100],
    },
  }
})
