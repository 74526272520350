import { forwardRef, Ref, SVGProps } from 'react'

export const UserIcon = forwardRef(
  (props: SVGProps<SVGSVGElement>, svgRef?: Ref<SVGSVGElement>) => {
    return (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
        {...props}
      >
        <path
          d="M11.943 10.938c2.637 0 4.7-2.01 4.7-4.469C16.643 4.011 14.58 2 12.057 2c-2.637 0-4.7 2.011-4.7 4.47 0 2.457 2.063 4.468 4.586 4.468zm0 0c-4.93 0-8.943 3.8-8.943 8.604C5.522 21.106 8.503 22 11.943 22c3.44 0 6.535-.894 9.057-2.458 0-4.805-4.013-8.604-9.057-8.604z"
          stroke="currentColor"
          strokeWidth={2}
          strokeMiterlimit={10}
        />
      </svg>
    )
  }
)
