import { forwardRef } from 'react'
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { orderBy } from 'lodash'

import { apiTagsFetchList, ListDataType, TagItem, tagQueryKeys } from '@klr/api-connectors'
import { CustomPopover, Scrollbar } from '@klr/shared'

import { StyledLabelIcon } from '../../../../../../customer-card.styles'
import { CustomerTagItemType } from '../customer-list.types'

import { useMutateCustomerTags } from './useMutateCustomerTags'

interface TagListPopoverProps {
  customerTagIds: TagItem['id'][]
  open: HTMLElement | null
  onClose(): void
}

export const TagListPopover = forwardRef<HTMLDivElement, TagListPopoverProps>(
  ({ customerTagIds, open, onClose }, ref) => {
    const { data: tagList } = useQuery<ListDataType<TagItem>, AxiosError, CustomerTagItemType[]>({
      queryKey: tagQueryKeys.tagList(),
      queryFn: async () => {
        const { data } = await apiTagsFetchList()

        return data
      },
      select: (data) =>
        orderBy(
          data.data.map((item) => ({
            ...item,
            checked: customerTagIds.includes(item.id),
          })),
          ['checked'],
          ['desc']
        ),
      enabled: !!open,
    })

    const { handleMutate } = useMutateCustomerTags({
      customerTagIds,
      successCallback: onClose,
    })

    return (
      <CustomPopover
        open={open}
        onClose={onClose}
        sx={{ width: 200, p: 0 }}
        ref={ref}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        hiddenArrow
      >
        <Scrollbar sx={{ maxHeight: 620 }}>
          <List sx={{ width: '100%' }}>
            {tagList?.map((item) => (
              <ListItem key={item.id} disablePadding>
                <ListItemButton onClick={() => handleMutate(item.id, !item.checked)}>
                  <ListItemIcon>
                    <Checkbox edge="start" checked={item.checked} tabIndex={-1} disableRipple />
                  </ListItemIcon>
                  <ListItemText>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <StyledLabelIcon ownerState={{ color: item.color || undefined }} />
                      <Typography variant="body2">{item.name}</Typography>
                    </Stack>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Scrollbar>
      </CustomPopover>
    )
  }
)
