import React, { createContext, ReactNode, useEffect, useMemo } from 'react'
import { useLocalStorage } from 'react-use'

import { defaultSettings } from '../../config'

import type { SettingsContextProps } from './types'
import { SettingsValueProps } from './types'

const initialState: SettingsContextProps = {
  ...defaultSettings,
  onToggleMode: () => undefined,
  onChangeLayout: () => undefined,
}

const SettingsContext = createContext(initialState as SettingsContextProps)

interface Props {
  themeLayout: SettingsValueProps['themeLayout']
  children: ReactNode
}

const SettingsProvider = ({ children, themeLayout }: Props) => {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const [settings, setSettings] = useLocalStorage<SettingsValueProps>('settings', {
    themeMode: initialState.themeMode ?? 'light',
    themeLayout,
  })

  const onToggleMode = () => {
    setSettings((prevState) => ({
      themeLayout: settings?.themeLayout ?? initialState.themeLayout,
      themeMode: settings?.themeMode === 'light' ? 'dark' : 'light',
    }))
  }

  const onChangeLayout = (layout: SettingsValueProps['themeLayout']) => {
    setSettings({
      themeMode: settings?.themeMode ?? initialState.themeMode,
      themeLayout: layout,
    })
  }

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'settings') {
        const newSettings = JSON.parse(e.newValue || '{}')
        setSettings(newSettings as SettingsValueProps)
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [setSettings])

  const memoSettings: SettingsValueProps = useMemo(() => {
    if (!settings) return defaultSettings
    if (!settings.themeLayout) settings.themeLayout = themeLayout
    if (!settings.themeLayout) settings.themeMode = initialState.themeMode ?? 'light'
    return settings
  }, [settings, themeLayout])

  return (
    <SettingsContext.Provider
      value={{
        ...memoSettings,
        onToggleMode,
        onChangeLayout,
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}

export { SettingsContext, SettingsProvider }
