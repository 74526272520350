import { forwardRef, Ref, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { BookingItem, BookingPromoRoute } from '@klr/api-connectors'

import { CardTripPrice } from './components/CardTripPrice'

interface StyledButtonProps {
  isEBRoute: boolean
  isSmallScreen: boolean
  promoRoute: BookingPromoRoute | null
}

const StyledButton = styled(Button)<{ ownerState: StyledButtonProps }>(({ theme, ownerState }) => {
  const { isEBRoute, isSmallScreen, promoRoute } = ownerState

  return {
    position: 'relative',
    paddingTop: theme.spacing((promoRoute || isEBRoute) && isSmallScreen ? 3 : 1),
  }
})

const StyledIconDiscount = styled('div')<{ ownerState: { isSmallScreen: boolean } }>(
  ({ theme, ownerState }) => ({
    position: 'absolute',
    bottom: ownerState.isSmallScreen ? 24 : 8,
    right: ownerState.isSmallScreen ? 0 : -40,
    color: theme.palette.common.white,
  })
)

interface CardTripPriceInfoProps {
  currencyIso: BookingItem['price']['currency']
  ebDiscount: BookingItem['dynamic_fare_discount']
  isEBRoute: boolean
  isSmallScreen: boolean
  onSelectTrip(): void
  percent: number | null
  price: BookingItem['price']['value']
  promoRoute: BookingPromoRoute | null
}

export const CardTripPriceInfo = forwardRef(
  (
    {
      currencyIso,
      ebDiscount,
      isEBRoute,
      isSmallScreen,
      onSelectTrip,
      percent,
      price,
      promoRoute,
    }: CardTripPriceInfoProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    const { t } = useTranslation()

    const generalPercent = percent || -ebDiscount || 0

    const fullEBPrice = useMemo(() => {
      if (ebDiscount && ebDiscount !== 100) {
        return (price * 100) / (100 - ebDiscount)
      }

      return null
    }, [ebDiscount, price])

    if (isSmallScreen) {
      return (
        <Grid item xs={6} sm={12}>
          <StyledButton
            variant="contained"
            color="primary"
            size="large"
            ownerState={{ isEBRoute, isSmallScreen, promoRoute }}
            fullWidth
            onClick={onSelectTrip}
            ref={ref}
            data-testid="ticket-price-button"
          >
            <CardTripPrice
              fullEBPrice={fullEBPrice}
              promoRoute={promoRoute}
              price={price}
              currency={currencyIso}
              isSmallScreen={isSmallScreen}
            />
            {generalPercent !== 0 && (
              <StyledIconDiscount ownerState={{ isSmallScreen }}>
                <Typography variant="body2" fontWeight={500}>
                  ({generalPercent}%)
                </Typography>
              </StyledIconDiscount>
            )}
          </StyledButton>
        </Grid>
      )
    }

    return (
      <>
        <Grid item md={8}>
          <CardTripPrice
            fullEBPrice={fullEBPrice}
            promoRoute={promoRoute}
            price={price}
            currency={currencyIso}
          />
        </Grid>
        <Grid item md={4}>
          <StyledButton
            variant="contained"
            color="primary"
            size="large"
            ownerState={{ isEBRoute, isSmallScreen, promoRoute }}
            fullWidth
            onClick={onSelectTrip}
            ref={ref}
            data-testid="ticket-price-button"
          >
            {t('Common.chose')}
            {generalPercent !== 0 && (
              <StyledIconDiscount ownerState={{ isSmallScreen }}>
                <Typography>{generalPercent}%</Typography>
              </StyledIconDiscount>
            )}
          </StyledButton>
        </Grid>
      </>
    )
  }
)
