import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'

type Props = {
  isActive: boolean
}

const StyledStatus = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<Props>(({ theme, isActive }) => ({
  width: '15px',
  height: '15px',
  borderRadius: '50%',

  backgroundColor: isActive ? theme.palette.success.light : theme.palette.error.light,
}))

export const ActiveStatus = React.memo(function ActiveStatus({ isActive }: Props) {
  const { t } = useTranslation()

  return (
    <Tooltip title={t(isActive ? 'Common.active' : 'Common.inactive')}>
      <StyledStatus isActive={isActive} />
    </Tooltip>
  )
})
