import { Outlet } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { OrderDetailProvider } from '@klr/order-ticket-toolkit'
import { AlertError, Main, useBoolean, useResponsive } from '@klr/shared'

import { environments } from '../../environments'

import { Header } from './components/Header'
import { NavVertical } from './components/NavVertical'

export const DashboardLayout = () => {
  const lgUp = useResponsive('up', 'lg')

  const [nav, toggleNav] = useBoolean()

  return (
    <>
      <Header onOpenNav={toggleNav.on} />

      {!lgUp ? <NavVertical openNav={nav} onCloseNav={toggleNav.off} /> : null}

      <Main>
        <Sentry.ErrorBoundary fallback={(errorData) => <AlertError {...errorData} />}>
          <OrderDetailProvider marketplaceURL={environments.marketplaceURL}>
            <Outlet />
          </OrderDetailProvider>
        </Sentry.ErrorBoundary>
      </Main>
    </>
  )
}
