import { useQuery } from '@tanstack/react-query'

import {
  apiTicketsPrintPdf,
  TicketDetail,
  TicketReqPrintPdf,
  ticketsQueryKeys,
} from '@klr/api-connectors'
import { AppModules, downloadPDF } from '@klr/shared'

export const useGetTicketPdf = (
  ticketId: TicketDetail['id'],
  params: TicketReqPrintPdf['params'] = {}
) => {
  return useQuery({
    enabled: false,
    meta: {
      module: AppModules.Tickets,
    },
    queryFn: async () => {
      const { data } = await apiTicketsPrintPdf({ ticketId, params })

      if (data.item.pdf) {
        downloadPDF(data.item.pdf, `Ticket_KLR_Bus_${ticketId}`)
      }

      return data.item
    },
    queryKey: ticketsQueryKeys.pdf({ ticketId, params }),
  })
}
