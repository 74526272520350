import { FC } from 'react'
import {
  Coffee as CoffeeIcon,
  IconProps,
  Tv as TvIcon,
  Volume1 as VolumeIcon,
  Wifi as WifiIcon,
} from 'react-feather'
import { useTranslation } from 'react-i18next'
import {
  FastfoodOutlined as FastFoodIcon,
  PowerOutlined as PowerIcon,
  Room as GpsIcon,
  Wc as WcIcon,
} from '@mui/icons-material'

import { BookingOptions } from '@klr/api-connectors'
import { AirConditionerIcon, CouponIcon, PaymentMethodIcon, StewardessIcon } from '@klr/shared'

type TripBenefits = Record<
  // видаляємо з BookingOptions тип 'no_printed_ticket', бо він не є бенефітом і відображається окремо
  Exclude<BookingOptions, 'no_printed_ticket'>,
  {
    label: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon: FC<IconProps> | any
  }
>

export const useMapTripBenefits = (): TripBenefits => {
  const { t } = useTranslation()

  return {
    return_route_by_promocode: {
      label: t('Benefits.return_route_by_promocode'),
      icon: CouponIcon,
    },
    payment_to_driver: {
      label: t('Benefits.payment_to_driver'),
      icon: PaymentMethodIcon,
    },
    is_220v: {
      label: t('Benefits.220v'),
      icon: PowerIcon,
    },
    is_conditioner: {
      label: t('Benefits.conditioner'),
      icon: AirConditionerIcon,
    },
    is_wc: {
      label: t('Benefits.wc'),
      icon: WcIcon,
    },
    is_wifi: {
      label: t('Benefits.wifi'),
      icon: WifiIcon,
    },
    is_music: {
      label: t('Benefits.music'),
      icon: VolumeIcon,
    },
    is_stuardesa: {
      label: t('Benefits.stuardesa'),
      icon: StewardessIcon,
    },
    is_coffee: {
      label: t('Benefits.coffee'),
      icon: CoffeeIcon,
    },
    is_food: {
      label: t('Benefits.food'),
      icon: FastFoodIcon,
    },
    is_tv: {
      label: t('Benefits.tv'),
      icon: TvIcon,
    },
    is_gps: {
      label: t('Benefits.gps'),
      icon: GpsIcon,
    },
  }
}
