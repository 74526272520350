import { X as CloseIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { ContentPaste as ContentPasteIcon } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle, IconButton, TextField, Tooltip } from '@mui/material'

import { copyToClipboard, StyledCloseButton } from '@klr/shared'

interface SpainInviteLinkModalProps {
  inviteLink: string
  open: boolean
  onClose(): void
}

export const SpainInviteLinkModal = ({ inviteLink, open, onClose }: SpainInviteLinkModalProps) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {t('Auth.spain_invite_link')}
        <Tooltip title={t('Common.close')}>
          <StyledCloseButton aria-label="close" onClick={onClose}>
            <CloseIcon fontSize="large" />
          </StyledCloseButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent sx={{ py: 2 }}>
        <TextField
          size="small"
          value={inviteLink}
          fullWidth
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => copyToClipboard(inviteLink, t('Messages.link_copied'))}
                size="small"
              >
                <ContentPasteIcon fontSize="small" />
              </IconButton>
            ),
            readOnly: true,
          }}
        />
      </DialogContent>
    </Dialog>
  )
}
