import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare'
import { IconButton, Tooltip } from '@mui/material'

import { useAuth } from '../../context'

import { ModalForm } from './components/ModalForm'

interface InviteLinkCopyButtonProps {
  marketplaceURL: string
}

export const InviteLinkModalButton = ({ marketplaceURL }: InviteLinkCopyButtonProps) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [open, setOpen] = useState(false)

  if (!user?.invite) return null

  const inviteLink = `${marketplaceURL}/invite/` + user.invite

  return (
    <>
      <Tooltip title={t('Auth.invite_link')}>
        <IconButton onClick={() => setOpen(true)} data-testid="invite-link">
          <MobileScreenShareIcon />
        </IconButton>
      </Tooltip>
      <ModalForm open={open} setOpen={setOpen} inviteLink={inviteLink} />
    </>
  )
}
