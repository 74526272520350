import React, { forwardRef } from 'react'

type Props = {
  color?: string
  size?: number
}

export const AirConditionerIcon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }: Props, svgRef?: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        enableBackground="new 0 0 512 512"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
        width={size}
        height={size}
        stroke={color}
        {...rest}
      >
        <g>
          <path d="m448 0h-384c-35.29 0-64 28.71-64 64v160c0 35.29 28.71 64 64 64h384c35.29 0 64-28.71 64-64v-160c0-35.29-28.71-64-64-64zm-344 256v-32h304v32zm376-32c0 17.645-14.355 32-32 32h-8v-48c0-8.836-7.163-16-16-16h-336c-8.837 0-16 7.164-16 16v48h-8c-17.645 0-32-14.355-32-32v-64h416c8.837 0 16-7.164 16-16s-7.163-16-16-16h-416v-64c0-17.645 14.355-32 32-32h384c17.645 0 32 14.355 32 32z" />
          <path d="m128 304c-8.837 0-16 7.164-16 16v96c0 17.645-14.355 32-32 32-8.837 0-16 7.164-16 16s7.163 16 16 16c35.29 0 64-28.71 64-64v-96c0-8.836-7.163-16-16-16z" />
          <path d="m440 480c-17.645 0-32-14.355-32-32v-104c0-8.836-7.163-16-16-16s-16 7.164-16 16v104c0 35.29 28.71 64 64 64 8.837 0 16-7.164 16-16s-7.163-16-16-16z" />
          <path d="m304 480c-17.645 0-32-14.355-32-32v-88c0-8.836-7.163-16-16-16s-16 7.164-16 16v88c0 35.29 28.71 64 64 64 8.837 0 16-7.164 16-16s-7.163-16-16-16z" />
          <path d="m192 328c-8.837 0-16 7.164-16 16v120c0 8.836 7.163 16 16 16s16-7.164 16-16v-120c0-8.836-7.163-16-16-16z" />
          <path d="m320 304c-8.837 0-16 7.164-16 16v88c0 8.836 7.163 16 16 16s16-7.164 16-16v-88c0-8.836-7.163-16-16-16z" />
          <circle cx="432" cy="72" r="16" />
          <circle cx="368" cy="72" r="16" />
        </g>
      </svg>
    )
  }
)
