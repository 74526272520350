import { LoadingButton } from '@mui/lab'
import { Box, Divider, FormControl, Grid, InputLabel, MenuItem } from '@mui/material'

import { TicketDetail } from '@klr/api-connectors'
import { GenericTextFields, RHFPhoneInputMask, RHFSelect, RHFTextField } from '@klr/shared'

import { PassengerForm } from '../../../../../../../PassengerForm'

import { getFormFields } from './config'

type Props = {
  isAllowEdit?: boolean
  disable: boolean
  availablePlaces?: number[]
  ticketData: TicketDetail
  defaultPlace?: number
}

export const FormChangeDetail = ({
  isAllowEdit = true,
  disable,
  availablePlaces = [],
  ticketData,
  defaultPlace,
}: Props) => (
  <PassengerForm defaultPlace={defaultPlace} ticketData={ticketData}>
    {({ isLoading, methods, t }) => {
      const errors = methods.formState.errors

      return (
        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item xs={12} container justifyContent="space-between" spacing={2}>
            {defaultPlace && (
              <Grid item sm={6}>
                <FormControl variant="outlined">
                  <InputLabel id="select-place-label">{t('Common.place')}</InputLabel>
                  <RHFSelect
                    name="place"
                    label={t('Common.place')}
                    labelId="select-place-label"
                    disabled={isLoading}
                    defaultValue={defaultPlace}
                  >
                    <MenuItem value={defaultPlace} disabled>
                      {defaultPlace}
                    </MenuItem>
                    {availablePlaces.map((item) => (
                      <MenuItem value={item} key={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                </FormControl>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <Box mb={1} mt={1}>
              <Divider />
            </Box>
          </Grid>

          <GenericTextFields formFields={getFormFields(t)} isDisabled={!isAllowEdit} />

          <Grid item xs={12} sm={6}>
            <RHFPhoneInputMask name="phone" disabled={!isAllowEdit} />
          </Grid>

          <Grid item sm={12}>
            <RHFTextField
              error={!!errors?.note}
              fullWidth
              helperText={errors.note?.message}
              label={t('Common.note')}
              name="note"
              multiline
              rows={2}
              disabled={isLoading || !isAllowEdit}
            />
          </Grid>

          {isAllowEdit && (
            <Grid item container justifyContent="flex-end">
              <LoadingButton
                variant="outlined"
                disabled={disable || isLoading || !methods.formState.isDirty}
                loading={isLoading}
                type="submit"
              >
                {t('Common.save')}
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      )
    }}
  </PassengerForm>
)
