export enum StatusTicketEnum {
  DEFERRED = 'deferred',
  RESERVED = 'reserved',
  RESERVED_PENDING = 'reserved_pending',
  CANCEL = 'cancel',
  CHANGED = 'changed',
  RETURN = 'return',
  PAID = 'paid',
  CONFIRM = 'confirm',
  CASH = 'cash',
  CASH_DISP = 'cash_disp',
  CASH_RESERVED = 'cash_reserved',
  PAYMENT_FAILURE = 'payment_failure',
  OPENDATE = 'opendate',

  // Статуси які присутні тільки в відомості
  SOLD = 'sold',
}

export enum StatusGroupEnum {
  ALL = 'all',
  RETURNED = 'returned',
  NOT_RETURNED = 'notReturned',
}
