import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TableBar } from '@mui/icons-material'
import { SvgIcon, Tooltip } from '@mui/material'

import { Seat, SeatType } from '@klr/api-connectors'
import {
  isCategoryPremium,
  isSeat,
  StairsImg,
  StyledStairs,
  StyledTable,
  StyledWC,
} from '@klr/schemes'

import { SeatPlace } from './components/SeatPlace'
import { PlaceProps } from './Place'

export const PlaceSwitch = (props: PlaceProps) => {
  const { axisPosition, seat } = props
  const { t } = useTranslation()

  const title = useMemo(() => {
    if (isSeat(seat) && seat.available) {
      return isCategoryPremium(seat.category)
        ? t(`Schemes.${seat.category}`, { count: 1 })
        : t('Schemes.standard_place')
    }

    return ''
  }, [seat, t])

  switch (seat.type) {
    case SeatType.SEAT:
    case SeatType.SEAT_REVERSE:
      return (
        <Tooltip title={title}>
          <span>
            <SeatPlace
              {...props}
              // in case of type Seat, seat is Seat
              seat={seat as Seat}
            />
          </span>
        </Tooltip>
      )
    case SeatType.STAIRS:
      return (
        <StyledStairs
          className={axisPosition}
          src={StairsImg}
          alt="stairs"
          width={42}
          height={42}
        />
      )
    case SeatType.WC:
      return <StyledWC>WC</StyledWC>
    case SeatType.TABLE:
      return (
        <StyledTable>
          <SvgIcon
            sx={(theme) => ({
              color: theme.palette.grey[700],
            })}
            component={TableBar}
          />
        </StyledTable>
      )
    default:
      return null
  }
}
