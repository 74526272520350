import React from 'react'
import { Controller } from 'react-hook-form'

import { PhoneInputMask, PhoneInputMaskProps } from '../MuiDataEntry'

type RHFPhoneInputMaskProps = Omit<PhoneInputMaskProps, 'value' | 'onChange' | 'name'> & {
  name: string
}

export const RHFPhoneInputMask = (props: RHFPhoneInputMaskProps) => {
  const { name, ...fieldProps } = props

  return (
    <Controller
      name={name}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <PhoneInputMask
          {...fieldProps}
          {...field}
          ref={ref}
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  )
}
