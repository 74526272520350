import { Outlet } from 'react-router-dom'
import { Box, Container, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Logo } from '@klr/shared'

export const StyledWrapper = styled(Stack)({
  justifyContent: 'center',
  height: '100%',
})

export const StyledLogo = styled(Logo)({
  maxWidth: 42,
  maxHeight: 42,
})

export const AuthLayout = () => {
  return (
    <StyledWrapper>
      <Container maxWidth="md">
        <Box mb={8} display="flex" alignItems="center">
          <StyledLogo />
        </Box>
        <Outlet />
      </Container>
    </StyledWrapper>
  )
}

AuthLayout.displayName = 'AuthLayout'
