import { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import { Typography } from '@mui/material'

type TimerProps = {
  time: number
  setTime: Dispatch<SetStateAction<number>>
  label: string
}

export const Timer = ({ time, setTime, label }: TimerProps) => {
  const tick = useCallback(() => setTime((prevTime) => prevTime - 1), [setTime])

  useEffect(() => {
    const timer = setInterval(() => {
      if (time > 0) {
        tick()
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [tick, time])

  if (!time) return null

  return <Typography variant="body2">{label}</Typography>
}
