import React from 'react'
import { Tab, Tabs } from '@mui/material'

export type TabData<R extends string = string> = {
  label: string
  value: R
}

type Props<R extends string> = {
  tabsArray: TabData<R>[]
  currentTab: R
  handleChange: (val: R) => void
}

export const TabsControls = <R extends string = string>({
  currentTab,
  handleChange,
  tabsArray,
}: Props<R>) => {
  const _handleChange = (_: React.SyntheticEvent, value: R) => {
    handleChange(value)
  }

  return (
    <Tabs value={currentTab} onChange={_handleChange} scrollButtons="auto" variant="scrollable">
      {tabsArray.map((item: TabData<R>, idx: number) => (
        <Tab key={idx} label={item.label} value={item.value} />
      ))}
    </Tabs>
  )
}
