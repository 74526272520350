import { Check as CheckIcon, Trash2 as TrashIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { AccountBalanceWalletOutlined as AccountBalanceWalletOutlinedIcon } from '@mui/icons-material'
import { Button, Grid, SvgIcon } from '@mui/material'

import { copyToClipboard } from '@klr/shared'

interface ActionButtonListProps {
  actionHandlers: {
    handleConfirmTicket(): void
    handleDeleteTicket(): void
  }
  allowedActions: {
    hasConfirmTicket: boolean
    hasDeleteTicket: boolean
  }
  isLoadingAction: boolean
  paymentLink: {
    allowed: boolean | undefined
    url: string
  }
}

export const ActionButtonList = ({
  actionHandlers,
  allowedActions,
  isLoadingAction,
  paymentLink,
}: ActionButtonListProps) => {
  const { t } = useTranslation()

  const { hasDeleteTicket, hasConfirmTicket } = allowedActions
  const { handleConfirmTicket, handleDeleteTicket } = actionHandlers

  return (
    <>
      {hasConfirmTicket && (
        <Grid item xs={12} md={6}>
          <Button
            disabled={isLoadingAction}
            variant="outlined"
            onClick={handleConfirmTicket}
            startIcon={
              <SvgIcon fontSize="small">
                <CheckIcon />
              </SvgIcon>
            }
            fullWidth
          >
            {t('Orders.confirm_ticket')}
          </Button>
        </Grid>
      )}

      {hasDeleteTicket && (
        <Grid item xs={12} md={6}>
          <Button
            disabled={isLoadingAction}
            variant="outlined"
            onClick={handleDeleteTicket}
            startIcon={
              <SvgIcon fontSize="small">
                <TrashIcon />
              </SvgIcon>
            }
            fullWidth
            data-testid="remove-ticket"
          >
            {t('Orders.remove_ticket')}
          </Button>
        </Grid>
      )}

      {paymentLink.allowed && (
        <Grid item xs={12} md={6}>
          <Button
            disabled={isLoadingAction}
            variant="outlined"
            onClick={() => copyToClipboard(paymentLink.url, t('Messages.link_copied'))}
            startIcon={
              <SvgIcon fontSize="small">
                <AccountBalanceWalletOutlinedIcon />
              </SvgIcon>
            }
            fullWidth
          >
            {t('Orders.payment_link')}
          </Button>
        </Grid>
      )}
    </>
  )
}
