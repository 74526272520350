import { useTranslation } from 'react-i18next'
import { Telegram as TelegramIcon } from '@mui/icons-material'
import { AppBar, Box, IconButton, Stack, Toolbar, Tooltip } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { AccountPopover, InviteLinkModalButton, SpainInviteLinkModalButton } from '@klr/auth'
import {
  AGENT_NEWS_TELEGRAM_LINK,
  bgBlur,
  HEADER,
  LanguagePopover,
  Logo,
  NAV,
  RouterLink,
  SettingsMenu,
  SvgColor,
  useOffSetTop,
  useResponsive,
  useSettings,
} from '@klr/shared'

import { environments } from '../../../environments'

import { NavHorizontal } from './NavHorizontal'

type Props = {
  onOpenNav?: VoidFunction
}

export const Header = ({ onOpenNav }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { themeLayout } = useSettings()

  const isNavHorizontal = themeLayout === 'horizontal'

  const isNavMini = themeLayout === 'mini'

  const lgUp = useResponsive('up', 'lg')

  const offset = useOffSetTop(HEADER.H_DESKTOP)

  const offsetTop = offset && !isNavHorizontal

  const renderContent = (
    <>
      <Box sx={{ mr: 1 }}>{lgUp && isNavHorizontal && <Logo />}</Box>

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      {lgUp && <NavHorizontal />}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        <Tooltip title={t('Common.news')}>
          <IconButton component={RouterLink} target="_blank" href={AGENT_NEWS_TELEGRAM_LINK}>
            <TelegramIcon />
          </IconButton>
        </Tooltip>

        <InviteLinkModalButton marketplaceURL={environments.marketplaceURL} />

        <SpainInviteLinkModalButton spainMarketplaceURL={environments.spainMarketplaceURL} />

        <SettingsMenu />

        <LanguagePopover />

        <AccountPopover />
      </Stack>
    </>
  )

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  )
}
