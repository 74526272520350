import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  activityLogsQueryKeys,
  ActivityLogSubjectType,
  apiTicketsChange,
  ordersQueryKeys,
  TicketDetail,
  TicketReqChangeData,
} from '@klr/api-connectors'
import { AppModules, handleBackendError } from '@klr/shared'

export function useChangeTicket(ticketId: TicketDetail['id']) {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (variables: Partial<TicketReqChangeData>) => {
      const { data } = await apiTicketsChange({
        ticketId,
        data: variables,
      })

      return data.item
    },
    mutationKey: ['tickets', ticketId, 'change'],
    onError: (error) => {
      handleBackendError(error, AppModules.Tickets)
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(ordersQueryKeys.detail(data.order_id)).then(() => {
        queryClient
          .invalidateQueries(
            activityLogsQueryKeys.list({
              subject_type: ActivityLogSubjectType.TICKET,
              subject_id: ticketId,
            })
          )
          .then(() => toast.success(t('Messages.ticket_successfully_updated')))
      })
    },
  })
}
