import React, { forwardRef } from 'react'

type Props = {
  color?: string
  size?: number
}

export const CouponIcon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }: Props, svgRef?: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        ref={svgRef}
        width={size}
        height={size}
        viewBox="0 0 512 512"
        xmlSpace="preserve"
        stroke={color}
        {...rest}
      >
        <g>
          <g>
            <path
              d="M287,151c-24.813,0-45,20.187-45,45s20.187,45,45,45s45-20.187,45-45S311.813,151,287,151z M287,211
			c-8.271,0-15-6.729-15-15s6.729-15,15-15s15,6.729,15,15S295.271,211,287,211z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M407,271c-24.813,0-45,20.187-45,45s20.187,45,45,45s45-20.187,45-45S431.813,271,407,271z M407,331
			c-8.271,0-15-6.729-15-15s6.729-15,15-15s15,6.729,15,15S415.271,331,407,331z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M415.32,153.519c-6.891-4.594-16.206-2.732-20.801,4.161l-120,180c-4.595,6.892-2.732,16.205,4.161,20.801
			c6.892,4.594,16.205,2.733,20.801-4.161l120-180C424.076,167.427,422.213,158.114,415.32,153.519z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M497,91H15c-8.284,0-15,6.716-15,15v90c0,8.284,6.716,15,15,15c24.813,0,45,20.187,45,45s-20.187,45-45,45
			c-8.284,0-15,6.716-15,15v90c0,8.284,6.716,15,15,15h482c8.284,0,15-6.716,15-15V106C512,97.716,505.284,91,497,91z M482,391H150
			v-15c0-8.284-6.716-15-15-15s-15,6.716-15,15v15H30v-61.506c34.191-6.969,60-37.275,60-73.494c0-36.219-25.809-66.524-60-73.493
			V121h91.25v15c0,8.284,6.716,15,15,15s15-6.716,15-15v-15H482V391z"
            />
          </g>
        </g>
        <g>
          <g>
            <path d="M135,271c-8.284,0-15,6.716-15,15v30c0,8.284,6.716,15,15,15s15-6.716,15-15v-30C150,277.716,143.284,271,135,271z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M135,181c-8.284,0-15,6.716-15,15v30c0,8.284,6.716,15,15,15s15-6.716,15-15v-30C150,187.716,143.284,181,135,181z" />
          </g>
        </g>
      </svg>
    )
  }
)
