import { X as CloseIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogContent, DialogTitle, Tooltip } from '@mui/material'

import { StyledCloseButton } from '@klr/shared'

import { useFetchTemplates } from '../../hooks'
import { MessageDispatchSkeleton } from '../MessageDispatchSkeleton'

import { TicketMessageForm } from './TicketMessageForm'

interface TicketSendMessageModalProps {
  defaultMessage?: string
  handleClose(): void
  open: boolean
  phoneNumber: string
}

export const TicketSendMessageModal = ({
  defaultMessage,
  handleClose,
  open,
  phoneNumber,
}: TicketSendMessageModalProps) => {
  const { t } = useTranslation()
  const { data: templates, isLoading } = useFetchTemplates({ enabled: open })

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {t('Orders.send_message')}
        <Tooltip title={t('Common.close')}>
          <StyledCloseButton aria-label="close" onClick={handleClose}>
            <CloseIcon fontSize="large" />
          </StyledCloseButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent sx={{ py: 4 }}>
        {isLoading ? (
          <MessageDispatchSkeleton />
        ) : (
          <TicketMessageForm
            defaultMessage={defaultMessage}
            phoneNumber={phoneNumber}
            templates={templates ?? []}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}
