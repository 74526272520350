export type SearchCarrier = {
  id: number
  name: string
}

export const defaultCarrier: SearchCarrier = {
  id: 1,
  name: 'KLR Bus',
}

export const defaultCurrencyId = 1

//sorry bonuses
export const minValue = 10
export const maxFixedValue = 1000
export const maxPercentValue = 100
export const maxSmsTextLength = 160
