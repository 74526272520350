import { AxiosPromise } from 'axios'

import { axios } from '../_axios'
import { DetailType, ListType } from '../_types'
import { CurrencyDTO } from '../currencies'
import { RouteItem } from '../routes'

import type {
  AgentDetail,
  AgentItem,
  AgentReqChange,
  AgentReqChangeCurrencies,
  AgentReqCreate,
  AgentReqDeleteCarrierOption,
  AgentReqDeleteRouteOption,
  AgentReqDeleteSubAgent,
  AgentReqEditAllowedRoutes,
  AgentReqEditListSubAgents,
  AgentReqEditRatesAllowance,
  AgentReqEditRoutesCommission,
  AgentReqFetchItems,
  AgentsCarriersCommission,
  AgentsReqChangeCarriersCommission,
} from './models'

export const apiAgentsFetchList = (
  params: AgentReqFetchItems
): AxiosPromise<ListType<AgentItem>> => {
  return axios.get('/agents', {
    params,
  })
}

export const apiAgentsFetchDetail = (
  id: AgentItem['id']
): AxiosPromise<DetailType<AgentDetail>> => {
  return axios.get(`/agents/${id}`)
}

export const apiAgentsCreate = (data: AgentReqCreate): AxiosPromise<DetailType<AgentDetail>> => {
  return axios.post('/agents', data)
}

export const apiAgentsChange = ({
  id,
  data,
}: AgentReqChange): AxiosPromise<DetailType<AgentDetail>> => {
  return axios.put(`/agents/${id}`, data)
}

// START SUB_AGENTS
/**
 * Отримати суб агентів для агента
 *
 * @param id - індитифікатор батьківського агента
 */
export const apiAgentsFetchListSubAgents = (id: number): AxiosPromise<ListType<AgentItem>> => {
  return axios.get(`/agents/${id}/sub-agents`)
}

/**
 * Встановлення суб агентів
 *
 * @param id - індитифікатор батьківського агента
 * @param data - масив індитифікаторів суб агентів
 */
export const apiAgentsChangeListSubAgents = ({
  id,
  data,
}: AgentReqEditListSubAgents): AxiosPromise => {
  return axios.put(`/agents/${id}/sub-agents`, data)
}

/**
 * Видалення субагента
 *
 * @param agentId - індитифікатор батьківського агента
 * @param subAgentId - індитифікатор суб агента
 */
export const apiAgentsDeleteSubAgent = ({
  agentId,
  subAgentId,
}: AgentReqDeleteSubAgent): AxiosPromise => {
  return axios.delete(`/agents/${agentId}/sub-agents/${subAgentId}`)
}
// END SUB_AGENTS

// START COMMISSION
/**
 * Отримати список маршрутів для яких в агента встановлена комісія
 *
 * @param id - індитифікатор агента
 */
export const apiAgentsFetchRoutesCommission = (id: number): AxiosPromise<ListType<RouteItem>> => {
  return axios.get(`/agents/${id}/route-commission`)
}

/**
 * Зміна комісії агента, для маршрута, якщо комісія не втановлена раніше, буде сворено новий запис
 *
 * @param id - індитифікатор агента
 * @param data - Масив з об. індитифікатор маршрута і комісія
 */
export const apiAgentsChangeRoutesCommission = ({
  id,
  data,
}: AgentReqEditRoutesCommission): AxiosPromise<ListType<RouteItem>> => {
  return axios.put(`/agents/${id}/route-commission`, data)
}

/**
 * Видалення комісії агента для певного маршруту
 *
 * @param agent_id
 * @param route_id
 */
export const apiAgentsDeleteRoutesCommission = ({
  agent_id,
  route_id,
}: AgentReqDeleteRouteOption): AxiosPromise => {
  return axios.delete(`/agents/${agent_id}/route-commission/${route_id}`)
}

// END COMMISSION

// START RATE ALLOWANCE
/**
 * Отримати список маршрутів з надбавками для тарифів для агента
 *
 * @param id
 */
export const apiAgentsFetchRatesAllowance = (id: number): AxiosPromise<ListType<RouteItem>> => {
  return axios.get(`/agents/${id}/rate-allowance`)
}

/**
 *
 * @param id
 * @param data
 */
export const apiAgentsChangeRatesAllowance = ({
  id,
  data,
}: AgentReqEditRatesAllowance): AxiosPromise<ListType<RouteItem>> => {
  return axios.put(`/agents/${id}/rate-allowance`, data)
}

/**
 * Видалення надбавки для тарифу певного маршруту
 *
 * @param agent_id
 * @param route_id
 */
export const apiAgentsDeleteRateAllowance = ({
  agent_id,
  route_id,
}: AgentReqDeleteRouteOption): AxiosPromise => {
  return axios.delete(`/agents/${agent_id}/rate-allowance/${route_id}`)
}
// END RATE ALLOWANCE

export const apiAgentsChangeAllowedRoutes = ({
  id,
  data,
}: AgentReqEditAllowedRoutes): AxiosPromise<ListType<RouteItem>> => {
  return axios.put(`/agents/${id}/allowed-routes`, data)
}

/**
 * Зміна валют доступних для агента
 *
 * @param id
 * @param data
 */
export const apiAgentsChangeCurrencies = ({
  id,
  data,
}: AgentReqChangeCurrencies): AxiosPromise<ListType<CurrencyDTO>> => {
  return axios.put(`/agents/${id}/currencies`, data)
}

/**
 * Отримати список перевізників для яких встановлена комісія
 *
 * @param id
 */
export const apiAgentsFetchCarriersCommission = (
  id: AgentItem['id']
): AxiosPromise<ListType<AgentsCarriersCommission>> => {
  return axios.get(`/agents/${id}/carrier-commission`)
}

/**
 * Зміна комісії агента для перевізника. Якщо, комісія встановлена раніше, буде створено новий запис.
 */
export const apiAgentsChangeCarriersCommission = ({
  agent_id,
  data,
}: AgentsReqChangeCarriersCommission): AxiosPromise<DetailType<AgentsCarriersCommission>> => {
  return axios.put(`/agents/${agent_id}/carrier-commission`, data)
}

/**
 * Видилення комісії агента для перевізника.
 *
 * @param agent_id
 * @param carrier_id
 */
export const apiAgentsDeleteCarriersCommission = ({
  agent_id,
  carrier_id,
}: AgentReqDeleteCarrierOption): AxiosPromise => {
  return axios.delete(`/agents/${agent_id}/carrier-commission/${carrier_id}`)
}
