import { forwardRef, Ref } from 'react'
import { useTranslation } from 'react-i18next'

import { AgentItem, agentQueryFns, AgentQueryKeys, agentQueryKeys } from '@klr/api-connectors'
import { ConnectedAutocomplete, ConnectedMultipleAutocompleteFabricProps } from '@klr/shared'

export const ConnectedAutocompleteAgentsMultiple = forwardRef(
  (
    props: ConnectedMultipleAutocompleteFabricProps<AgentItem, AgentQueryKeys['list']>,
    ref: Ref<AgentItem>
  ) => {
    const { t } = useTranslation()
    const { textFieldProps, autocompleteProps, label, value, onChange, ...rest } = props

    return (
      <ConnectedAutocomplete<AgentItem, AgentQueryKeys['list'], true>
        queryKey={agentQueryKeys.list({ is_active: 1 })}
        queryFn={agentQueryFns.getAgentList}
        queryOptions={{
          retryOnMount: false,
          refetchOnMount: false,
        }}
        getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
        isOptionEqualToValue={(option, _value) => option.id === _value.id}
        value={value ?? undefined}
        onChange={(_, value) => onChange(value)}
        {...rest}
        autocompleteProps={{
          ...autocompleteProps,
          multiple: true,
        }}
        textFieldProps={{
          placeholder: t('Agents.search_by_agent'),
          ...textFieldProps,
          label,
          inputRef: ref,
        }}
      />
    )
  }
)
