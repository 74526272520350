import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { Loadable } from '@klr/shared'

const BookingSearch = Loadable(lazy(() => import('./pages/booking-search')))
const BookingCheckout = Loadable(lazy(() => import('./pages/booking-checkout')))

export const bookingRoutes = {
  path: 'booking',
  children: [
    {
      index: true,
      element: <Navigate to="/app/booking/search" replace />,
    },
    { path: 'search', element: <BookingSearch /> },
    { path: 'checkout/:id', element: <BookingCheckout /> },
  ],
}
