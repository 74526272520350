import { useState } from 'react'

import { MessageDispatchTemplate, NMessageToClient } from '@klr/api-connectors'
import { formattingPhoneNumber } from '@klr/shared'

import { MessageDispatchForm } from '../MessageDispatchForm/MessageDispatchForm'

interface TicketMessageFormProps {
  defaultMessage: string | undefined
  phoneNumber: string
  templates: MessageDispatchTemplate[]
}

export const TicketMessageForm = ({
  defaultMessage = '',
  phoneNumber,
  templates,
}: TicketMessageFormProps) => {
  const [messageTemplate, setMessageTemplate] = useState(defaultMessage)

  const messages: NMessageToClient[] = [
    {
      phone: formattingPhoneNumber(phoneNumber),
      ticket_ids: [],
      message: messageTemplate,
    },
  ]

  return (
    <MessageDispatchForm
      templates={templates}
      messages={messages}
      messageTemplate={messageTemplate}
      setMessageTemplate={setMessageTemplate}
    />
  )
}
