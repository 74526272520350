import { ReactNode } from 'react'
import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'headerName' && prop !== 'divider',
})<{ headerName?: boolean; divider: DividerType | undefined }>(
  ({ headerName, divider, theme }) => ({
    ...(divider && {
      borderBottom: `1px ${divider} #ccc`,
    }),
    ...(headerName && {
      fontWeight: theme.typography.fontWeightMedium,
    }),
  })
)

type DividerType = 'dashed' | 'solid'

type FieldConfigCustomRender = {
  text?: never
  customRender?: ReactNode
}

type FieldConfigText = {
  text?: string | number
  customRender?: never
}

export type FieldConfig = { label: string } & (FieldConfigCustomRender | FieldConfigText)

type Props = {
  fieldsArray: FieldConfig[]
  divider?: DividerType
}

export const TableDetailInfo = ({ fieldsArray, divider }: Props) => (
  <Table size="small">
    <TableBody>
      {fieldsArray.map((item, idx) =>
        item.text || item.customRender ? (
          <TableRow key={idx}>
            <StyledTableCell headerName divider={divider}>
              {item.label}:
            </StyledTableCell>
            <StyledTableCell align="right" divider={divider}>
              {item.customRender ? (
                item.customRender
              ) : (
                <Typography variant="body2" color="textSecondary">
                  {item.text}
                </Typography>
              )}
            </StyledTableCell>
          </TableRow>
        ) : null
      )}
    </TableBody>
  </Table>
)
