import { memo } from 'react'
import { Stack } from '@mui/material'

import { NavProps } from '../../../shared/types'

import { NavList } from './components/NavList'

export const NavSectionHorizontal = memo(({ data, slotProps, ...other }: NavProps) => (
  <Stack
    component="nav"
    id="nav-section-horizontal"
    direction="row"
    alignItems="center"
    spacing="6px"
    {...other}
  >
    {data.map((item) => (
      <NavList key={item.title} data={item} depth={1} slotProps={slotProps} />
    ))}
  </Stack>
))
