/**
 * Formats phone numbers to the desired standard
 * Necessary for inserting and replacing while opening old phone numbers.
 *
 * @param phone
 * @returns formatted phone number
 */
export const formattingPhoneNumber = (phone: string): string => {
  const regexMap = new Map<RegExp, string>([
    [/-/g, ''],
    [/^09/g, '+3809'],
    [/^06/g, '+3806'],
    [/^05/g, '+3805'],
    [/^380/g, '+380'],
    [/^4/g, '+4'],
    [/\s/g, ''],
    [/^004/g, '+4'],
  ])

  let formattedPhone = phone
  for (const [regex, replacement] of regexMap.entries()) {
    formattedPhone = formattedPhone.replace(regex, replacement)
  }

  return formattedPhone.trim()
}
