import { List, ListItem, ListItemButton, ListItemText, Skeleton } from '@mui/material'
import { styled } from '@mui/material/styles'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'

import { CityItem } from '@klr/api-connectors'

type Props = {
  dataSource: CityItem[]
  isLoading: boolean
  selectCity: (item: CityItem) => void
  searchInput: string
}

const StyledSpan = styled('span', {
  shouldForwardProp: (prop) => prop !== 'highlight',
})<{ highlight: boolean }>(({ theme, highlight }) => ({
  fontWeight: highlight ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular,
}))

export const CityList = ({ dataSource, isLoading, selectCity, searchInput }: Props) => {
  if (isLoading) {
    return (
      <List dense>
        {[0, 1, 2, 3, 4, 5].map((item) => (
          <ListItem key={item}>
            <Skeleton height={30} width="100%" variant="rectangular" />
          </ListItem>
        ))}
      </List>
    )
  }

  return (
    <List dense>
      {dataSource.map((item) => {
        const matches = match(item.name, searchInput)
        const parts = parse(item.name, matches)

        return (
          <ListItemButton key={item.id} onClick={() => selectCity(item)}>
            <ListItemText
              primary={
                <div>
                  {parts.map((part, index) => (
                    <StyledSpan key={index} highlight={part.highlight}>
                      {part.text}
                    </StyledSpan>
                  ))}
                </div>
              }
              secondary={item.country_name}
            />
          </ListItemButton>
        )
      })}
    </List>
  )
}
