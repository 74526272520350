import { useTranslation } from 'react-i18next'
import { Chip, ChipProps } from '@mui/material'
import { styled } from '@mui/material/styles'

import { OrderStatus } from '@klr/api-connectors'

const StyledRoot = styled(Chip)(({ theme, variant }) => ({
  border: `1px dashed ${theme.palette.action.active}`,
  color: variant === undefined ? theme.palette.action.active : 'default',
  marginTop: 6,
  marginRight: 6,
}))

type Props = {
  quickArrayFormPaid?: OrderStatus[]
  handleChange: (val: OrderStatus) => void
} & Omit<ChipProps, 'label'>

export const ChipsFormPaid = (props: Props) => {
  const { t } = useTranslation()

  const chips: OrderStatus[] = [
    {
      id: 'reserved',
      name: t('Statuses.reserved'),
    },
    {
      id: '26',
      name: 'Вояжер',
    },
    {
      id: '23',
      name: 'Євротікет',
    },
  ]

  const { handleChange, quickArrayFormPaid = chips, ...other } = props

  return (
    <>
      {quickArrayFormPaid.map((item, idx) => (
        <StyledRoot
          key={idx}
          tabIndex={-1}
          label={item.name}
          size="small"
          {...other}
          onClick={() => handleChange(item)}
        />
      ))}
    </>
  )
}
