import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'

const StyledRoot = styled('button')(({ theme }) => ({
  width: '100%',
  background: 'transparent',
  border: `2px dashed ${theme.palette.action.hover}`,
  height: 50,

  cursor: 'pointer',
  color: theme.palette.action.disabled,
  fontSize: theme.typography.body1.fontSize,
  transition: 'border-color 0.5s ease, color 0.5s ease',
  '&:hover': {
    borderColor: theme.palette.action.active,
    color: theme.palette.action.active,
  },
  '&:focus': {
    outline: 'none',
    borderColor: theme.palette.action.active,
  },
}))

interface ButtonAddFormProps {
  onClick: VoidFunction
}

export const ButtonAddForm = ({ onClick }: ButtonAddFormProps) => {
  const { t } = useTranslation()

  return (
    <StyledRoot type="button" onClick={onClick}>
      {t('Common.add_passenger')}
    </StyledRoot>
  )
}
