import { useQuery } from '@tanstack/react-query'

import {
  apiBookingFetchTicketTransportInfo,
  BookingReqFetchTicketTransportInfo,
} from '@klr/api-connectors'
import { handleBackendError } from '@klr/shared'

export function useGetTicketTransportInfo(params: BookingReqFetchTicketTransportInfo) {
  return useQuery({
    enabled: false,
    queryKey: ['useGetTicketTransportInfo', params],
    queryFn: async () => {
      const { data } = await apiBookingFetchTicketTransportInfo(params)

      return data.data.response.confirmed_transports ?? []
    },
    onError: (error) => {
      handleBackendError(error)
    },
  })
}
