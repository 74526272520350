import { ReactNode } from 'react'
import { Box, Stack, styled, Typography } from '@mui/material'

import { CurrencyDTO } from '@klr/api-connectors'
import { formatCurrency, useLocales } from '@klr/shared'

const StyledBox = styled(Stack)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  width: 1,
  minWidth: 200,
  color: '#e7eaef',
}))

interface CustomerReferralTotalCardProps {
  color: 'success' | 'info'
  currency: CurrencyDTO['iso']
  icon: ReactNode
  sum: number
  title: string
}

export const CustomerReferralTotalCard = ({
  color,
  currency,
  icon,
  sum,
  title,
}: CustomerReferralTotalCardProps) => {
  const { currentLang } = useLocales()

  return (
    <StyledBox
      direction="row"
      spacing={1}
      alignItems="center"
      sx={(theme) => ({
        backgroundColor: theme.palette[color].dark,
      })}
    >
      {icon}
      <Box>
        <Typography variant="subtitle1">{title}</Typography>

        <Typography variant="h4" fontWeight="bold">
          {formatCurrency(sum, currency, currentLang.code)}
        </Typography>
      </Box>
    </StyledBox>
  )
}
