import { lazy } from 'react'

import { Loadable } from '@klr/shared'

import { GuestGuard } from '../guards'

import { AuthLayout } from './components/AuthLayout'

const Login = Loadable(lazy(() => import('./components/pages/LoginPage')))

export const authRoutes = {
  path: 'login',
  element: (
    <GuestGuard>
      <AuthLayout />
    </GuestGuard>
  ),
  children: [{ index: true, element: <Login /> }],
}
