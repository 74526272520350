import React from 'react'
import { Grid, TextFieldProps } from '@mui/material'

import { RHFTextField } from '../react-hook-form'

export interface ArrayFields {
  name: string
  label: string
  type?: TextFieldProps['type']
  placeholder?: string
  required?: boolean
}

type Props = {
  formFields: ArrayFields[]
  isDisabled?: boolean
  columns?: number
  textFieldProps?: Omit<
    TextFieldProps,
    'name' | 'label' | 'type' | 'placeholder' | 'required' | 'disabled'
  >
}

export const GenericTextFields = ({
  formFields,
  isDisabled = false,
  columns = 2,
  textFieldProps,
}: Props) => {
  const columnsInRow = Math.floor(12 / columns)
  const grid = columnsInRow > 0 ? columnsInRow : 12

  return (
    <>
      {formFields.map((item: ArrayFields, idx: number) => (
        <Grid key={idx} item sm={grid} xs={12}>
          <RHFTextField
            label={item.label}
            name={item.name}
            type={item.type ?? 'text'}
            placeholder={item.placeholder || item.label}
            required={item.required}
            disabled={isDisabled}
            {...textFieldProps}
          />
        </Grid>
      ))}
    </>
  )
}
