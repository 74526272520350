import {
  apiFetchSchemes,
  ConnectedQueryFunctionContext,
  getQueryPagination,
  ListType,
  SchemeDTO,
  SchemeReqFetchItems,
} from '@klr/api-connectors'

export const schemeQueryKeys = {
  all: ['schemes'] as const,
  list: (filters: Pick<SchemeReqFetchItems, 'with_trashed'> = {}): SchemeQueryKeys['list'] =>
    [...schemeQueryKeys.all, filters] as const,
  detail: (id: SchemeDTO['id'] | null) => [schemeQueryKeys.all, id] as const,
}

export type SchemeQueryKeys = {
  all: readonly ['schemes']
  list: readonly [...SchemeQueryKeys['all'], Pick<SchemeReqFetchItems, 'with_trashed'>]
  detail: (id: SchemeDTO['id']) => readonly [...SchemeQueryKeys['all'], SchemeDTO['id']]
}

export const schemeQueryFns = {
  getSchemeList: async (
    context: ConnectedQueryFunctionContext<SchemeQueryKeys['list']>
  ): Promise<ListType<SchemeDTO>> => {
    const {
      pageParam = 1,
      queryKey: [, { with_trashed }, { search }],
    } = context

    const payload: SchemeReqFetchItems = {
      ...getQueryPagination({ pageParam }),
      with_trashed,
    }

    if (search) payload.query = search.trim()

    const { data } = await apiFetchSchemes(payload)
    return data
  },
}
