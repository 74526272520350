import { useTranslation } from 'react-i18next'
import { Box, Grid, IconButton, inputBaseClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

import { AutocompleteCities } from '@klr/location'
import { Swap as SwapIcon } from '@klr/shared'

import { FormSearchScreenProps } from '../booking.form-search.types'

const StyledSwapIconBox = styled(Box)({
  position: 'absolute',
  left: '52%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  zIndex: 1,
})

const StyledSearchCitiesGrid = styled(Grid)({
  '& >:nth-of-type(1)': {
    [`.${inputBaseClasses.root}`]: {
      borderRadius: '5px 0 0 5px',
    },
  },

  '& >:nth-of-type(3)': {
    [`.${inputBaseClasses.root}`]: {
      borderRadius: '0 5px 5px 0',
    },
  },
})

export const DesktopCitySearchForm = ({
  cityFromValue,
  cityToValue,
  reverseCities,
  setCityFromValue,
  setCityToValue,
}: FormSearchScreenProps) => {
  const { t } = useTranslation()

  return (
    <StyledSearchCitiesGrid item md={5} container position="relative" height="fit-content">
      <Grid item md={6} sx={{ mb: 2 }}>
        <AutocompleteCities
          value={cityFromValue || null}
          onChange={setCityFromValue}
          textFieldProps={{
            placeholder: t('Locations.khmelnytskyi'),
            label: t('Booking.search.from'),
            InputProps: { className: 'outlinedFieldSet' },
          }}
        />
      </Grid>
      <StyledSwapIconBox>
        <IconButton
          tabIndex={-1}
          onClick={reverseCities}
          data-testid="swap-locations"
          sx={{
            backgroundColor: 'background.paper',
            '&:hover': { backgroundColor: 'background.paper' },
          }}
        >
          <SwapIcon />
        </IconButton>
      </StyledSwapIconBox>
      <Grid item md={6}>
        <AutocompleteCities
          value={cityToValue || null}
          onChange={setCityToValue}
          textFieldProps={{
            placeholder: t('Locations.kyiv'),
            label: t('Booking.search.to'),
            InputProps: { className: 'outlinedFieldSet' },
          }}
        />
      </Grid>
    </StyledSearchCitiesGrid>
  )
}
