import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Label, transformUkrToShortString } from '@klr/shared'

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  fontSize: theme.typography.pxToRem(20),
  lineHeight: 1,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(24),
  },
  // TODO: remove this after fix bug with mui
})) as typeof Typography

const StyledDate = styled(Label)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  whiteSpace: 'nowrap',
  fontSize: theme.typography.pxToRem(12),
}))

type Props = {
  time: string
  date: string
  isShowDate?: boolean
}

export const TimeTicketCard = ({ time, date, isShowDate = true }: Props) => {
  return (
    <StyledTypography variant="button" color="primary" component="time">
      {time}
      {isShowDate ? (
        <StyledDate variant="soft" color="secondary">
          {transformUkrToShortString(date)}
        </StyledDate>
      ) : null}
    </StyledTypography>
  )
}
