import { Card, Grid, Skeleton, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: 30,
  padding: theme.spacing(2, 2, 1),

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2),
  },
}))

export const SkeletonTrip = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))

  const _renderBlock = (
    <Grid container>
      <Grid item xs={8}>
        <Skeleton width="100%" height={30} variant="rectangular" />
      </Grid>
      <Grid item xs={5}>
        <Skeleton width="100%" height={30} />
      </Grid>
      <Grid item xs={10}>
        <Skeleton width="100%" height={30} />
      </Grid>
    </Grid>
  )

  const _renderAction = (
    <Grid container justifyContent={matches ? 'flex-end' : 'space-between'} alignItems="flex-end">
      <Grid item xs={4} sm={12}>
        <Skeleton width="100%" height={25} />
      </Grid>
      <Grid item xs={10} sx={{ display: { xs: 'none', md: 'block' } }}>
        <Skeleton width="100%" height={30} />
      </Grid>
      <Grid item xs={6} sm={8} sx={{ mt: 1 }}>
        <Skeleton width="100%" height={30} variant="rectangular" />
      </Grid>
    </Grid>
  )

  return (
    <StyledCard>
      <Grid container>
        <Grid item xs={6} sm={5}>
          {_renderBlock}
        </Grid>
        <Grid item xs={6} sm={5}>
          {_renderBlock}
        </Grid>
        <Grid item xs={12} sm={2}>
          {_renderAction}
        </Grid>
      </Grid>
    </StyledCard>
  )
}
