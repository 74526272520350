import { MouseEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { Button, ListItemText, Menu, MenuItem, SvgIcon } from '@mui/material'

import { TicketDetail } from '@klr/api-connectors'

type TrackingType = Pick<
  TicketDetail,
  'transport_name' | 'transport_reg_number' | 'transport_gps_id'
> & { index: number }

interface TrackingBusInfoProps {
  dataSource: TicketDetail
}

export const TrackingBusInfo = ({ dataSource }: TrackingBusInfoProps) => {
  const { children, transport_name, transport_reg_number, transport_gps_id } = dataSource
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const trackingTransports = useMemo(() => {
    const candidates: TrackingType[] = []

    if (children) {
      children.forEach((child, index) => {
        if (child.transport_gps_id) {
          candidates.push({
            transport_name: child.transport_name,
            transport_reg_number: child.transport_reg_number,
            transport_gps_id: child.transport_gps_id,
            index,
          })
        }
      })
    } else if (transport_gps_id) {
      candidates.push({
        transport_name,
        transport_reg_number,
        transport_gps_id,
        index: 0,
      })
    }

    return candidates
  }, [children, transport_gps_id, transport_name, transport_reg_number])

  if (!trackingTransports.length) {
    return null
  }

  // remove unnecessary clicks
  if (trackingTransports.length < 2) {
    return (
      <Button
        variant="outlined"
        onClick={() => window.open(trackingTransports[0].transport_gps_id!, '_blank')}
        fullWidth
        data-testid="bus-tracking"
      >
        {t('Orders.bus_tracking')}
      </Button>
    )
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleOpen}
        startIcon={
          <SvgIcon fontSize="small">
            <ExpandMoreIcon />
          </SvgIcon>
        }
        fullWidth
        data-testid="bus-tracking"
      >
        {t('Orders.bus_tracking')}
      </Button>

      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        anchorEl={anchorEl}
        open={open}
      >
        {trackingTransports.map((item) => (
          <MenuItem
            key={item.index}
            onClick={() => {
              window.open(item.transport_gps_id!, '_blank')
              handleClose()
            }}
          >
            <ListItemText
              primary={`${item.transport_name} ${item.transport_reg_number}`}
            ></ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
