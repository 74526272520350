import { getQueryPagination } from '../_byQuery'
import { ConnectedQueryFunctionContext } from '../_shared'
import { ListDataPaginationType } from '../_types'

import { apiSorryBonusFetchList } from './sorry-bonuses.api'
import { SorryBonusDTO, SorryBonusReqFetchList } from './sorry-bonuses.models'

export const sorryBonusesQueryKeys = {
  all: ['sorryBonuses'] as const,
  list: (params: SorryBonusReqFetchList = {}) => [...sorryBonusesQueryKeys.all, params] as const,
  types: ['sorryBonusTypes'] as const,
}

export type SorryBonusesQueryKeys = {
  all: readonly ['sorryBonuses']
  list: readonly [...SorryBonusesQueryKeys['all'], SorryBonusReqFetchList]
  types: readonly ['sorryBonusTypes']
}

export const sorryBonusesQueryFns = {
  getList: async (
    context: ConnectedQueryFunctionContext<SorryBonusesQueryKeys['list']>
  ): Promise<ListDataPaginationType<SorryBonusDTO>> => {
    const {
      pageParam = 1,
      queryKey: [, params],
    } = context

    const payload = {
      ...params,
      ...getQueryPagination({ pageParam, limit: params?.limit }),
    }

    const { data } = await apiSorryBonusFetchList(payload)
    return data
  },
}
