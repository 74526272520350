import { useResponsive } from '@klr/shared'

import { AxisPosition, Direction } from '../scheme.types'

export const useSchemePaintingParams = (defaultAxisPosition?: AxisPosition) => {
  const isMdScreen = useResponsive('down', 'md')

  const axisPosition =
    defaultAxisPosition ?? isMdScreen ? AxisPosition.VERTICAL : AxisPosition.HORIZONTAL

  const direction: Direction = axisPosition === AxisPosition.HORIZONTAL ? 'row-reverse' : 'column'

  return { axisPosition, direction }
}
