import { ReactNode } from 'react'
import { Box, BoxProps } from '@mui/material'

import { HEADER, NAV } from '../config'
import { useSettings } from '../contexts/SettingsContext'
import { useResponsive } from '../hooks'

const SPACING = 16

interface MainProps extends BoxProps {
  children: ReactNode
}

export const Main = ({ children, sx, ...other }: MainProps) => {
  const { themeLayout } = useSettings()
  const lgUp = useResponsive('up', 'lg')
  const mdUp = useResponsive('up', 'md')

  const isNavHorizontal = themeLayout === 'horizontal'
  const isNavMini = themeLayout === 'mini'

  if (isNavHorizontal) {
    return (
      <Box
        component="main"
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: 'column',
          pt: `${HEADER.H_MOBILE + SPACING}px`,
          pb: 10,
          ...(mdUp && {
            pb: 15,
          }),
        }}
      >
        {children}
      </Box>
    )
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(lgUp && {
          px: 2,
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.W_VERTICAL}px)`,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI}px)`,
          }),
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  )
}
