import { Theme } from '@mui/material/styles'
import merge from 'lodash/merge'

import { alert } from './components/alert'
import { appBar } from './components/appbar'
import { autocomplete } from './components/autocomplete'
import { avatar } from './components/avatar'
import { backdrop } from './components/backdrop'
import { badge } from './components/badge'
import { breadcrumbs } from './components/breadcrumbs'
import { card } from './components/card'
import { checkbox } from './components/checkbox'
import { chip } from './components/chip'
import { cssBaseline } from './components/css-baseline'
import { dataGrid } from './components/data-grid-premium'
import { datePicker } from './components/date-picker'
import { dialog } from './components/dialog'
import { drawer } from './components/drawer'
import { list } from './components/list'
import { menu } from './components/menu'
import { paper } from './components/paper'
import { popover } from './components/popover'
import { progress } from './components/progress'
import { radio } from './components/radio'
import { skeleton } from './components/skeleton'
import { switches } from './components/switch'
import { textField } from './components/textfield'
import { timeline } from './components/timeline'
import { tooltip } from './components/tooltip'

export function componentsOverrides(theme: Theme) {
  return merge(
    alert(theme),
    appBar(theme),
    autocomplete(theme),
    avatar(theme),
    backdrop(theme),
    badge(theme),
    breadcrumbs(theme),
    card(theme),
    checkbox(theme),
    chip(theme),
    cssBaseline(theme),
    dataGrid(theme),
    datePicker(theme),
    dialog(theme),
    drawer(theme),
    list(theme),
    menu(theme),
    paper(theme),
    popover(theme),
    progress(theme),
    radio(theme),
    skeleton(theme),
    switches(theme),
    textField(theme),
    timeline(theme),
    tooltip(theme)
  )
}
