import toast from 'react-hot-toast'
import { getI18n } from 'react-i18next'
import { isAxiosError } from 'axios'

import { ApiResponseError, ApiResponseMessageError } from '@klr/api-connectors'

import { AppModules } from '../../constants'

export const handleBackendError = (error: unknown, module?: AppModules | string | null) => {
  const i18n = getI18n()

  if (isAxiosError<ApiResponseError>(error)) {
    if (error.response && error.response.status >= 500) {
      toast.error(i18n.t('Notifications.Common.server_error'), {
        icon: '🚨',
      })
      return
    }

    if (error?.response?.data?.errors) {
      const messageStack: string[] = []

      let messages: string[]
      if (Array.isArray(error.response?.data.errors.detail)) {
        messages = error.response?.data.errors.detail
      } else {
        messages = Array.isArray(error.response?.data.errors.details)
          ? error.response?.data.errors.details
          : []
      }

      messages.forEach((message) => {
        // filter message from double quotes and dot at the end
        const fixedMessage = message.replace(/"/g, '').replace(/\.$/, '')
        const key = module
          ? `Notifications.${module}.${fixedMessage}`
          : `Notifications.${fixedMessage}`

        if (i18n && i18n.exists(key)) {
          messageStack.push(i18n.t(key))
        }
      })

      if (messageStack.length > 0) {
        messageStack.forEach((message) => {
          toast.error(message)
        })
        return
      }
    }
  }

  if (isAxiosError<ApiResponseMessageError>(error)) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message)
      return
    }
  }

  toast.error(i18n.t('Notifications.Common.general_error'))
}
