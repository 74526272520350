import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { apiTicketsConfirm, ordersQueryKeys, TicketDetail } from '@klr/api-connectors'
import { AppModules, handleBackendError } from '@klr/shared'

interface VariablesConfirmTicketParams {
  ticketId: TicketDetail['id']
  orderId: TicketDetail['order_id']
}

export const useConfirmTicket = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (variables: VariablesConfirmTicketParams) => {
      const { data } = await apiTicketsConfirm({
        ticketId: variables.ticketId,
        data: {
          confirmed: true,
        },
      })

      return data
    },
    mutationKey: ['tickets', 'confirm'],
    onError: (error) => {
      handleBackendError(error, AppModules.Tickets)
    },
    onSuccess: (_, variables) => {
      Promise.all([
        queryClient.invalidateQueries(ordersQueryKeys.detail(variables.orderId)),
        queryClient.invalidateQueries(['reportsTickets']),
      ]).then(() => {
        toast.success(t('Orders.ticket_confirmed'))
      })
    },
  })
}
