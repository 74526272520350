import { AxiosPromise } from 'axios'

import axios from '../../_axios/axios'
import { ListDataType } from '../../_types'
import { TagItem } from '../../tags'
import { CustomerDTO, CustomerReqTags } from '../models'

export const apiCustomerTagsFetchList = (
  id: CustomerDTO['id']
): AxiosPromise<ListDataType<TagItem>> => {
  return axios.get(`/customers/${id}/tags`)
}

export const apiCustomerTagsMutate = ({
  id,
  data,
}: CustomerReqTags): AxiosPromise<ListDataType<TagItem>> => {
  return axios.post(`/customers/${id}/tags`, data)
}
