import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { PatternFormat } from 'react-number-format'
import { TextField, TextFieldProps } from '@mui/material'

export type PhoneInputMaskProps = {
  value: string
  format?: string
} & Omit<TextFieldProps, 'defaultValue'>

export const PhoneInputMask = forwardRef((props: PhoneInputMaskProps, ref) => {
  const { t } = useTranslation()
  const {
    label = t('Auth.phone_number'),
    placeholder = '+380000000000',
    format = '+##############',
    ...rest
  } = props

  return (
    <PatternFormat
      fullWidth
      placeholder={placeholder}
      {...rest}
      customInput={TextField}
      inputRef={ref}
      type="tel"
      format={format}
      label={label}
    />
  )
})
