// noinspection ES6UnusedImports

import { Theme } from '@mui/material'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import type {} from '@mui/x-date-pickers-pro/themeAugmentation'

import { UKR_DATE_FORMAT, UKR_FULL_DATETIME_FORMAT } from '../../../constants'

export function datePicker(theme: Theme) {
  return {
    MuiDatePicker: {
      defaultProps: {
        format: UKR_DATE_FORMAT,
        slotProps: {
          textField: {
            fullWidth: true,
          },
        },
      },
    },
    MuiDateTimePicker: {
      defaultProps: {
        format: UKR_FULL_DATETIME_FORMAT,
        slotProps: {
          textField: {
            fullWidth: true,
          },
        },
      },
    },
    MuiPickersLayout: {
      styleOverrides: {
        // root: {
        //   '& .MuiPickersLayout-actionBar': {
        //     [`& .${buttonClasses.root}:last-of-type`]: {
        //       backgroundColor: theme.palette.text.primary,
        //       color:
        //         theme.palette.mode === 'light'
        //           ? theme.palette.common.white
        //           : theme.palette.grey[800],
        //     },
        //   },
        // },
      },
    },
  }
}
