import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { Grid } from '@mui/material'

import { TicketReqChangeData } from '@klr/api-connectors'
import { FormProvider, RHFTextField } from '@klr/shared'

import { useChangeTicket } from '../../../../../../../../hooks/tickets'

type Props = {
  isAllowEdit?: boolean
  disable: boolean
  ticketId: number
  defaultNote: string | null
}

type FormFields = {
  note: string
}

export const FormChangeNote = ({ isAllowEdit = true, disable, ticketId, defaultNote }: Props) => {
  const { t } = useTranslation()

  const { mutate, isLoading: loadingChangeTicket } = useChangeTicket(ticketId)

  const methods = useForm<FormFields>({
    defaultValues: {
      note: defaultNote || '',
    },
  })

  const {
    handleSubmit,
    formState: { isDirty },
  } = methods

  const _handleSubmit = (fields: FormFields): void => {
    const data: TicketReqChangeData = {
      information: fields.note,
    }

    mutate(data)
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(_handleSubmit)}>
      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <RHFTextField
            label={t('Common.note')}
            name="note"
            multiline
            rows={2}
            disabled={disable || loadingChangeTicket || !isAllowEdit}
          />
        </Grid>

        {isAllowEdit && (
          <Grid item container justifyContent="flex-end">
            <LoadingButton
              variant="outlined"
              disabled={disable || loadingChangeTicket || !isDirty}
              loading={disable || loadingChangeTicket}
              type="submit"
            >
              {t('Orders.save_note')}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </FormProvider>
  )
}
