import { useTranslation } from 'react-i18next'
import { Grid, Stack, SvgIcon, Typography } from '@mui/material'

import { BookingItem, PremiumPrice } from '@klr/api-connectors'
import { isCategoryPremium, isSeatCategory, PremiumIcon } from '@klr/schemes'

interface AdditionalPricesInfoProps {
  currencyTitle: BookingItem['price']['currency']
  prices: Partial<PremiumPrice>
}

export const AdditionalPricesInfo = ({ currencyTitle, prices }: AdditionalPricesInfoProps) => {
  const { t } = useTranslation()

  return Object.entries(prices).map(([key, value]) => {
    if (isSeatCategory(key)) {
      return (
        <Grid key={key} container justifyContent="center">
          <Grid item xs={1}>
            <SvgIcon color={isCategoryPremium(key) ? 'warning' : 'inherit'}>
              <PremiumIcon category={key} showStandard />
            </SvgIcon>
          </Grid>
          <Grid item xs={5} ml={1}>
            <Typography
              variant="body2"
              sx={{ color: isCategoryPremium(key) ? 'warning.main' : 'inherit' }}
            >
              {t(`Schemes.${key}`, { count: 1 })}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row" spacing={1}>
              <Typography>{value}</Typography>
              <Typography>{currencyTitle}</Typography>
            </Stack>
          </Grid>
        </Grid>
      )
    }

    return null
  })
}
