import { useRef } from 'react'

import { useActiveLink } from '@klr/shared'

import { NavListProps } from '../../../../shared/types'

import { NavItem } from './NavItem'

export const NavList = ({ data, depth, slotProps }: NavListProps) => {
  const navRef = useRef<HTMLDivElement | null>(null)

  const active = useActiveLink(data.path)

  return (
    <NavItem
      ref={navRef}
      //
      title={data.title}
      path={data.path}
      icon={data.icon}
      //
      depth={depth}
      //
      active={active}
      className={active ? 'active' : ''}
      sx={depth === 1 ? slotProps?.rootItem : slotProps?.subItem}
    />
  )
}
