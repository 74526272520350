// noinspection ES6UnusedImports

// This file is used to override the default MUI DataGrid component
// with the Premium version of the component.
// https://mui.com/x/react-data-grid/getting-started/#typescript
import { circularProgressClasses } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { alpha, Theme } from '@mui/material/styles'
import {} from '@mui/x-data-grid/themeAugmentation'
import {} from '@mui/x-data-grid-premium/themeAugmentation'
import {} from '@mui/x-data-grid-pro/themeAugmentation'

import { CustomNoRowsOverlay } from '../../components/CustomNoRowsOverlay'
import { paper } from '../../css'
import { customScrollbar } from '../../custom-scrollbar'

export function dataGrid(theme: Theme) {
  const paperStyles = paper({ theme, dropdown: true })

  return {
    MuiDataGrid: {
      defaultProps: {
        disableAggregation: true,
        disableRowGrouping: true,
        disableMultipleColumnsSorting: true,
        density: 'compact',
        slots: {
          noRowsOverlay: CustomNoRowsOverlay,
          loadingOverlay: LinearProgress,
        },
        slotProps: {
          toolbar: {
            printOptions: {
              hideFooter: true,
              hideToolbar: true,
            },
          },
        },
      },
      styleOverrides: {
        // root: {
        //   '--unstable_DataGrid-radius': 0,
        //   // '--unstable_DataGrid-headWeight': theme.typography.fontWeightSemiBold,
        //   borderWidth: 0,
        // },
        withBorderColor: {
          borderColor: theme.palette.divider,
        },
        //     // Column
        columnHeaders: {
          borderBottom: 0,
        },
        columnHeader: {
          fontSize: 14,
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.neutral,
          '&--sorted': {
            color: theme.palette.text.primary,
          },
        },
        columnSeparator: {
          color: theme.palette.divider,
        },
        // Row, Cell
        cell: {
          borderBottom: `1px dashed`,
          '&--editing': {
            boxShadow: 'none !important',
            backgroundColor: `${alpha(theme.palette.primary.main, 0.08)} !important`,
          },
        },
        // Paper
        paper: {
          ...paperStyles,
          padding: 0,
        },
        selectedRowCount: {
          display: 'none',
          whiteSpace: 'nowrap',
        },
        overlay: {
          [`& .${circularProgressClasses.root}`]: {
            color: theme.palette.text.primary,
          },
        },
        //     // Columns Panel
        panelHeader: {
          padding: theme.spacing(2, 2, 0, 2),
        },
        panelContent: {
          padding: theme.spacing(1),
        },
        virtualScroller: {
          '&::-webkit-scrollbar': customScrollbar(theme),
        },
      },
    },
  }
}
