import { memo, useState } from 'react'
import { X as CloseIcon } from 'react-feather'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { ContentPaste as ContentPasteIcon } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material'

import { apiNotificationsSendMessageToUser, notificationQueryKeys } from '@klr/api-connectors'
import {
  copyToClipboard,
  FormProvider,
  handleBackendError,
  RHFPhoneInputMask,
  RHFTextField,
  StyledCloseButton,
  useMutateCreate,
} from '@klr/shared'

import { TIME_BETWEEN_REQUESTS } from '../../../../auth.constants'

import { Timer } from './components'
import { FieldValues, formSchema } from './validations'

interface ModalFormProps {
  open: boolean
  setOpen: (value: boolean) => void
  inviteLink: string
}

export const ModalForm = memo(({ open, setOpen, inviteLink }: ModalFormProps) => {
  const { t } = useTranslation()

  const [time, setTime] = useState(0)
  const [hasSent, setHasSent] = useState(false)

  const methods = useForm<FieldValues>({
    defaultValues: {
      phone: '',
      message: inviteLink,
    },
    resolver: yupResolver(formSchema(t)),
  })

  const { handleSubmit } = methods

  const { mutateAsync, isLoading } = useMutateCreate({
    queryKey: notificationQueryKeys.all,
    createFn: apiNotificationsSendMessageToUser,
  })

  const onSubmit = (formData: FieldValues) => {
    mutateAsync(formData)
      .then(() => setHasSent(true))
      .catch((error) => handleBackendError(error))
    // No matter what result we get, we should block the button for a TIME_BETWEEN_REQUESTS
    setTime(TIME_BETWEEN_REQUESTS)
  }

  const clearState = () => {
    setTime(0)
    setHasSent(false)
  }

  const handleClose = () => {
    setOpen(false)
    clearState()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {t('Auth.invite_link')}
        <Tooltip title={t('Common.close')}>
          <StyledCloseButton aria-label="close" onClick={handleClose}>
            <CloseIcon fontSize="large" />
          </StyledCloseButton>
        </Tooltip>
      </DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            {hasSent ? (
              <Alert severity="success">{t('Messages.message_sent')}</Alert>
            ) : (
              <DialogContentText>{t('Auth.dialog_text')}</DialogContentText>
            )}
          </Box>

          <Stack spacing={3}>
            <RHFPhoneInputMask
              name="phone"
              label={t('Auth.phone')}
              onInput={clearState}
              size="small"
            />
            <RHFTextField
              name="message"
              label={t('Auth.invite_link')}
              size="small"
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => copyToClipboard(inviteLink, t('Messages.link_copied'))}
                    size="small"
                  >
                    <ContentPasteIcon fontSize="small" />
                  </IconButton>
                ),
                readOnly: true,
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Timer time={time} setTime={setTime} label={t('Auth.time_left', { count: time })} />
          <Button disabled={!!time || isLoading} variant="contained" type="submit">
            {t('Orders.send_message')}
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
})
