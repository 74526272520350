import { forwardRef, Ref } from 'react'
import { CircularProgress, SvgIcon } from '@mui/material'
import { useIsMutating } from '@tanstack/react-query'

import { Seat, SeatType } from '@klr/api-connectors'
import {
  AxisPosition,
  isCategoryPremium,
  PremiumIcon,
  StyledPremiumIconContainer,
  StyledSeatPlaceUnavailable,
  StyledSeatPlaceWrap,
  StyledTypographySeat,
  TransportPlaceLock,
} from '@klr/schemes'

import { StyledSeatPlaceAvailable } from '../place.styles'

interface SeatPlaceProps {
  axisPosition: AxisPosition
  seat: Seat
  onToggle: TransportPlaceLock
  isSingleSelected: boolean
  selectedByCurrentPassenger: boolean
  selectedByOtherPassenger: boolean
  transportId: number
}

export const SeatPlace = forwardRef(
  (
    {
      axisPosition,
      seat,
      onToggle,
      isSingleSelected,
      selectedByCurrentPassenger,
      selectedByOtherPassenger,
      transportId,
    }: SeatPlaceProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const isFetching = useIsMutating(['toggle-place', { transportId, place: seat.place }])

    return (
      <StyledSeatPlaceWrap ownerState={{ isInHover: false }} ref={ref}>
        {(seat.available || seat.selected) && !selectedByOtherPassenger ? (
          <StyledSeatPlaceAvailable
            ownerState={{
              axisPosition,
              selected: isSingleSelected ? selectedByCurrentPassenger : seat.selected,
              isPremium: isCategoryPremium(seat.category),
              inverted: seat.type === SeatType.SEAT_REVERSE,
            }}
            onClick={() => onToggle(transportId, seat)}
          >
            {isFetching === 1 ? (
              <CircularProgress color="warning" size={16} />
            ) : (
              <StyledTypographySeat
                variant="subtitle2"
                ownerState={seat.type === SeatType.SEAT_REVERSE}
              >
                {seat.place}
              </StyledTypographySeat>
            )}
            <StyledPremiumIconContainer>
              <SvgIcon sx={{ width: 12, height: 12 }}>
                <PremiumIcon category={seat.category} />
              </SvgIcon>
            </StyledPremiumIconContainer>
          </StyledSeatPlaceAvailable>
        ) : (
          <StyledSeatPlaceUnavailable className={axisPosition} />
        )}
      </StyledSeatPlaceWrap>
    )
  }
)
