import { AxiosPromise } from 'axios'

import { axios } from '../_axios'
import { DetailDataType, ListDataType } from '../_types'

import { BranchDTO, BranchReqChange, BranchReqCreate } from './branches.models'

export const apiBranchFetchList = (): AxiosPromise<ListDataType<BranchDTO>> => {
  return axios.get('/branches')
}

export const apiBranchFetchDetail = (
  id: BranchDTO['id']
): AxiosPromise<DetailDataType<BranchDTO>> => {
  return axios.get(`/branches/${id}`)
}

export const apiBranchReqCreate = (
  data: BranchReqCreate
): AxiosPromise<DetailDataType<BranchDTO>> => {
  return axios.post('/branches', data)
}

export const apiBranchReqChange = ({
  id,
  data,
}: BranchReqChange): AxiosPromise<DetailDataType<BranchDTO>> => {
  return axios.put(`/branches/${id}`, data)
}

export const apiBranchReqDelete = (id: BranchDTO['id']): AxiosPromise => {
  return axios.delete(`/branches/${id}`)
}
