import { memo } from 'react'
import { SvgIcon } from '@mui/material'

import { DriverWheelIcon } from '@klr/shared'

import { AxisPosition } from '../scheme.types'

export const DriverPlace = memo(({ axisPosition }: { axisPosition: AxisPosition }) => (
  <SvgIcon
    sx={(theme) => ({
      width: 48,
      height: 48,
      color: theme.palette.grey[theme.palette.mode === 'light' ? 900 : 100],
      ...(axisPosition === AxisPosition.HORIZONTAL && {
        transform: 'rotate(90deg)',
      }),
    })}
  >
    <DriverWheelIcon />
  </SvgIcon>
))
