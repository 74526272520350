import { UploadCloud as UploadIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  SvgIcon,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import { useDropZone } from '../Core/FileDropzone/useDropZone'

const StyledAvatar = styled(Avatar)(() => ({
  height: 100,
  width: 100,
  fontSize: '2rem',
  borderRadius: '50%',
  cursor: 'pointer',
}))

type Props = {
  isLoading: boolean
  name: string
  sourceFile: File | null
  sourcePath: string | null
  handleChange: (val: File) => void
  handleUpload: VoidFunction
}

export const AvatarProfile = ({
  isLoading,
  sourceFile,
  sourcePath,
  name,
  handleChange,
  handleUpload,
}: Props) => {
  const { t } = useTranslation()

  const { getRootProps, getInputProps } = useDropZone({
    onDrop: (acceptedFiles: File[]) => handleChange(acceptedFiles[0]),
  })

  return (
    <Card>
      <CardContent>
        <Box display="flex" alignItems="center" flexDirection="column" textAlign="center">
          <StyledAvatar
            data-testid="img-avatar"
            alt={name}
            src={sourceFile ? URL.createObjectURL(sourceFile) : sourcePath ?? ''}
            {...getRootProps()}
          />

          <input {...getInputProps()} />

          <Typography color="textPrimary" gutterBottom variant="h3" mt={1}>
            {name}
          </Typography>
        </Box>
      </CardContent>
      <Divider />

      <CardActions>
        <LoadingButton
          loading={isLoading}
          fullWidth
          onClick={handleUpload}
          disabled={isLoading || !sourceFile}
          endIcon={
            <SvgIcon fontSize="small">
              <UploadIcon />
            </SvgIcon>
          }
          data-testid="change-avatar"
        >
          {t('Globals.change_avatar')}
        </LoadingButton>
      </CardActions>
    </Card>
  )
}
