import { useContext } from 'react'

import { LocalizationContext } from './LocalizationContext'

export const useLocales = () => {
  const context = useContext(LocalizationContext)

  if (!context) {
    throw new Error('useLocalization must be used within a LocalizationProvider')
  }

  return context
}
