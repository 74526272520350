import { useCallback } from 'react'
import { ConfirmOptions, useConfirm as useMUIConfirm } from 'material-ui-confirm'

/**
 * It's a wrapper of dialog confirm library to process promise result.
 * @see https://github.com/jonatanklosko/material-ui-confirm/issues/41#issuecomment-980782976
 * @returns {(options: ConfirmOptions) => Promise<boolean>}
 */
export const useConfirm = (): ((options: ConfirmOptions) => Promise<boolean>) => {
  const confirm = useMUIConfirm()

  return useCallback(
    /**
     * @param {ConfirmOptions} options - Dialog confirm options.
     * @returns {boolean} - The result that indicates whether user approved his action or rejected.
     */
    async (options: ConfirmOptions): Promise<boolean> => {
      try {
        await confirm(options)
        return true
      } catch {
        return false
      }
    },
    [confirm]
  )
}
