import { forwardRef, Ref } from 'react'
import { SvgIcon } from '@mui/material'

import { Seat, SeatType } from '@klr/api-connectors'

import {
  StyledPremiumIconContainer,
  StyledSeatPlaceAvailable,
  StyledSeatPlaceUnavailable,
  StyledSeatPlaceWrap,
} from '../../../../../../scheme.styles'
import { AxisPosition } from '../../../../../../scheme.types'
import { isCategoryPremium } from '../../../../../../utils'
import { PremiumIcon } from '../../../../../PremiumIcon'

interface SeatPlaceProps {
  axisPosition: AxisPosition
  seat: Seat
  index: string
}

export const SeatPlace = forwardRef(
  ({ axisPosition, seat }: SeatPlaceProps, ref: Ref<HTMLDivElement>) => {
    return (
      <StyledSeatPlaceWrap ownerState={{ isInHover: seat.available }} ref={ref}>
        {seat.available || seat.selected ? (
          <StyledSeatPlaceAvailable
            ownerState={{
              axisPosition,
              isPremium: isCategoryPremium(seat.category),
              inverted: seat.type === SeatType.SEAT_REVERSE,
            }}
          >
            <StyledPremiumIconContainer>
              <SvgIcon sx={{ width: 12, height: 12 }}>
                <PremiumIcon category={seat.category} />
              </SvgIcon>
            </StyledPremiumIconContainer>
            {seat.place}
          </StyledSeatPlaceAvailable>
        ) : (
          <StyledSeatPlaceUnavailable className={axisPosition} />
        )}
      </StyledSeatPlaceWrap>
    )
  }
)
