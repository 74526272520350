import { useState } from 'react'
import { CornerDownLeft as ReturnIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Button, Grid } from '@mui/material'

import { PercentInput } from '@klr/shared'

type Props = {
  isLoading: boolean
  label: string
  onClick(val: number): void
}

export const ReturnFieldControl = ({ isLoading, onClick, label }: Props) => {
  const { t } = useTranslation()
  const [percent, setPercent] = useState(100)

  const handleClick = () => {
    onClick(percent)
  }

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item sm={6} md={4}>
        <PercentInput
          label={t('Orders.percentage_of_return')}
          value={percent}
          setValue={setPercent}
          disabled={isLoading}
          size="small"
        />
      </Grid>
      <Grid item sm={6} md={6}>
        <Button
          variant="outlined"
          onClick={handleClick}
          startIcon={<ReturnIcon size={16} />}
          disabled={isLoading}
          fullWidth
        >
          {label}
        </Button>
      </Grid>
    </Grid>
  )
}
