import { Box, Card, styled } from '@mui/material'

export const StyledCustomerCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.neutral,
  height: '100%',
}))

export const StyledCenteredContainer = styled(Box)({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const StyledLabelIcon = styled(Box)<{ ownerState: { color?: string } }>(
  ({ theme, ownerState }) => ({
    backgroundColor: ownerState.color ?? theme.palette.background.default,
    borderRadius: '50%',
    height: 16,
    width: 16,
    minWidth: 16,
  })
)
