import { TFunction } from 'react-i18next'
import * as Yup from 'yup'

import { SorryBonusDTO } from '@klr/api-connectors'
import { filterNonNumericValue, validateRequiredStringField } from '@klr/shared'

import { maxFixedValue, maxPercentValue, maxSmsTextLength, minValue } from '../../config'

export const sorryBonusValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    title: validateRequiredStringField(t, 150),
    type: validateRequiredStringField(t),
    text: validateRequiredStringField(t, 1000),
    value: Yup.number()
      .transform(filterNonNumericValue)
      .min(minValue, t('Validations.min_number', { count: minValue }))
      .when('$valueType', ([valueType]: SorryBonusDTO['value_type'][], schema) => {
        if (valueType === 'percent') {
          return schema.max(
            maxPercentValue,
            t('Validations.max_number', { count: maxPercentValue })
          )
        }

        return schema.max(maxFixedValue, t('Validations.max_number', { count: maxFixedValue }))
      })
      .required(t('Validations.req_field')),
    sms_text: validateRequiredStringField(t, maxSmsTextLength * 2).matches(
      /^[a-zA-Z0-9.,!?:;+-=)(*%”№\s]*$/,
      t('Validations.only_latin_numbers_specials')
    ),
  })

export type SorryBonusFormFields = Yup.InferType<ReturnType<typeof sorryBonusValidationSchema>>
