import { lazy } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

import { AuthGuard, authRoutes } from '@klr/auth'
import { bookingRoutes } from '@klr/booking'
import { customerReferralsRoutes } from '@klr/customers'
import { orderTicketToolkitRoutes } from '@klr/order-ticket-toolkit'
import { Loadable, sharedRoutes } from '@klr/shared'

import { DashboardLayout } from '../layouts/dashboard/DashboardLayout'

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/app/booking/search" replace />,
    },
    sharedRoutes,
    {
      path: 'app',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { index: true, element: <Navigate to="/app/booking/search" replace /> },
        bookingRoutes,
        customerReferralsRoutes,
        orderTicketToolkitRoutes,
        {
          path: 'reports',
          children: [
            {
              index: true,
              element: <Navigate to="/app/reports/orders" replace />,
            },
            { path: 'tickets', element: <ReportTickets /> },
          ],
        },
        { path: 'account/settings', element: <AccountSettings /> },
        { path: 'currencies', element: <Currencies /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    authRoutes,
    { path: '*', element: <Navigate to="/404" replace /> },
  ])
}

// DASHBOARD
const AccountSettings = Loadable(lazy(() => import('../pages/Account')))
const ReportTickets = Loadable(lazy(() => import('../pages/reports/Tickets')))
const Currencies = Loadable(lazy(() => import('../pages/Currencies')))
