import { createRoot } from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import Hotjar from '@hotjar/browser'
import { ReduxRouter } from '@lagunovsky/redux-react-router'
import * as Sentry from '@sentry/react'

import { axios } from '@klr/api-connectors'
import { LocalizationProvider, SharedProvider, SYSTEM_LANGUAGES } from '@klr/shared'

import { i18n } from './core/i18n'
import { history, store } from './core/store'
import App from './App'
import { environments } from './environments'
import SW from './sw'

axios.defaults.baseURL = environments.baseURL

if (process.env.NODE_ENV === 'production' && environments.env === 'production') {
  const siteId = 3801419
  const hotjarVersion = 6

  Hotjar.init(siteId, hotjarVersion)

  Sentry.init({
    dsn: 'https://e3631d9299524ca38c54024631c72cf0@o354726.ingest.sentry.io/4505275006058496',
    environment: environments.env,
    integrations: [new Sentry.BrowserTracing()],
    release: `spa-agents@${APP_VERSION}`,
    tracesSampleRate: 0.2,
  })
}

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <ReduxRouter history={history}>
        <SharedProvider themeLayout="horizontal">
          <LocalizationProvider appLanguages={SYSTEM_LANGUAGES}>
            <App />
            <SW />
          </LocalizationProvider>
        </SharedProvider>
      </ReduxRouter>
    </Provider>
  </I18nextProvider>
)
