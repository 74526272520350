import { createContext } from 'react'

import { AuthReqLogin } from '@klr/api-connectors'

import { AuthHasPermission, AuthHasRoleType, AuthStateType, AuthUserType } from './types'

export type JWTContextType = {
  authenticated: boolean
  unauthenticated: boolean
  permissions: AuthHasPermission

  login(payload: AuthReqLogin): Promise<void>
  logout(): Promise<void>
  changeUserData(user: Partial<AuthUserType>): void
} & AuthStateType &
  AuthHasRoleType

export const AuthContext = createContext({} as JWTContextType)
