import { Box, styled, Typography } from '@mui/material'

import { customScrollbar } from '@klr/shared'

import { AxisPosition } from './scheme.types'

//container
export const StyledBus = styled(Box)({
  border: '2px solid grey',
  borderRadius: '20px',
  padding: '10px',
  width: 'fit-content',
  margin: '0 auto',
})

export const StyledCardBox = styled(Box)(({ theme }) => ({
  '&::-webkit-scrollbar': customScrollbar(theme),
}))

//cell
export const StyledPlace = styled(Box)({
  width: 48,
  height: 48,
})

export const StyledSeatPlace = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  position: 'relative',
  fontSize: theme.typography.pxToRem(14),
  fontWeight: theme.typography.fontWeightMedium,
  border: '2.5px solid',
  '&:after': {
    content: '" "',
    position: 'absolute',
    border: '2px solid',
  },
}))

export const StyledSeatPlaceWrap = styled(Box)<{ ownerState: { isInHover: boolean } }>(
  ({ ownerState }) => ({
    padding: 3,
    display: 'block',
    position: 'relative',
    margin: 0,
    width: '100%',
    height: '100%',
    ...(ownerState.isInHover && {
      transition: 'transform 0.2s ease-in-out',
      '&:hover': {
        transform: 'scale(1.1)',
        zIndex: 1,
      },
    }),
  })
)

export const StyledPremiumIconContainer = styled(Box)({
  position: 'absolute',
  top: 2,
  right: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const StyledStairs = styled('img', {
  shouldForwardProp: (prop) => prop !== 'editMode',
})<{ editMode?: boolean }>(({ theme, editMode = false }) => ({
  backgroundColor: theme.palette.grey[400],
  [`&.${AxisPosition.HORIZONTAL}`]: {
    transform: 'rotate(90deg)',
  },
  cursor: editMode ? 'grab' : 'auto',
  opacity: editMode ? 0.2 : 1,
}))

export const StyledTable = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '2px solid',
  width: 'calc(100% - 2px)',
  height: 'calc(100% - 2px)',
  borderColor: theme.palette.grey[500],
  backgroundColor: theme.palette.grey[200],
  margin: '2px',
}))

export const StyledWC = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'editMode',
})<{ editMode?: boolean }>(({ theme, editMode = false }) => ({
  width: 48,
  height: 48,
  lineHeight: '48px',
  textAlign: 'center',
  color: theme.palette.grey[theme.palette.mode === 'light' ? 900 : 100],
  cursor: editMode ? 'grab' : 'auto',
  opacity: editMode ? 0.2 : 1,
}))

export const StyledSeatPlaceUnavailable = styled(StyledSeatPlace)(({ theme }) => ({
  color: theme.palette.grey[400],
  backgroundColor: theme.palette.grey[300],
  '&:after, &:before': {
    content: '" "',
    position: 'absolute',
    width: 24,

    height: 2,
    background: theme.palette.grey[400],
    border: 'none',
    color: theme.palette.grey[300],
  },
  '&:after': {
    transform: 'rotate(45deg)',
  },
  '&:before': {
    transform: 'rotate(-45deg)',
  },
  '&:hover': {
    cursor: 'not-allowed',
  },
  [`&.${AxisPosition.HORIZONTAL}`]: {
    borderRadius: '6px 18px 18px 6px',
    '&:after, &:before': {
      left: 'calc(50% - 13px)',
      bottom: 'calc(50% - 1px)',
    },
  },
  [`&.${AxisPosition.VERTICAL}`]: {
    borderRadius: '18px 18px 6px 6px',
    '&:after, &:before': {
      left: 'calc(50% - 12px)',
      bottom: 'calc(50% - 2px)',
    },
  },
}))

type PlaceAvailableProps = {
  axisPosition: AxisPosition
  inverted: boolean
  isPremium: boolean
  isNonUniqPlace?: boolean
}

export const StyledSeatPlaceAvailable = styled(StyledSeatPlace)<{
  ownerState: PlaceAvailableProps
}>(({ theme, ownerState }) => {
  const { axisPosition, inverted, isPremium, isNonUniqPlace } = ownerState

  const borderRadiusStyle = {
    [AxisPosition.HORIZONTAL]: '6px 18px 18px 6px',
    [AxisPosition.VERTICAL]: '18px 18px 6px 6px',
  }

  const afterWidth = {
    [AxisPosition.HORIZONTAL]: 8,
    [AxisPosition.VERTICAL]: 'calc(100% + 4px)',
  }

  const afterHeight = {
    [AxisPosition.HORIZONTAL]: 'calc(100% + 4px)',
    [AxisPosition.VERTICAL]: 8,
  }

  const getBackGroundColor = () => {
    if (isNonUniqPlace) {
      return theme.palette.error.light
    }
    return isPremium ? theme.palette.warning.light : theme.palette.grey[100]
  }

  return {
    borderColor: isPremium ? theme.palette.warning.main : theme.palette.grey[400],
    borderRadius: borderRadiusStyle[axisPosition],
    color: theme.palette.grey[700],
    backgroundColor: getBackGroundColor(),
    transform: inverted ? 'rotate(180deg)' : 'none',
    cursor: 'pointer',
    '&:after': {
      bottom: -2,
      left: -2,
      borderRadius: '9px',
      backgroundColor: isPremium ? theme.palette.warning.light : theme.palette.grey[300],
      width: afterWidth[axisPosition],
      height: afterHeight[axisPosition],
    },
  }
})

export const StyledTypographySeat = styled(Typography)<{
  ownerState: PlaceAvailableProps['inverted']
}>(({ ownerState }) => ({
  transform: ownerState ? 'rotate(-180deg)' : 'none',
}))
