import { DateType } from '../../_types'

import { CustomerDTO } from './customers.models'

export enum CustomerSocialEnum {
  FACEBOOK = 'facebook',
  TELEGRAM = 'telegram',
  INSTAGRAM = 'instagram',
  TIKTOK = 'tiktok',
}

export interface CustomerSocialNetwork {
  created_at: DateType
  id: number
  link: string
  type: CustomerSocialEnum
  updated_at: DateType
}

export interface CustomerReqSocialsCreate {
  id: CustomerDTO['id']
  data: {
    type: CustomerSocialEnum
    link: string
  }
}

export interface CustomerReqSocialsUpdate {
  id: CustomerDTO['id']
  data: CustomerReqSocialsCreate['data'] & {
    social_id: CustomerSocialNetwork['id']
  }
}

export interface CustomerReqSocialsDelete {
  id: CustomerDTO['id']
  social_id: CustomerSocialNetwork['id']
}
