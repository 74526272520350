import {
  AirlineSeatFlatAngled,
  AirlineSeatReclineExtra,
  AllInclusive,
  Panorama,
  TableBar,
  VideoLabel,
  Weekend,
} from '@mui/icons-material'

import { SeatCategory } from '@klr/api-connectors'

type PremiumIconProps = {
  category: SeatCategory | null
  showStandard?: boolean
}

export const PremiumIcon = ({ category, showStandard }: PremiumIconProps) => {
  switch (category) {
    case SeatCategory.STANDARD:
      return showStandard ? <AirlineSeatReclineExtra /> : null
    case SeatCategory.PREMIUM:
      return <Weekend />
    case SeatCategory.PREMIUM_COMFORT:
      return <AirlineSeatFlatAngled />
    case SeatCategory.PREMIUM_PANORAMA:
      return <Panorama />
    case SeatCategory.PREMIUM_TABLE:
      return <TableBar />
    case SeatCategory.PREMIUM_EXTRA_DISTANCE:
      return <AllInclusive />
    case SeatCategory.PREMIUM_WINDOW:
      return <VideoLabel />
    default:
      return null
  }
}
