import { createContext, useContext } from 'react'

import { OrderDetail } from '@klr/api-connectors'

export interface OrderDetailContextType {
  marketplaceURL: string
  closeOrderDetail(): void
  showOrderDetail(orderId: OrderDetail['id']): void
}

export const OrderDetailContext = createContext({} as OrderDetailContextType)

export const useOrderDetailContext = () => {
  const context = useContext(OrderDetailContext)

  if (!context) {
    throw new Error('useOrderDetailContext must be used within OrderDetailProvider')
  }

  return context
}
