import { Suspense } from 'react'
import { Dialog, dialogClasses, DialogProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import * as Sentry from '@sentry/react'

import { AlertError, Fallback } from '../Core'

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.paper}`]: {
    backgroundColor: theme.palette.background.default,
    maxWidth: '100%',
    width: 650,
    [theme.breakpoints.down('md')]: {
      maxHeight: '100%',
      height: '100%',
      width: '100%',
      margin: '0',
      borderRadius: '0',
    },
  },
}))

export const DrawerDetail = ({ children, ...props }: DialogProps) => (
  <StyledDialog {...props} scroll="body">
    <Sentry.ErrorBoundary fallback={(errorData) => <AlertError {...errorData} />}>
      <Suspense fallback={<Fallback />}>{children}</Suspense>
    </Sentry.ErrorBoundary>
  </StyledDialog>
)
