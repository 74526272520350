import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Avatar, Box, Divider, IconButton, MenuItem, Stack, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { m } from 'framer-motion'

import { AccountCurrentAgent } from '@klr/api-connectors'
import { CustomPopover, usePopover, varHover } from '@klr/shared'

import { useAuth } from '../context'

interface AccountPopoverProps {
  slotAdditional?: ReactNode
}

export const AccountPopover = ({ slotAdditional }: AccountPopoverProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const OPTIONS = [
    {
      label: t('Auth.account'),
      linkTo: '/app/account/settings/',
      dataTestId: 'profile-button',
    },
  ]

  const { user, logout } = useAuth()

  const popover = usePopover()

  const handleLogout = async () => {
    try {
      await logout()
      popover.onClose()
      navigate('/', { replace: true })
    } catch (error) {
      console.error(error)
    }
  }

  const handleClickItem = (path: string) => {
    popover.onClose()
    navigate(path)
  }

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
        data-testid="profile-icon"
      >
        <Avatar
          src={(user as AccountCurrentAgent)?.logo ?? '/static/images/avatars/avatar.jpg'}
          alt={user?.first_name}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {user?.first_name?.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.first_name} {user?.last_name}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => handleClickItem(option.linkTo)}
              data-testid={option.dataTestId}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        {slotAdditional}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
          data-testid="profile-logout-button"
        >
          {t('Auth.logout')}
        </MenuItem>
      </CustomPopover>
    </>
  )
}
