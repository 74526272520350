export enum LoyaltyLevelTitle {
  LEVEL_1 = 'researcher',
  LEVEL_2 = 'traveler',
  LEVEL_3 = 'legend',
  LEVEL_4 = 'ambassador',
}

export interface LoyaltyLevelDTO {
  id: number
  name: string
  slug: LoyaltyLevelTitle
  min_cost: number
  max_cost: number
  percent: number
}

export interface LoyaltyLevelRequest {
  loyalty_level_id: LoyaltyLevelDTO['id']
  percent: LoyaltyLevelDTO['percent'] | undefined
}
