import { useContext } from 'react'

import { SettingsContext } from './SettingsContext'
import { SettingsContextProps } from './types'

export const useSettings: () => SettingsContextProps = () => {
  const context = useContext(SettingsContext)

  if (!context) {
    throw new Error('Setting context must be use inside SettingsProvider')
  }

  return context
}
