import { LoyaltyLevelDTO, LoyaltyLevelTitle } from '@klr/api-connectors'

import { CashbackFormFields } from '../../validations'

export const mapServerValuesToFormFields: Record<
  LoyaltyLevelDTO['slug'],
  keyof CashbackFormFields
> = {
  [LoyaltyLevelTitle.LEVEL_1]: 'level_1',
  [LoyaltyLevelTitle.LEVEL_2]: 'level_2',
  [LoyaltyLevelTitle.LEVEL_3]: 'level_3',
  [LoyaltyLevelTitle.LEVEL_4]: 'level_4',
}
