import * as React from 'react'
import { styled } from '@mui/material/styles'

const StyledRoot = styled('a')(() => ({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  color: 'inherit',
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline',
  },
}))

type Props = {
  href: string
  children: React.ReactNode
  target?: string
}

// TODO: Добавити наслідування
export const CustomLink = React.memo(function CustomLink({
  href,
  target = '_self',
  children,
}: Props) {
  return (
    <StyledRoot href={href} target={target}>
      {children}
    </StyledRoot>
  )
})
