import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

import {
  apiNotificationsSendMessageToClient,
  DetailType,
  notificationQueryKeys,
  NResSendMessageToClient,
} from '@klr/api-connectors'
import { handleBackendError } from '@klr/shared'

export const useSendMessageToClient = () => {
  const { t } = useTranslation()

  return useMutation({
    mutationFn: apiNotificationsSendMessageToClient,
    mutationKey: [...notificationQueryKeys.all, 'send_message_to_client'],
    onError: (error) => handleBackendError(error),
    onSuccess: (response: AxiosResponse<DetailType<NResSendMessageToClient>>) => {
      const { not_informed_messages, informed_messages } = response.data.item

      if (not_informed_messages?.length) {
        toast.error(`${t('Messages.not_sent_messages_for_some_passengers')} 🚧`)
      }

      if (informed_messages?.length) {
        toast.success(`${t('Messages.messages_have_been_sent')} ✉️`)
      }
    },
  })
}
