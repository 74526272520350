import { useReducer } from 'react'

import { ActionsType } from '@klr/shared'

import { AuthStateType, AuthUserType } from '../types'

export enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  MUTATE_USER = 'MUTATE_USER',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType
  }
  [Types.LOGIN]: {
    user: AuthUserType
  }
  [Types.LOGOUT]: undefined
  [Types.MUTATE_USER]: {
    user: AuthUserType
  }
}

const initialState: AuthStateType = {
  user: null,
  loading: true,
}

const reducer = (state: AuthStateType, action: ActionsType<Payload>) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    }
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    }
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    }
  }
  if (action.type === Types.MUTATE_USER) {
    return {
      ...state,
      user: action.payload.user,
    }
  }
  return state
}

export const useAuthStore = () => useReducer(reducer, initialState)
