import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  apiTicketsDelete,
  ordersQueryKeys,
  TicketDetail,
  TicketReqDelete,
} from '@klr/api-connectors'
import { useConfirm } from '@klr/shared'

type UseDeleteTicketParams = {
  ticketId: TicketDetail['id']
  orderId: TicketDetail['order_id']
}

export const useDeleteTicket = ({ ticketId, orderId }: UseDeleteTicketParams) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const confirm = useConfirm()

  const mutation = useMutation({
    mutationFn: async (variables: TicketReqDelete['params']) => {
      const request: TicketReqDelete = {
        ticketId,
        params: {},
      }

      if (variables?.information) {
        request.params.information = variables.information
      }

      return await apiTicketsDelete(request)
    },
    mutationKey: ['tickets', ticketId, 'delete'],
    onError: () => {
      toast.error(t('Messages.failed_to_delete_ticket'))
    },
    onSuccess: (response) => {
      if (response.status === 204) {
        queryClient.invalidateQueries(ordersQueryKeys.detail(orderId)).then(() => {
          toast.success(`${t('Orders.ticket_deleted')} 🗑`)
        })
      }
    },
  })

  const handleDeleteTicket = (params: TicketReqDelete['params'] = {}) => {
    confirm({
      title: t('Messages.sure_should_return_ticket'),
      description: t('Messages.will_whole_returning'),
    }).then((success) => {
      if (success) {
        mutation.mutate(params)
      }
    })
  }

  return {
    handleDeleteTicket,
    ...mutation,
  }
}
