import * as React from 'react'
import { Edit as EditIcon, Trash2 as TrashIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { IconButton, SvgIcon, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledDelete = styled(SvgIcon)(({ theme }) => ({
  color: theme.palette.error.light,
}))

const StyledEdit = styled(SvgIcon)(({ theme }) => ({
  color: theme.palette.success.light,
}))

type Props = {
  handleEdit: VoidFunction
  handleDelete: VoidFunction
}

export const CellActions = ({ handleEdit, handleDelete }: Props) => {
  const { t } = useTranslation()

  return (
    <div>
      <Tooltip title={t('Common.edit')} aria-label={t('Common.edit')}>
        <IconButton onClick={handleEdit} size="large">
          <StyledEdit fontSize="small">
            <EditIcon />
          </StyledEdit>
        </IconButton>
      </Tooltip>

      <Tooltip title={t('Common.delete')} aria-label={t('Common.delete')}>
        <IconButton onClick={handleDelete} size="large">
          <StyledDelete fontSize="small">
            <TrashIcon />
          </StyledDelete>
        </IconButton>
      </Tooltip>
    </div>
  )
}
