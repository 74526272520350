import { ReactNode, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { globalPaths } from '@klr/shared'

import { useAuth } from '../context'

type Props = {
  children: ReactNode
}

export const AuthGuard = ({ children }: Props) => {
  const navigate = useNavigate()

  const { authenticated } = useAuth()

  const [checked, setChecked] = useState(false)

  const check = useCallback(() => {
    if (!authenticated) {
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString()

      const href = `${globalPaths.auth.login}?${searchParams}`

      navigate(href, { replace: true })
    } else {
      setChecked(true)
    }
  }, [authenticated, navigate])

  useEffect(() => {
    check()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!checked) {
    return null
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
