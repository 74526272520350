export const enum AppModules {
  Auth = 'Auth',
  Booking = 'Booking',
  DiscountRules = 'Discount_Rules',
  Orders = 'Orders',
  Tickets = 'Tickets',
  Trip = 'Trip',
  SystemEntities = 'System_Entities',
  Scheme = 'Scheme',
  Bonuses = 'Bonuses',
}
