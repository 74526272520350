import { BusCell, Floor, PremiumPrice, Seat, SeatCategory, SeatType } from '@klr/api-connectors'

export const seatMock = (place: number): Seat => ({
  type: SeatType.SEAT,
  place,
  available: true,
  selected: false,
  category: SeatCategory.STANDARD,
  price_for_category: 0,
})

export const isSeat = (cell: BusCell): cell is Seat =>
  cell.type === SeatType.SEAT || cell.type === SeatType.SEAT_REVERSE
export const isSeatCategory = (category: string): category is SeatCategory =>
  Object.values(SeatCategory).includes(category as SeatCategory)

const PREMIUM_CATEGORIES = [
  SeatCategory.PREMIUM,
  SeatCategory.PREMIUM_COMFORT,
  SeatCategory.PREMIUM_EXTRA_DISTANCE,
  SeatCategory.PREMIUM_PANORAMA,
  SeatCategory.PREMIUM_TABLE,
  SeatCategory.PREMIUM_WINDOW,
]

export const isSurchargePriceCategory = (
  category: string | null
): category is keyof PremiumPrice =>
  category
    ? [SeatCategory.STANDARD, ...PREMIUM_CATEGORIES].includes(category as SeatCategory)
    : false

export const isCategoryPremium = (category: SeatCategory | null) =>
  category ? PREMIUM_CATEGORIES.includes(category) : false

export const getPlaceInScheme = (schemeItem: Floor[], place: Seat['place'] | undefined) => {
  const flattenedScheme = schemeItem.flat(2)

  return flattenedScheme.find((item) => isSeat(item) && item.place === place) as Seat | undefined
}
