import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Box, BoxProps, styled } from '@mui/material'

interface PageProps extends BoxProps {
  title?: string
}

const StyledWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
}))

export const Page = ({ children, title = '', ...rest }: PageProps) => (
  <StyledWrapper {...rest}>
    <Helmet>{title && <title>{title} | KLR Bus</title>}</Helmet>
    {children}
  </StyledWrapper>
)
