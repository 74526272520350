import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledActionTicketCardGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2),
  },
}))

export const StyledActionTicketTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'placeStatus',
})<{ placeStatus: 'success' | 'warning' | 'error' }>(({ placeStatus, theme }) => {
  switch (placeStatus) {
    case 'success':
      return {
        color: theme.palette.success.main,
      }
    case 'warning':
      return {
        color: theme.palette.warning.main,
      }
    case 'error':
      return {
        color: theme.palette.error.main,
      }
    default:
      return null
  }
})
