import { isAfter } from 'date-fns'

import { BookingItem } from '@klr/api-connectors'
import { defaultCarrier } from '@klr/system-entities'

export const getSortedTripList = (tripList: BookingItem[]) => {
  const sortedTrips = tripList.sort((currentTrip, nextTrip) =>
    isAfter(new Date(currentTrip.start_date_time), new Date(nextTrip.start_date_time)) ? 1 : -1
  )

  const defaultCarrierTrips = sortedTrips.filter((item) => item.carrier_id === defaultCarrier.id)
  const anotherTrips = sortedTrips.filter((item) => item.carrier_id !== defaultCarrier.id)

  const anotherSortedTrips = anotherTrips.sort((currentTrip, nextTrip) => {
    if (currentTrip.carrier_recommended !== nextTrip.carrier_recommended) {
      return Number(nextTrip.carrier_recommended) - Number(currentTrip.carrier_recommended)
    }
    if (currentTrip.route_coefficient !== nextTrip.route_coefficient) {
      return nextTrip.route_coefficient - currentTrip.route_coefficient
    }
    return 0
  })

  return [...defaultCarrierTrips, ...anotherSortedTrips]
}
