import { X as CloseIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { DialogTitle, IconButton, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

type Props = {
  title: string
  closeDrawer: () => void
}

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(0.5),
  color: theme.palette.grey[500],
}))

export const HeaderDrawer = ({ closeDrawer, title }: Props) => {
  const { t } = useTranslation()

  return (
    <DialogTitle sx={{ m: 0, p: 2 }}>
      <Typography variant="h4" component="span">
        {title}
      </Typography>
      <Tooltip title={t('Common.close')}>
        <StyledCloseButton aria-label="close" onClick={closeDrawer} size="large">
          <CloseIcon fontSize="large" />
        </StyledCloseButton>
      </Tooltip>
    </DialogTitle>
  )
}
