import { ChangeEvent, useState } from 'react'
import { Mail as MailIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { MenuItem, Stack, TextField } from '@mui/material'
import { AxiosResponse } from 'axios'

import {
  DetailType,
  MessageDispatchTemplate,
  NMessageToClient,
  NResSendMessageToClient,
  NSuccessMessage,
} from '@klr/api-connectors'

import { useSendMessageToClient } from '../../hooks'

interface MessageDispatchFormProps {
  templates: MessageDispatchTemplate[]
  messages: NMessageToClient[]
  messageTemplate: string
  setMessageTemplate(value: string): void
  successSubmitCallback?(notifications: NSuccessMessage[] | null): void
}

export const MessageDispatchForm = ({
  templates,
  messages,
  messageTemplate,
  setMessageTemplate,
  successSubmitCallback,
}: MessageDispatchFormProps) => {
  const { t } = useTranslation()
  const [currentPlace, setCurrentPlace] = useState(0)

  const { mutateAsync, isLoading } = useSendMessageToClient()

  const handleChangeTemplate = (event: ChangeEvent<HTMLInputElement>) => {
    const current = templates.find(
      (item: MessageDispatchTemplate) => item.id === +event.target.value
    )

    if (current) {
      setCurrentPlace(current.id)
      setMessageTemplate(current.template)
    } else {
      setCurrentPlace(0)
      setMessageTemplate('')
    }
  }

  const handleSubmit = () => {
    mutateAsync({ messages }).then(
      (response: AxiosResponse<DetailType<NResSendMessageToClient>>) => {
        successSubmitCallback && successSubmitCallback(response.data.item.informed_messages)
      }
    )
  }

  return (
    <Stack spacing={2} width={350} pt={1}>
      <TextField
        select
        label={t('Common.message_templates')}
        value={currentPlace}
        onChange={handleChangeTemplate}
        variant="outlined"
      >
        <MenuItem value={0}>Кастомне повідомлення</MenuItem>
        {templates.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label={t('Common.message_to_send')}
        multiline
        minRows={6}
        onChange={(event) => setMessageTemplate(event.target.value)}
        value={messageTemplate}
        variant="outlined"
      />
      <LoadingButton
        fullWidth
        onClick={handleSubmit}
        variant="outlined"
        endIcon={<MailIcon />}
        color="primary"
        size="large"
        disabled={!messageTemplate || isLoading}
        loading={isLoading}
      >
        {t('Common.send')}
      </LoadingButton>
    </Stack>
  )
}
