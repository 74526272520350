import { AxiosPromise } from 'axios'

import { axios } from '../../_axios'
import { DetailType, ListType } from '../../_types'
import {
  TicketDetail,
  TicketPrint,
  TicketReqPaidReserve,
  TicketReqPrintPdf,
  TicketReqTransportStatuses,
  TicketResConfirmedTransport,
  TicketResReconciliation,
} from '../models'

/**
 * Отримати PDF по квитку
 *
 * @param ticketId
 * @param isMainPdf
 */
export const apiTicketsPrintPdf = ({
  ticketId,
  params = {},
}: TicketReqPrintPdf): AxiosPromise<DetailType<TicketPrint>> => {
  return axios.get(`/tickets/${ticketId}/pdf`, {
    params,
  })
}

/**
 * Отримати PDF по квиткам
 *
 * @param ticketIds
 */
export const apiBulkTicketsPrintPdf = (
  ticketIds: TicketDetail['id'][]
): AxiosPromise<DetailType<TicketPrint>> => {
  return axios.post(`/tickets/bulk-pdf-generation`, {
    ticket_ids: ticketIds,
  })
}

/**
 * Отримати інформацію по транспорту для квитка
 *
 * @param ticketId
 * @param with_details
 */
export const apiTicketsFetchTransportsStatus = ({
  ticketId,
  with_details,
}: TicketReqTransportStatuses): AxiosPromise<ListType<TicketResConfirmedTransport>> => {
  return axios.get(`/tickets/${ticketId}/transports-status`, {
    params: { with_details },
  })
}

/**
 * Встановити значення звірки
 *
 * @param ticketIds
 */
export const apiTicketsReconciliation = (
  ticketIds: TicketDetail['id'][]
): AxiosPromise<TicketResReconciliation> => {
  return axios.post('/tickets/reconciliation', {
    ticket_ids: ticketIds,
  })
}

/**
 * Внесення даних про оплату
 *
 * @param ticketId
 * @param paidAt
 */
export const apiTicketsPaidReserve = ({
  ticketId,
  paidAt,
}: TicketReqPaidReserve): AxiosPromise<DetailType<TicketDetail>> => {
  return axios.post(`/tickets/${ticketId}/paid`, {
    paid_at: paidAt,
  })
}
