import { useMemo } from 'react'
import { Printer as PrinterIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { Grid } from '@mui/material'

import { TicketDetail } from '@klr/api-connectors'
import { useAuth } from '@klr/auth'
import { hasConfirmTicket } from '@klr/shared'

import { useOrderDetailContext } from '../../../../../../../../context'
import { getAllowedActionsTickets } from '../../../../../../../../tickets.utils'
import { CustomReturnPercent } from '../../../../../../../CustomReturnPercent'
import { ReturnFieldControl } from '../../../../../../../ReturnFieldControl'
import { TicketPriceInfo } from '../../../../../../../TicketPriceInfo'
import { TrackingBusInfo } from '../../../../../../../TrackingBusInfo'

import { ActionButtonList } from './components/ActionButtonList'
import { useTicketActionsController } from './useTicketActionsController'

type Props = {
  ticketData: TicketDetail
  disable: boolean
}

export const ActionsTicket = ({ ticketData, disable }: Props) => {
  const { t } = useTranslation()
  const { marketplaceURL } = useOrderDetailContext()

  const { permissions, user } = useAuth()

  const {
    handleConfirmTicket,
    handleCustomReturnPercent,
    handleDeleteTicket,
    handlePrintPdf,
    handleReturnTicket,
    isLoading: isHandling,
    loadingPrintPdf,
  } = useTicketActionsController({ ticketData })

  const allowActions = useMemo(
    () =>
      getAllowedActionsTickets({
        isEarlyBooking: ticketData.is_early_booking,
        status: ticketData.status,
        hasFullAccess: permissions.hasFullAccessToOrder,
      }),
    [permissions.hasFullAccessToOrder, ticketData.is_early_booking, ticketData.status]
  )

  const isLoading = disable || isHandling

  return (
    <Grid container alignItems="center" spacing={2}>
      {allowActions.hasPrintTicket && (
        <Grid item xs={12} md={6}>
          <LoadingButton
            variant="outlined"
            onClick={() => handlePrintPdf()}
            startIcon={<PrinterIcon size={16} />}
            fullWidth
            disabled={isLoading}
            loading={loadingPrintPdf}
          >
            {t('Common.print')}
          </LoadingButton>
        </Grid>
      )}

      <ActionButtonList
        actionHandlers={{
          handleConfirmTicket: () =>
            handleConfirmTicket({
              ticketId: ticketData.id,
              orderId: ticketData.order_id,
            }),
          handleDeleteTicket: () => handleDeleteTicket({}),
        }}
        allowedActions={{
          hasConfirmTicket:
            !ticketData.confirmed && hasConfirmTicket(ticketData.status, ticketData.can_reserve),
          hasDeleteTicket: allowActions.hasDeleteTicket || permissions.hasFullAccessToOrder,
        }}
        isLoadingAction={isLoading}
        paymentLink={{
          allowed: user?.has_payment_system && allowActions.isShowPaymentLink,
          url: `${marketplaceURL}/booking-payment/${ticketData.order_id}`,
        }}
      />

      {!allowActions.hasReturnedTicket && (
        <Grid item xs={12} md={6}>
          <TrackingBusInfo dataSource={ticketData} />
        </Grid>
      )}

      {allowActions.hasReturnTicket && permissions.canReturnPercent && (
        <ReturnFieldControl
          isLoading={isLoading}
          onClick={(percent) => handleReturnTicket(percent)}
          label={t('Orders.return_ticket')}
        />
      )}

      <TicketPriceInfo
        ticketData={ticketData}
        handleReturn={() => handleReturnTicket()}
        hasReturnTicket={allowActions.hasReturnTicket}
        hasReturnedTicket={allowActions.hasReturnedTicket}
        disable={isLoading}
      />

      {allowActions.hasReturnTicket && permissions.canReturnPercent && (
        <Grid item xs={12}>
          <CustomReturnPercent
            handleCustomReturnPercent={handleCustomReturnPercent}
            isLoading={isLoading}
          />
        </Grid>
      )}
    </Grid>
  )
}
