import { uniq } from 'lodash'

import { Floor, RowOfSeats, Seat, SeatType } from '@klr/api-connectors'

import { NUMBER_OF_ROWS } from '../scheme.constants'
import { PlaceInsert, Scheme, SchemeCell, SchemeRow } from '../scheme.types'

import { isSeat } from './scheme.fns'
import { otherAttributes } from './scheme.test-constants'

interface GenerateSchemeType {
  firstFloorCellsCount: number
  secondFloorCellsCount?: number
  includeSeatInCorridor?: boolean
}

const getCurrentPlace = (initialPlace: number) => ({
  place: initialPlace,
  ...otherAttributes,
})

export const getSchemeObjectFromArray = (scheme: Floor[] | undefined): Scheme => {
  const getRowKey = (index: number) => {
    if (index === 0) return 'left'
    if (index === 1) return 'left_center'
    if (index === 2) return 'center'
    if (index === 3) return 'right_center'
    if (index === 4) return 'right'
    return null
  }

  const result: Scheme = {
    floorCount: 1,
    floor: {
      first: null,
      second: null,
    },
  }

  if (!scheme) return result

  scheme.forEach((floor, index) => {
    const floorKey = index === 0 ? 'first' : 'second'

    result.floor[floorKey] = {
      seatsCount: 0,
      row: {
        left: {},
        left_center: {},
        center: {},
        right_center: {},
        right: {},
      },
    }

    // if we have a scheme with one and only one empty place in center row
    const transformedFloor = [...floor]

    if (floor[2].length === 1 && floor[2][0].type === SeatType.EMPTY) {
      floor[0].forEach((_, index) => {
        if (index !== 0) {
          transformedFloor[2].push({ type: SeatType.EMPTY })
        }
      })
    }

    transformedFloor.forEach((row, rowIndex) => {
      const rowKey = getRowKey(rowIndex)

      if (!rowKey) return

      row.forEach((cell, cellIndex) => {
        result.floor[floorKey]!.row[rowKey][cellIndex + 1] = isSeat(cell)
          ? {
              place: cell.place,
              type: cell.type,
              category: cell.category,
            }
          : cell
        result.floor[floorKey]!.seatsCount += isSeat(cell) ? 1 : 0
      })
    })
  })

  if (result.floor.second) {
    result.floorCount = 2
  }

  return result
}

export const getTransformedSchemeArray = (scheme: Scheme) => {
  const getTransformedRow = (rowOfSeats: Record<string, SchemeCell>): RowOfSeats => {
    return Object.values(rowOfSeats).map((cell) => {
      if (isSeat(cell)) {
        return {
          ...cell,
          available: true,
          selected: false,
        }
      }

      return cell
    })
  }

  const getTransformedFloor = (row: SchemeRow): Floor[] => {
    const result = []

    result.push([
      getTransformedRow(row.left),
      getTransformedRow(row.left_center),
      getTransformedRow(row.center),
      getTransformedRow(row.right_center),
      getTransformedRow(row.right),
    ])

    return result
  }

  const result: Floor[] = []

  if (scheme.floor.first) {
    const row = scheme.floor.first.row

    result.push(...getTransformedFloor(row))
  }

  if (scheme.floor.second) {
    const row = scheme.floor.second.row

    result.push(...getTransformedFloor(row))
  }

  return result
}

export const generateEmptyRow = () => {
  const result = {} as SchemeRow

  result.left = {
    1: { type: SeatType.EMPTY },
  }

  result.left_center = {
    1: { type: SeatType.EMPTY },
  }

  result.center = {
    1: { type: SeatType.EMPTY },
  }

  result.right_center = {
    1: { type: SeatType.EMPTY },
  }

  result.right = {
    1: { type: SeatType.EMPTY },
  }

  return result
}

export const generateDefaultRow = (
  cellsCount: number,
  startNumber: number,
  includeSeatInCorridor: boolean | undefined
) => {
  const columnCount = Math.ceil(cellsCount / NUMBER_OF_ROWS)
  const result = {} as SchemeRow
  let currentPlace = startNumber

  for (let i = 1; i <= columnCount; i++) {
    result.left = {
      ...result.left,
      [i.toString()]: getCurrentPlace(currentPlace),
    }
    currentPlace++
    result.left_center = {
      ...result.left_center,
      [i.toString()]: getCurrentPlace(currentPlace),
    }
    currentPlace++
    result.center = {
      ...result.center,
      [i.toString()]:
        includeSeatInCorridor && i === columnCount
          ? getCurrentPlace(currentPlace)
          : { type: SeatType.EMPTY },
    }

    if (includeSeatInCorridor && i === columnCount) {
      currentPlace++
    }

    result.right_center = {
      ...result.right_center,
      [i.toString()]: getCurrentPlace(currentPlace),
    }
    currentPlace++
    result.right = {
      ...result.right,
      [i.toString()]: getCurrentPlace(currentPlace),
    }
    currentPlace++
  }

  return result
}

export const generateScheme = ({
  firstFloorCellsCount,
  secondFloorCellsCount,
  includeSeatInCorridor,
}: GenerateSchemeType) => {
  const scheme: Scheme = {
    floorCount: 1,
    floor: {
      first: null,
      second: null,
    },
  }

  const initializeFloor = (
    seatsCount: number,
    startNumber: number,
    additionalSeat: boolean | undefined
  ) => ({
    seatsCount: Math.ceil(seatsCount / NUMBER_OF_ROWS) * NUMBER_OF_ROWS + Number(additionalSeat),
    row: generateDefaultRow(
      seatsCount,
      Math.ceil(startNumber / NUMBER_OF_ROWS) * NUMBER_OF_ROWS +
        (additionalSeat && startNumber > 0 ? 2 : 1),
      additionalSeat
    ),
  })

  if (firstFloorCellsCount) {
    scheme.floor.first = initializeFloor(firstFloorCellsCount, 0, includeSeatInCorridor)
  }

  if (secondFloorCellsCount) {
    scheme.floor.second = initializeFloor(
      secondFloorCellsCount,
      firstFloorCellsCount,
      includeSeatInCorridor
    )
    scheme.floorCount = 2
  }

  return scheme
}

export const addRow = (row: SchemeRow) => {
  let lastRowKey = 0
  const rowKeys = Object.keys(row.left)

  if (rowKeys.length) {
    lastRowKey = Number(rowKeys[rowKeys.length - 1])
  }

  const result = { ...row }

  result.left[lastRowKey + 1] = {
    type: SeatType.EMPTY,
  }
  result.left_center[lastRowKey + 1] = {
    type: SeatType.EMPTY,
  }
  result.center[lastRowKey + 1] = {
    type: SeatType.EMPTY,
  }
  result.right_center[lastRowKey + 1] = {
    type: SeatType.EMPTY,
  }
  result.right[lastRowKey + 1] = {
    type: SeatType.EMPTY,
  }

  return result
}

export const removeRow = (row: SchemeRow) => {
  const rowKeys = Object.keys(row.left)

  if (rowKeys.length > 1) {
    const lastRowKey = Number(rowKeys[rowKeys.length - 1])

    const result = { ...row }

    delete result.left[lastRowKey]
    delete result.left_center[lastRowKey]
    delete result.center[lastRowKey]
    delete result.right_center[lastRowKey]
    delete result.right[lastRowKey]

    return result
  }

  return row
}

export const checkForUniquePlace = (scheme: Scheme) => {
  // all found place numbers
  const places: number[] = []
  // non-unique place numbers
  const result: number[] = []

  if (scheme.floor.first) {
    Object.values(scheme.floor.first.row).forEach((row: Record<string, SchemeCell>) => {
      Object.values(row).forEach((cell) => {
        if (isSeat(cell)) {
          if (places.includes(cell.place)) {
            result.push(cell.place)
          }

          places.push(cell.place)
        }
      })
    })
  }

  if (scheme.floor.second) {
    Object.values(scheme.floor.second.row).forEach((row: Record<string, SchemeCell>) => {
      Object.values(row).forEach((cell) => {
        if (isSeat(cell)) {
          if (places.includes(cell.place)) {
            result.push(cell.place)
          }

          places.push(cell.place)
        }
      })
    })
  }

  return uniq(result)
}

export const checkForZeroPlace = (scheme: Scheme) => {
  if (scheme.floor.first) {
    const hasZeroPlaceNumbers = Object.values(scheme.floor.first.row).some(
      (row: Record<string, SchemeCell>) => {
        return Object.values(row).some((cell) => isSeat(cell) && cell.place === 0)
      }
    )

    if (hasZeroPlaceNumbers) {
      return true
    }
  }

  if (scheme.floor.second) {
    return Object.values(scheme.floor.second.row).some((row: Record<string, SchemeCell>) => {
      return Object.values(row).some((cell) => isSeat(cell) && cell.place === 0)
    })
  }

  return false
}

export const insertPlaceInScheme = (scheme: Scheme | null, payload: PlaceInsert) => {
  if (!scheme) return null

  const result = { ...scheme }
  const { coords, cell } = payload
  const { floor: floorKey, row: rowPosition, cell: cellNumber } = coords

  const oldCell = result.floor[floorKey]!.row[rowPosition][cellNumber]
  result.floor[floorKey]!.row[rowPosition][cellNumber] = cell

  if (isSeat(oldCell)) {
    result.floor[floorKey]!.seatsCount += isSeat(cell) ? 0 : -1
  } else {
    result.floor[floorKey]!.seatsCount += isSeat(cell) ? 1 : 0
  }

  return result
}

export const getSeatsInRow = (row: SchemeRow) => {
  const rowKeys = Object.keys(row.left)

  if (rowKeys.length > 1) {
    const lastRowKey = Number(rowKeys[rowKeys.length - 1])

    return [
      row.left[lastRowKey],
      row.left_center[lastRowKey],
      row.center[lastRowKey],
      row.right_center[lastRowKey],
      row.right[lastRowKey],
    ].filter((item) => isSeat(item)) as Seat[]
  }

  return []
}

export const getFilteredNonUniquePlaces = (nonUniquePlaces: number[] | null, rowSeats: Seat[]) => {
  const hasNonUniquePlaces = nonUniquePlaces?.some((place) =>
    rowSeats.map((seat) => seat.place).includes(place)
  )
  if (hasNonUniquePlaces) {
    return (
      nonUniquePlaces?.filter((place) => !rowSeats.map((seat) => seat.place).includes(place)) ??
      null
    )
  }

  return nonUniquePlaces
}

export const getMaxPlaceNumber = (scheme: Scheme) => {
  // all found place numbers
  const places: number[] = []

  if (scheme.floor.first) {
    Object.values(scheme.floor.first.row).forEach((row: Record<string, SchemeCell>) => {
      Object.values(row).forEach((cell) => {
        if (isSeat(cell) && cell.place) {
          places.push(cell.place)
        }
      })
    })
  }

  if (scheme.floor.second) {
    Object.values(scheme.floor.second.row).forEach((row: Record<string, SchemeCell>) => {
      Object.values(row).forEach((cell) => {
        if (isSeat(cell) && cell.place) {
          places.push(cell.place)
        }
      })
    })
  }

  return places.length ? Math.max(...places) : 0
}
