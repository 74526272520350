import { ReportsReqFetchReportOrders } from './legacy-order-list'
import { OrderDetail } from './order.models'

export const ordersQueryKeys = {
  all: ['orders'] as const,
  ordersList: (params: ReportsReqFetchReportOrders) => [...ordersQueryKeys.all, params] as const,
  ordersListExport: (params: ReportsReqFetchReportOrders) =>
    [...ordersQueryKeys.all, params, 'export'] as const,
  xls: (params: ReportsReqFetchReportOrders) => [...ordersQueryKeys.all, params, 'xls'] as const,
  detail: (id: OrderDetail['id'] | null) => [...ordersQueryKeys.all, id] as const,
  pdf: (id: OrderDetail['id'] | null) => [...ordersQueryKeys.all, 'pdf', id] as const,
}
