import { ChangeEvent } from 'react'
import { X as CloseIcon, XCircle as XCircleIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import { CityItem } from '@klr/api-connectors'
import { useBoolean } from '@klr/shared'

import { useFetchCityList } from '../../hooks'

import { CityList } from './CityList'

type Props = {
  placeholder: string
  label: string
  value: CityItem | null
  onChange: (val: CityItem) => void
}

const StyledCloseButton = styled(IconButton)({
  position: 'absolute',
  top: 0,
  right: 0,
  marginTop: 3,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const StyledTitle = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
}))

const StyledSearchFieldWrap = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
}))

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(2, 0),
  margin: theme.spacing(2, 0),
}))

export const ModalAutocompleteCities = ({ placeholder, label, value, onChange }: Props) => {
  const { searchInput, setSearchInput, dataSource, loading } = useFetchCityList(value)
  const { t } = useTranslation()

  const [open, setOpen] = useBoolean()

  const selectCity = (item: CityItem): void => {
    onChange(item)
    setOpen.off()
  }

  return (
    <>
      <TextField
        fullWidth
        label={label}
        variant="outlined"
        placeholder={placeholder}
        onClick={setOpen.on}
        aria-readonly
        value={value?.name || ''}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Dialog fullScreen open={open}>
        <StyledDialogTitle>
          <StyledTitle>
            <Typography variant="h4" gutterBottom>
              {t('Booking.trip.departure_point')}
            </Typography>

            <StyledCloseButton aria-label="close" onClick={setOpen.off} size="large">
              <SvgIcon>
                <CloseIcon />
              </SvgIcon>
            </StyledCloseButton>
          </StyledTitle>

          <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />

          <StyledSearchFieldWrap>
            <TextField
              fullWidth
              label={label}
              variant="outlined"
              sx={{ mb: 2 }}
              value={searchInput}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setSearchInput(event.target.value)
              }}
              InputProps={{
                endAdornment: searchInput ? (
                  <InputAdornment position="end" onClick={() => setSearchInput('')}>
                    <SvgIcon color="action">
                      <XCircleIcon />
                    </SvgIcon>
                  </InputAdornment>
                ) : null,
              }}
            />

            {!dataSource.length && searchInput ? (
              <Typography variant="body2">{t('Messages.departure_point_not_found')}</Typography>
            ) : null}
          </StyledSearchFieldWrap>
        </StyledDialogTitle>

        <DialogContent dividers>
          <CityList
            dataSource={dataSource}
            isLoading={loading}
            selectCity={selectCity}
            searchInput={searchInput}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
