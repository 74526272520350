import { TFunction } from 'react-i18next'
import * as Yup from 'yup'

import {
  validateEmail,
  validateNotRequiredStringField,
  validatePhone,
  validateRequiredStringField,
} from '@klr/shared'

export const ticketPassengerSchema = (t: TFunction) =>
  Yup.object().shape({
    place: Yup.number().notRequired(),
    firstName: validateNotRequiredStringField(t),
    lastName: validateNotRequiredStringField(t),
    phone: Yup.lazy((value) => {
      if (value !== '') {
        return validatePhone(t)
      }
      return Yup.string().notRequired()
    }),
    email: validateEmail(t),
    ticketNumber: validateRequiredStringField(t),
    note: validateNotRequiredStringField(t),
  })

export type TicketPassengerFormFields = Yup.InferType<ReturnType<typeof ticketPassengerSchema>>
