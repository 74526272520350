import { initReactI18next } from 'react-i18next'
import { createInstance, InitOptions } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import enLocales from './locales/es-ES.json'
import plLocales from './locales/pl-PL.json'
import ruLocales from './locales/ru-RU.json'
import ukLocales from './locales/uk-UA.json'

interface I18nInstanceOptions extends InitOptions {
  resources: Partial<{
    uk: { translations: typeof ukLocales }
    en: { translations: typeof enLocales }
    pl: { translations: typeof plLocales }
    ru: { translations: typeof ruLocales }
  }>
}

export const createI18nInstance = (options: I18nInstanceOptions) => {
  const i18n = createInstance()

  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: process.env.NODE_ENV !== 'production',
      defaultNS: 'translations',
      detection: {
        order: ['localStorage', 'navigator'],
        lookupLocalStorage: 'i18nextLng',
      },
      interpolation: {
        escapeValue: false,
      },
      ns: ['translations'],
      ...options,
    })

  return i18n
}
