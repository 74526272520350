import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { CustomerDetailDTO, CustomerSocialNetwork, OrderDetail } from '@klr/api-connectors'
import { BasicOptions } from '@klr/shared'

import { SocialDataType } from '../customer.constants'
import { getCustomerMainInfo, getCustomerSocialListSnippet } from '../customer.utils'

interface MainInfoSnippet extends BasicOptions {
  highlightIfAbsent: boolean
}

interface CustomerStore {
  mainInfo: CustomerDetailDTO | null
  orders: OrderDetail[]
  socials: CustomerSocialNetwork[]
  getMainInfoSnippet: () => MainInfoSnippet[]
  getSocialListSnippet: () => SocialDataType[]
}

export const useCustomerStore = create<CustomerStore>()(
  devtools(
    persist(
      (_, get) => ({
        mainInfo: null,
        orders: [],
        socials: [],
        getMainInfoSnippet: () => getCustomerMainInfo(get().mainInfo),
        getSocialListSnippet: () => getCustomerSocialListSnippet(get().socials),
      }),
      { name: 'CustomerStore', version: 6 }
    ),
    {
      name: 'customerStore',
      enabled: process.env.NODE_ENV === 'development',
    }
  )
)

export const setMainInfo = (payload: CustomerDetailDTO | null) =>
  useCustomerStore.setState(() => ({ mainInfo: payload }))
export const setOrders = (payload: OrderDetail[]) =>
  useCustomerStore.setState(() => ({ orders: payload }))
export const setSocials = (payload: CustomerSocialNetwork[]) =>
  useCustomerStore.setState(() => ({ socials: payload }))
export const clearCustomerData = () =>
  useCustomerStore.setState(() => ({ mainInfo: null, orders: [], socials: [] }))
