import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 17 36"
      width="1em"
      height="1em"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1V34.9988C0 35.9521 1.20709 36.3648 1.79069 35.611L7.70931 27.967C8.10965 27.45 8.89035 27.45 9.29069 27.967L15.2093 35.611C15.7929 36.3648 17 35.9521 17 34.9988V1C17 0.447717 16.5523 0 16 0H1C0.447715 0 0 0.447715 0 1ZM8.69018 10.5641C8.63023 10.3799 8.36977 10.3799 8.30982 10.5641L7.31013 13.6346C7.28332 13.717 7.20656 13.7727 7.11995 13.7727H3.88573C3.69189 13.7727 3.61139 14.0208 3.76832 14.1346L6.38418 16.0313C6.45442 16.0823 6.48381 16.1727 6.45695 16.2552L5.45762 19.3246C5.39765 19.5088 5.60838 19.6622 5.7652 19.5485L8.3826 17.6506C8.45263 17.5998 8.54737 17.5998 8.6174 17.6506L11.2348 19.5485C11.3916 19.6622 11.6023 19.5088 11.5424 19.3246L10.543 16.2552C10.5162 16.1727 10.5456 16.0823 10.6158 16.0313L13.2317 14.1346C13.3886 14.0208 13.3081 13.7727 13.1143 13.7727H9.88004C9.79344 13.7727 9.71668 13.717 9.68987 13.6346L8.69018 10.5641Z"
      />
    </svg>
  )
}

const ForwardRef = React.forwardRef(SvgComponent)
const MemoForwardRef = React.memo(ForwardRef)
export default MemoForwardRef
