import React, { forwardRef } from 'react'
import { NumericFormat } from 'react-number-format'
import { TextField, TextFieldProps } from '@mui/material'

export type PercentInputProps = {
  value: number
  setValue(val: number): void
} & Omit<TextFieldProps, 'value' | 'defaultValue' | 'type'>

export const PercentInput = forwardRef(
  ({ label, value, setValue, ...rest }: PercentInputProps, ref) => (
    <NumericFormat
      customInput={TextField}
      value={value}
      isAllowed={({ floatValue }) =>
        // allow deletion (floatValue becomes undefined and value turns to 0 in onValueChange function)
        // or values between 0 and 100
        floatValue === undefined || (floatValue <= 100 && floatValue >= 0)
      }
      onValueChange={({ floatValue }) => {
        setValue(floatValue ?? 0)
      }}
      allowNegative={false}
      decimalScale={0}
      suffix={'%'}
      label={label}
      fullWidth
      getInputRef={ref}
      {...rest}
    />
  )
)
