import { AxiosPromise } from 'axios'

import { axios } from '../../_axios'
import { DetailType } from '../../_types'
import {
  TicketDetail,
  TicketLiqPayInfo,
  TicketReqCustomReturnPercent,
  TicketReqReturn,
  TicketResReturn,
  TicketReturnCondition,
} from '../models'

/**
 * Отримати попередні суми повернення квитка
 *
 * @param ticketId
 */
export const apiTicketsFetchReturnConditions = (
  ticketId: TicketDetail['id']
): AxiosPromise<DetailType<TicketReturnCondition>> => {
  return axios.get(`/tickets/${ticketId}/return-conditions`)
}

/**
 * Повернути квиток за умовами повернення або по відсотку
 *
 * @param {TicketReqReturn }
 */
export const apiTicketsReturn = ({
  ticketId,
  data,
}: TicketReqReturn): AxiosPromise<DetailType<TicketResReturn>> => {
  return axios.post(`/tickets/${ticketId}/return`, data)
}

/**
 * // TODO: https://linear.app/klr-bus/issue/KLR-3210/kvitkizamovlennya-povernennya-kvitka-ba-18#comment-6a650617
 * Встановити Відсоток для повернення.
 *
 * @param {TicketReqCustomReturnPercent }
 */
export const apiTicketsCustomReturnPercent = ({
  ticketId,
  data,
}: TicketReqCustomReturnPercent): AxiosPromise =>
  axios.put(`/tickets/${ticketId}/custom-return-percent`, data)

/**
 * Повернути суму повернутого квитка по LiqPay
 *
 * @param ticketId
 */
export const apiTicketsLiqPayReturn = (
  ticketId: TicketDetail['id']
): AxiosPromise<DetailType<TicketLiqPayInfo>> => {
  return axios.post('/liqpay/return', {
    ticket_id: ticketId,
  })
}
