import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { IconButton, Tooltip } from '@mui/material'

import { copyToClipboard } from '../../utils'

type Props = {
  onClick?(event: MouseEvent<HTMLButtonElement>): void
  textToCopy: string
}

export const ClipBoardButton = ({ onClick, textToCopy }: Props) => {
  const { t } = useTranslation()

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    copyToClipboard(textToCopy, t('Messages.text_copied'))

    if (onClick) {
      onClick(event)
    }
  }

  return (
    <Tooltip title={t('Common.copy_to_buffer')}>
      <IconButton onClick={handleClick} size="small" data-testid="clipboard-button">
        <ContentCopyIcon color="action" />
      </IconButton>
    </Tooltip>
  )
}
