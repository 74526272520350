import { forwardRef } from 'react'

type Props = {
  color?: string
  size?: number
}

export const Dot = forwardRef<SVGSVGElement, Props>(
  ({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
      <svg
        fill={color}
        width={size}
        height={size}
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        stroke={color}
        strokeWidth="1"
        {...rest}
      >
        <path d="M7.8 10a2.2 2.2 0 0 0 4.4 0 2.2 2.2 0 0 0-4.4 0z" />
      </svg>
    )
  }
)
