import { forwardRef, Ref } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  CircularProgress,
  Skeleton,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material'
import { inputBaseClasses } from '@mui/material/InputBase'
import { styled } from '@mui/material/styles'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'

import { CityItem } from '@klr/api-connectors'

import { useFetchCityList } from '../hooks'

interface AutocompleteCitiesProps {
  value: CityItem | null
  onChange(val: CityItem): void
  countryId?: number
  autoCompleteProps?: Omit<
    AutocompleteProps<CityItem, false, true, false>,
    | 'inputValue'
    | 'onInputChange'
    | 'getOptionLabel'
    | 'isOptionEqualToValue'
    | 'options'
    | 'value'
    | 'onChange'
    | 'renderInput'
  >
  textFieldProps?: Omit<TextFieldProps, 'value' | 'onChange'>
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&.outlinedFieldSet': {
      [`& .${inputBaseClasses.input}`]: {
        padding: theme.spacing(1, 0, 1, 2),
      },
      '& fieldset': {
        border: `1px solid ${theme.palette.grey[700]}`,
      },
      '&:hover fieldset': {
        border: `1px solid ${theme.palette.grey[700]}`,
      },
      [`&.${inputBaseClasses.focused} fieldset`]: {
        border: `1px solid ${theme.palette.grey[700]}`,
      },
    },
  },
}))

export const AutocompleteCities = forwardRef(
  (
    { value, onChange, countryId, autoCompleteProps, textFieldProps }: AutocompleteCitiesProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const { t } = useTranslation()

    const { searchInput, setSearchInput, dataSource, loading } = useFetchCityList(value, countryId)

    return (
      <Autocomplete<CityItem, false, true>
        blurOnSelect="touch"
        autoComplete
        autoHighlight
        noOptionsText={<Typography variant="body2">{t('Messages.cities_not_found')}</Typography>}
        includeInputInList
        inputValue={searchInput}
        onInputChange={(_, newVal) => {
          setSearchInput(newVal)
        }}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, valueInput) =>
          option.name === valueInput?.name || valueInput?.name === ''
        }
        popupIcon={null}
        disableClearable
        options={dataSource}
        loading={loading}
        value={value as CityItem}
        loadingText={<Skeleton width="100%" />}
        onChange={(_, val) => {
          onChange(val)
        }}
        filterOptions={(x) => x}
        renderOption={(props, option, { inputValue }) => {
          const matches = match(option.name, inputValue, { insideWords: true })
          const parts = parse(option.name, matches)

          return (
            <li {...props} key={option.id}>
              <div>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                {', '}
                <Typography variant="caption">{option.country_name}</Typography>
              </div>
            </li>
          )
        }}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            label={t('Labels.cities')}
            {...textFieldProps}
            inputRef={ref}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
              ...textFieldProps?.InputProps,
            }}
          />
        )}
        {...autoCompleteProps}
      />
    )
  }
)
