import { AxiosPromise } from 'axios'

import { axios } from '../_axios'
import { DetailDataType, ListDataPaginationType, ListDataType, ReqFetchList } from '../_types'

import {
  SorryBonusCategoryDTO,
  SorryBonusDTO,
  SorryBonusReqChange,
  SorryBonusReqCreate,
} from './sorry-bonuses.models'

export const apiSorryBonusFetchList = (
  params: ReqFetchList
): AxiosPromise<ListDataPaginationType<SorryBonusDTO>> => {
  return axios.get('/customers/loyalty/transactions/templates', { params })
}

export const apiSorryBonusCategoryFetchList = (): AxiosPromise<
  ListDataType<SorryBonusCategoryDTO>
> => {
  return axios.get('/customers/loyalty/transactions/templates/categories')
}

export const apiSorryBonusCreate = (
  data: SorryBonusReqCreate
): AxiosPromise<DetailDataType<SorryBonusDTO>> => {
  return axios.post('/customers/loyalty/transactions/templates', data)
}

export const apiSorryBonusChange = ({
  id,
  data,
}: SorryBonusReqChange): AxiosPromise<DetailDataType<SorryBonusDTO>> => {
  return axios.put(`/customers/loyalty/transactions/templates/${id}`, data)
}

export const apiSorryBonusDelete = (id: SorryBonusDTO['id']): AxiosPromise => {
  return axios.delete(`/customers/loyalty/transactions/templates/${id}`)
}
