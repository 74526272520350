import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import {
  apiBranchFetchList,
  BranchDTO,
  BranchesQueryKeys,
  branchesQueryKeys,
} from '@klr/api-connectors'

export const useFetchBranchList = (
  options: Omit<
    UseQueryOptions<BranchDTO[], AxiosError, BranchDTO[], ReturnType<BranchesQueryKeys['list']>>,
    'queryKey' | 'queryFn'
  > = {}
) => {
  return useQuery({
    ...options,
    queryKey: branchesQueryKeys.list(),
    queryFn: async () => {
      const {
        data: { data },
      } = await apiBranchFetchList()

      return data
    },
  })
}
