import { create } from 'zustand'

import { BookingItem } from '@klr/api-connectors'
import { DateGroupedTrips } from '@klr/booking'

interface CustomerTripsStore {
  internalTrips: BookingItem[]
  externalTrips: BookingItem[]
  anotherTrips: DateGroupedTrips[]
  searchStatus: boolean
}

const initialState: CustomerTripsStore = {
  internalTrips: [],
  externalTrips: [],
  anotherTrips: [],
  searchStatus: false,
}

export const useCustomerTripsStore = create<CustomerTripsStore>()(() => initialState)

export const setInternalTrips = (payload: BookingItem[]) =>
  useCustomerTripsStore.setState({ internalTrips: payload })

export const setExternalTrips = (payload: BookingItem[]) =>
  useCustomerTripsStore.setState({ externalTrips: payload })

export const setAnotherTrips = (payload: DateGroupedTrips[]) =>
  useCustomerTripsStore.setState({ anotherTrips: payload })

export const setSearchStatus = (payload: boolean) =>
  useCustomerTripsStore.setState(() => ({ searchStatus: payload }))

export const resetCustomerTripsStore = () => useCustomerTripsStore.setState(initialState)
