import { lazy, ReactNode, Suspense, SyntheticEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, Skeleton, Tab, Tabs } from '@mui/material'

import { BookingItem } from '@klr/api-connectors'

import { ListStations } from './partials/ListStations'
import { MainInfo } from './partials/MainInfo'

const ReturnConditions = lazy(() => import('./partials/ReturnConditions'))

type Props = {
  hasSmsForm?: boolean
  phoneNumber?: string
  tripData: BookingItem
}

function a11yProps(index: unknown) {
  return {
    id: `trip-detail-tab-${index}`,
    'aria-controls': `trip-detail-tab-${index}`,
  }
}

interface TabPanelProps {
  children?: ReactNode
  index: unknown
  value: unknown
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`trip-detail-tabpanel-${index}`}
      aria-labelledby={`trip-detail-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

export const CollapseTripDetail = ({ hasSmsForm = false, phoneNumber = '', tripData }: Props) => {
  const [currentTab, setCurrentTab] = useState(0)
  const { t } = useTranslation()

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const hasReturnConditions = tripData.return_conditions.length > 0

  return (
    <Card
      sx={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      <Tabs value={currentTab} onChange={handleChange} scrollButtons="auto" variant="scrollable">
        <Tab label={t('Booking.trip.route')} {...a11yProps(0)} />
        <Tab label={t('Booking.trip.stations_list')} {...a11yProps(1)} />
        {hasReturnConditions && <Tab label={t('Orders.return_conditions')} {...a11yProps(2)} />}
      </Tabs>

      <TabPanel value={currentTab} index={0}>
        <MainInfo hasSmsForm={hasSmsForm} phoneNumber={phoneNumber} tripData={tripData} />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <ListStations dataSource={tripData.timetable} />
      </TabPanel>
      {hasReturnConditions && (
        <TabPanel value={currentTab} index={2}>
          <Suspense fallback={<Skeleton width="100%" height={100} variant="rectangular" />}>
            <ReturnConditions
              dataSource={tripData.return_conditions}
              price={tripData.price.value}
              currency={tripData.price.currency}
            />
          </Suspense>
        </TabPanel>
      )}
    </Card>
  )
}
