import { Box, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledColorPickButton = styled(IconButton)<{ ownerState: { bgColor: string } }>(
  ({ theme, ownerState }) => ({
    width: theme.spacing(2.75),
    height: theme.spacing(2.75),
    borderRadius: '50%',
    backgroundColor: ownerState.bgColor,
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: ownerState.bgColor,
      border: `1px solid ${theme.palette.common.black}`,
    },
  })
)

export const StyledLabelIcon = styled(Box)<{ ownerState: { color?: string } }>(
  ({ theme, ownerState }) => ({
    width: theme.spacing(1.25),
    height: theme.spacing(1.25),
    borderRadius: '50%',
    backgroundColor: ownerState.color ?? theme.palette.background.default,
  })
)

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.text.secondary}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,
}))
