import { createI18nInstance, enLocales, plLocales, ruLocales, ukLocales } from '@klr/shared'

export const i18n = createI18nInstance({
  resources: {
    uk: { translations: ukLocales },
    en: { translations: enLocales },
    pl: { translations: plLocales },
    ru: { translations: ruLocales },
  },
  supportedLngs: ['uk', 'en', 'pl', 'ru'],
  fallbackLng: ['en'],
})
