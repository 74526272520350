import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { bookingPaths } from '@klr/booking'
import { customerPaths } from '@klr/customers'
import { SvgColor } from '@klr/shared'

import { paths } from '../../router/paths'

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
)

const ICONS = {
  analytics: icon('ic_analytics'),
  network: icon('ic_network'),
  order: icon('ic_order'),
  tour: icon('ic_tour'),
  currencies: icon('ic_currencies'),
}

export function useNavData() {
  const { t } = useTranslation()
  return useMemo(
    () => [
      {
        title: t('Common.booking'),
        icon: ICONS.tour,
        path: bookingPaths.bookingSearch,
      },
      {
        title: t('Orders.order'),
        icon: ICONS.order,
        path: paths.orderList,
      },
      {
        title: t('Common.report_sells'),
        icon: ICONS.analytics,
        path: paths.reportsTickets,
      },
      {
        title: t('Pages.customer_referrals'),
        icon: ICONS.network,
        path: customerPaths.customerReferrals,
      },
      {
        title: t('Common.system_currencies'),
        icon: ICONS.currencies,
        path: paths.currencies,
      },
    ],
    [t]
  )
}
