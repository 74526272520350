import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { CustomerDTO } from '@klr/api-connectors'

interface CustomerUIStore {
  showDrawer: boolean
  customerId: CustomerDTO['id'] | null
}

export const useCustomerUIStore = create<CustomerUIStore>()(
  persist(
    (_) => ({
      showDrawer: false,
      customerId: null,
    }),
    { name: 'CustomerUIStore', version: 3 }
  )
)

export const toggleCustomerCardDrawer = (payload: boolean) =>
  useCustomerUIStore.setState(() => ({ showDrawer: payload }))

export const setCustomerCardId = (payload: CustomerDTO['id'] | null) =>
  useCustomerUIStore.setState(() => ({ customerId: payload }))
