import React, { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material'

export type SearchTextFieldProps = {
  defaultValue?: string | number
  delay?: number
} & TextFieldProps

export const SearchTextField = memo(
  ({ defaultValue = '', delay = 600, onChange, ...textFieldProps }: SearchTextFieldProps) => {
    const { t } = useTranslation()
    const [internalValue, setInternalValue] = useState(defaultValue)

    useEffect(() => {
      setInternalValue(defaultValue)
    }, [defaultValue])

    useEffect(() => {
      const timer = setTimeout(() => {
        if (onChange) {
          onChange({
            target: { value: internalValue },
          } as React.ChangeEvent<HTMLInputElement>)
        }
      }, delay)

      return () => clearTimeout(timer)
    }, [delay, internalValue, onChange])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setInternalValue(event.target.value)
    }

    const handleClear = () => {
      setInternalValue('')

      if (onChange) {
        onChange({
          target: { value: '' },
        } as React.ChangeEvent<HTMLInputElement>)
      }
    }

    return (
      <TextField
        fullWidth
        size="small"
        {...textFieldProps}
        InputProps={{
          ...textFieldProps.InputProps,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              onClick={handleClear}
              sx={{ visibility: !textFieldProps.disabled && internalValue ? 'visible' : 'hidden' }}
            >
              <IconButton aria-label={t('Common.clear')} title={t('Common.clear')}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={internalValue}
        onChange={handleChange}
      />
    )
  }
)
