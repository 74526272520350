import { CustomerSocialEnum, SorryBonusDTO, ValueTypeEnum } from '@klr/api-connectors'
import { CustomSvgIcon, FacebookIcon, InstagramIcon, TelegramIcon, TiktokIcon } from '@klr/shared'
import { minValue } from '@klr/system-entities'

export interface SocialDataType {
  icon: CustomSvgIcon
  id: number | null
  link: string | null
  type: CustomerSocialEnum
}

export const socialData: SocialDataType[] = [
  {
    icon: FacebookIcon,
    id: null,
    link: null,
    type: CustomerSocialEnum.FACEBOOK,
  },
  {
    icon: TelegramIcon,
    id: null,
    link: null,
    type: CustomerSocialEnum.TELEGRAM,
  },
  {
    icon: InstagramIcon,
    id: null,
    link: null,
    type: CustomerSocialEnum.INSTAGRAM,
  },
  {
    icon: TiktokIcon,
    id: null,
    link: null,
    type: CustomerSocialEnum.TIKTOK,
  },
]

export const defaultSorryBonusTemplate: SorryBonusDTO = {
  id: 0,
  title: 'Кастомне повідомлення',
  type: 'type_apology',
  type_translate: 'Вибачення',
  text: '',
  value: minValue,
  value_type: ValueTypeEnum.FIXED,
  sms_text: '',
}
