import { useTranslation } from 'react-i18next'
import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import EmptyDataSVG from './EmptySearchTrip.svg'

const StyledCard = styled(Card)(({ theme }) => ({
  height: 450,
  width: '100%',
  maxWidth: 900,
  padding: theme.spacing(2),
  boxShadow: 'none',
}))

const StyledMedia = styled(CardMedia)({
  backgroundSize: 'contain',
  width: '100%',
  height: 300,
})

export const ResultEmptySearch = () => {
  const { t } = useTranslation()

  return (
    <Grid sx={{ width: '100%', mt: 4 }} container alignItems="center" justifyContent="center">
      <StyledCard>
        <StyledMedia image={EmptyDataSVG} title={t('Validations.no_data')} />
        <CardContent>
          <Typography align="center" variant="h3" gutterBottom>
            {t('Messages.trips_not_found')}
            <span role="img" aria-label="empty-inbox">
              📭
            </span>
          </Typography>
          <Typography align="center" variant="body1" gutterBottom>
            {t('Messages.trips_not_found_for_this_date')}
          </Typography>
        </CardContent>
      </StyledCard>
    </Grid>
  )
}
