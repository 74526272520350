import { isValid } from 'date-fns'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { CityItem, CurrencyDTO, DateType } from '@klr/api-connectors'
import { formatToDateString, parseDateString } from '@klr/shared'

interface SearchCustomerTripsStore {
  cityFrom: CityItem | null
  cityTo: CityItem | null
  date: DateType
  passengers: number
  getDate: () => Date
  selectedCurrency: CurrencyDTO | null
}

export const useSearchCustomerTripsStore = create<SearchCustomerTripsStore>()(
  devtools(
    persist(
      (_, get) => ({
        cityFrom: null,
        cityTo: null,
        date: formatToDateString(new Date()),
        passengers: 1,
        getDate: () => parseDateString(get().date),
        selectedCurrency: null,
      }),
      { name: 'SearchCustomerTripsStore', version: 1 }
    ),
    {
      name: 'SearchCustomerTripsStore',
      enabled: process.env.NODE_ENV === 'development',
    }
  )
)

export const setCityFrom = (payload: CityItem | null) =>
  useSearchCustomerTripsStore.setState(() => ({ cityFrom: payload }))

export const setCityTo = (payload: CityItem | null) =>
  useSearchCustomerTripsStore.setState(() => ({ cityTo: payload }))

export const setDate = (payload: Date) =>
  useSearchCustomerTripsStore.setState(() => ({
    date: formatToDateString(isValid(payload) ? payload : new Date()),
    searchStatus: false,
  }))

export const setPassengers = (payload: number) =>
  useSearchCustomerTripsStore.setState(() => ({ passengers: payload }))

export const setSelectedCurrency = (payload: CurrencyDTO) =>
  useSearchCustomerTripsStore.setState(() => ({ selectedCurrency: payload }))
