import React, { ElementType, Suspense } from 'react'

import { Fallback } from '../../components/Core/Fallback'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<Fallback />}>
      <Component {...props} />
    </Suspense>
  )
}
