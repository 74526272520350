import axios, { AxiosInstance } from 'axios'

const axiosInstance: AxiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 90000,
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error || 'Something went wrong')
)

axiosInstance.interceptors.request.use((config) => {
  config.headers['Content-Language'] =
    localStorage && localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'uk'

  return config
})

export default axiosInstance
