import { forwardRef } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import { TextField, TextFieldProps } from '@mui/material'

export type NumberInputProps = {
  value: number | undefined
  setValue(val: number | undefined): void
  maxValue?: number
} & Omit<TextFieldProps, 'value' | 'defaultValue' | 'type'> &
  Omit<NumericFormatProps, 'customInput' | 'size' | 'ref'>

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  ({ value, setValue, maxValue = Infinity, ...rest }, ref) => (
    <NumericFormat
      customInput={TextField}
      value={value}
      onValueChange={({ floatValue }) => {
        setValue(floatValue)
      }}
      fullWidth
      allowNegative={false}
      decimalScale={0}
      isAllowed={({ floatValue }) => floatValue === undefined || floatValue <= maxValue}
      getInputRef={ref}
      {...rest}
    />
  )
)
