import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { CurrencyDTO } from '@klr/api-connectors'

import type { AccountState } from './account.types'

const initialState: AccountState = {
  selectCurrency: null,
}

const slice = createSlice({
  name: 'ACCOUNT',
  initialState,
  reducers: {
    changeSelectCurrency: (state: AccountState, action: PayloadAction<CurrencyDTO | null>) => {
      state.selectCurrency = action.payload
    },
  },
})

export default slice.reducer

export const accountActions = slice.actions
