import { lazy } from 'react'

import { Loadable } from '@klr/shared'

const CustomerReferral = Loadable(lazy(() => import('./pages/customer-referrals')))
const CustomerDashboard = Loadable(lazy(() => import('./pages/customer-dashboard')))

export const customerRoutes = {
  path: 'customers',
  children: [{ index: true, element: <CustomerDashboard /> }],
}

export const customerReferralsRoutes = {
  path: 'customer-referrals',
  children: [{ index: true, element: <CustomerReferral /> }],
}

export const customerPaths = {
  customerDashboard: '/app/customers/',
  customerReferrals: '/app/customer-referrals/',
}
