import { forwardRef } from 'react'
import { Box, Link, ListItemButton } from '@mui/material'
import { styled } from '@mui/material/styles'

import { RouterLink } from '@klr/shared'

import { NavItemProps, NavItemStateProps } from '../../../../shared/types'

export const NavItem = forwardRef<HTMLDivElement, NavItemProps>(
  (
    {
      title,
      path,
      icon,
      //
      depth,
      active,
      ...other
    },
    ref
  ) => {
    return (
      <Link component={RouterLink} href={path} color="inherit" underline="none">
        <StyledNavItem ref={ref} depth={depth} active={active} {...other}>
          {icon && (
            <Box component="span" className="icon">
              {icon}
            </Box>
          )}

          {title && (
            <Box component="span" className="label">
              {title}
            </Box>
          )}
        </StyledNavItem>
      </Link>
    )
  }
)

const StyledNavItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})<NavItemStateProps>(({ active, depth, theme }) => {
  const subItem = depth !== 1

  const baseStyles = {
    item: {
      ...theme.typography.body2,
      borderRadius: 6,
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium,
    },
    icon: {
      width: 22,
      height: 22,
      flexShrink: 0,
      marginRight: theme.spacing(1),
    },
    label: {
      textTransform: 'capitalize',
    },
    caption: {
      marginLeft: theme.spacing(0.75),
      color: theme.palette.text.disabled,
    },
    info: {
      display: 'inline-flex',
      marginLeft: theme.spacing(0.75),
    },
    arrow: {
      marginLeft: theme.spacing(0.75),
    },
  } as const

  return {
    // Root item
    ...(!subItem && {
      ...baseStyles.item,
      minHeight: 32,
      flexShrink: 0,
      padding: theme.spacing(0, 0.75),
      '& .icon': {
        ...baseStyles.icon,
      },
      '& .label': {
        ...baseStyles.label,
        whiteSpace: 'nowrap',
      },
      '& .caption': {
        ...baseStyles.caption,
      },
      '& .info': {
        ...baseStyles.info,
      },
      '& .arrow': {
        ...baseStyles.arrow,
      },
      ...(active && {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.action.selected,
        fontWeight: theme.typography.fontWeightBold,
      }),
    }),

    // Sub item
    ...(subItem && {
      ...baseStyles.item,
      minHeight: 34,
      padding: theme.spacing(0, 1),
      '& .icon': {
        ...baseStyles.icon,
      },
      '& .label': {
        ...baseStyles.label,
        flexGrow: 1,
      },
      '& .caption': {
        ...baseStyles.caption,
      },
      '& .info': {
        ...baseStyles.info,
      },
      '& .arrow': {
        ...baseStyles.arrow,
        marginRight: theme.spacing(-0.5),
      },
      ...(active && {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.action.selected,
        fontWeight: theme.typography.fontWeightBold,
      }),
    }),
  }
})
