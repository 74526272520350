import { CornerDownLeft as ReturnIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { Divider, Grid, Table, TableBody } from '@mui/material'

import { TicketDetail } from '@klr/api-connectors'
import { TableDetailRow } from '@klr/shared'

import { useGetReturnConditions } from '../../hooks/tickets'

import { ReturnCondition } from './components/ReturnCondition'

interface TicketPriceInfoProps {
  disable: boolean
  handleReturn: VoidFunction
  hasReturnTicket: boolean
  hasReturnedTicket: boolean
  ticketData: TicketDetail
}

export const TicketPriceInfo = ({
  disable,
  handleReturn,
  hasReturnTicket,
  hasReturnedTicket,
  ticketData,
}: TicketPriceInfoProps) => {
  const { t } = useTranslation()

  const { isLoading, data } = useGetReturnConditions({
    ticketId: ticketData.id,
    hasReturnConditions: !hasReturnedTicket,
  })

  const returnedPrice = ticketData.price < 0 ? -ticketData.price : ticketData.price

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item md={6}>
        {!hasReturnedTicket && data ? (
          <ReturnCondition
            cashbackPayment={ticketData.cashback_payment}
            price={ticketData.price}
            price_extra={ticketData.price_extra}
            currencyISO={ticketData.currency.iso}
            discount={ticketData.discount}
            returnCondition={data}
          />
        ) : null}

        {hasReturnedTicket && (
          <Table size="small">
            <TableBody>
              <TableDetailRow
                title={t('Orders.returned')}
                description={`${returnedPrice} ${ticketData.currency.iso}`}
              />
            </TableBody>
          </Table>
        )}
      </Grid>
      <Grid item md={6}>
        {hasReturnTicket && (
          <LoadingButton
            loading={isLoading}
            variant="outlined"
            disabled={disable}
            // we should not pass an onClick event where either a number or null is expected
            onClick={() => handleReturn()}
            startIcon={<ReturnIcon size={16} />}
            fullWidth
          >
            {t('Messages.return_according_to_the_rules')}
          </LoadingButton>
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  )
}
