import {
  apiCityFetchList,
  apiStationFetchList,
  CityItem,
  CityReqFetchList,
  ConnectedQueryFunctionContext,
  CountryDto,
  getCountryList,
  GetCountryListParams,
  getQueryPagination,
  ListType,
  StationItem,
  StationReqFetchList,
} from '@klr/api-connectors'

// COUNTRY
export const countryQueryKeys = {
  list: ['countries'] as const,
}

export type CountryQueryKeys = typeof countryQueryKeys

export const countryQueryFns = {
  getCountryList: async (
    context: ConnectedQueryFunctionContext<CountryQueryKeys['list']>
  ): Promise<ListType<CountryDto>> => {
    const {
      pageParam = 1,
      queryKey: [, { search }],
    } = context

    const payload: GetCountryListParams = getQueryPagination({ pageParam })

    if (search) payload.query = search.trim()

    const { data } = await getCountryList(payload)
    return data
  },
}

// ============================================
// ====================City====================
// ============================================

export const cityQueryKeys = {
  all: ['cities'] as const,
  list: (params: CityReqFetchList = {}): CityQueryKeys['list'] =>
    [...cityQueryKeys.all, params] as const,
}

export type CityQueryKeys = {
  all: readonly ['cities']
  list: readonly [...CityQueryKeys['all'], CityReqFetchList]
}

export const cityQueryFns = {
  getCityList: async (
    context: ConnectedQueryFunctionContext<CityQueryKeys['list']>
  ): Promise<ListType<CityItem>> => {
    const {
      queryKey: [, { country_id, with_trashed }, { search }],
      pageParam = 1,
    } = context

    const payload: CityReqFetchList = {
      country_id,
      with_trashed,
      ...getQueryPagination({ pageParam }),
    }

    if (search) payload.query = search.trim()

    const { data } = await apiCityFetchList(payload)
    return data
  },
}

// STATIONS
export const stationQueryKeys = {
  all: ['stations'] as const,
  list: (params: StationReqFetchList = {}): StationQueryKeys['list'] =>
    [...stationQueryKeys.all, params] as const,
  detail: (id: StationItem['id'] | null) => [...stationQueryKeys.all, id] as const,
}

export type StationQueryKeys = {
  all: readonly ['stations']
  list: readonly [...StationQueryKeys['all'], StationReqFetchList]
  detail: readonly [...StationQueryKeys['all'], StationItem['id']]
}

export const stationQueryFns = {
  getStationList: async (
    context: ConnectedQueryFunctionContext<StationQueryKeys['list']>
  ): Promise<ListType<StationItem>> => {
    const {
      queryKey: [, { city_id }, { search }],
      pageParam = 1,
    } = context

    const payload: StationReqFetchList = {
      city_id,
      ...getQueryPagination({ pageParam }),
    }

    if (search) payload.query = search.trim()

    const { data } = await apiStationFetchList(payload)
    return data
  },
}
