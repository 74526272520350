import React, { useEffect } from 'react'
import { Box, LinearProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import NProgress from 'nprogress'

const StyledRoot = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  minHeight: '100%',
  padding: theme.spacing(3),
}))

export const Fallback = () => {
  useEffect(() => {
    NProgress.start()

    return () => {
      NProgress.done()
    }
  }, [])

  return (
    <StyledRoot>
      <Box width={400}>
        <LinearProgress />
      </Box>
    </StyledRoot>
  )
}
