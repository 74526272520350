import { Check as CheckIcon } from 'react-feather'
import { SvgIcon, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

type Props = {
  label: string
}

export const GridCellCheck = ({ label }: Props) => (
  <Root>
    <Tooltip title={label}>
      <SvgIcon fontSize="small" color="success">
        <CheckIcon size={20} />
      </SvgIcon>
    </Tooltip>
  </Root>
)
