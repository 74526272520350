import { forwardRef, Ref, SVGProps } from 'react'

export const Swap = forwardRef(
  ({ color = 'currentColor', ...rest }: SVGProps<SVGSVGElement>, svgRef?: Ref<SVGSVGElement>) => {
    return (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth={2}
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
        {...rest}
      >
        <path d="M7.533 3L3 8.667h18.133M16.6 20l4.533-5.667H3" />
      </svg>
    )
  }
)
