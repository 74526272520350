import { Stack, TableCell, TableRow, Typography } from '@mui/material'

import { MaterialIcon } from '../../@types'

type Props = {
  title: string
  description: string
  icon?: MaterialIcon
}

export const TableDetailRow = ({ title, description, icon: Icon }: Props) => (
  <TableRow>
    <TableCell>
      <Stack direction="row" alignItems="center" spacing={1}>
        {Icon && <Icon />}
        <Typography variant="body2" color="textSecondary">
          {title}:
        </Typography>
      </Stack>
    </TableCell>
    <TableCell align="right">
      <Typography variant="body2" color="textSecondary">
        {description}
      </Typography>
    </TableCell>
  </TableRow>
)
