import { axios } from '@klr/api-connectors'
import { globalPaths } from '@klr/shared'

import { STORAGE_KEY } from '../auth.constants'

type DecodedToken = {
  exp: number
}
function jwtDecode(token: string): DecodedToken {
  const base64Url = token.split('.')[1]
  // eslint-disable-next-line more/no-numeric-endings-for-variables
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  )

  return JSON.parse(jsonPayload) as DecodedToken
}

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false
  }

  const decoded = jwtDecode(accessToken)

  const currentTime = Date.now() / 1000

  return decoded.exp > currentTime
}

export const tokenExpired = (exp: number) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer: NodeJS.Timeout | undefined

  const currentTime = Date.now()

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime

  clearTimeout(expiredTimer)

  expiredTimer = setTimeout(() => {
    alert('Token expired')

    localStorage.removeItem(STORAGE_KEY)

    window.location.href = globalPaths.auth.login
  }, timeLeft)
}

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem(STORAGE_KEY, accessToken)

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`

    // This function below will handle when the token is expired
    const { exp } = jwtDecode(accessToken) // ~ 60 minutes by minimal server

    tokenExpired(exp)
  } else {
    localStorage.removeItem(STORAGE_KEY)

    delete axios.defaults.headers.common.Authorization
  }
}
