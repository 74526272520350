import { useQuery } from '@tanstack/react-query'

import {
  apiTicketsFetchReturnConditions,
  TicketDetail,
  ticketsQueryKeys,
} from '@klr/api-connectors'
import { AppModules } from '@klr/shared'

interface UseGetReturnConditionsParams {
  ticketId: TicketDetail['id']
  hasReturnConditions: boolean
}

export function useGetReturnConditions({
  ticketId,
  hasReturnConditions,
}: UseGetReturnConditionsParams) {
  return useQuery({
    enabled: !!ticketId && hasReturnConditions,
    meta: {
      module: AppModules.Tickets,
    },
    queryFn: async () => {
      const { data } = await apiTicketsFetchReturnConditions(ticketId)

      return data.item
    },
    queryKey: ticketsQueryKeys.returnConditions(ticketId),
  })
}
