import { parse } from 'date-fns'

import {
  INT_DATE_FORMAT,
  INT_FULL_DATETIME_FORMAT,
  UKR_DATE_FORMAT,
  UKR_FULL_TIMEDATE_FORMAT,
} from '../../constants'

// parse server date format 'yyyy-MM-dd' to date | '2023-12-01'
export const parseDateString = (value: string): Date => parse(value, INT_DATE_FORMAT, new Date())

// parse 'dd.MM.yyyy' to date | '01.12.2023'
export const parseUkrDateString = (value: string): Date => parse(value, UKR_DATE_FORMAT, new Date())

// parse 'yyyy-MM-dd HH:mm:ss' to date | '2023-12-01 00:00:00'
export const parseDateTimeString = (value: string): Date =>
  parse(value, INT_FULL_DATETIME_FORMAT, new Date())

// parse 'HH:mm dd.MM.yyyy' to date | '00:00 01.12.2023'
export const parseUkrTimeDateString = (value: string): Date =>
  parse(value, UKR_FULL_TIMEDATE_FORMAT, new Date())

// parse 'HH:mm' to date | '00:00'
export const parseTimeString = (value: string): Date => parse(value, 'HH:mm', new Date())
