import { TicketDetail } from '@klr/api-connectors'

import { useConfirmTicket } from '../../../../../../../../hooks/tickets/useConfirmTicket'
import { useCustomReturnTicket } from '../../../../../../../../hooks/tickets/useCustomReturnTicket'
import { useDeleteTicket } from '../../../../../../../../hooks/tickets/useDeleteTicket'
import { useGetTicketPdf } from '../../../../../../../../hooks/tickets/useGetTicketPdf'
import { useReturnTicket } from '../../../../../../../../hooks/tickets/useReturnTicket'

interface UseTicketActionsControllerProps {
  ticketData: TicketDetail
}

export const useTicketActionsController = ({ ticketData }: UseTicketActionsControllerProps) => {
  const { isFetching: loadingPrintPdf, refetch: handlePrintPdf } = useGetTicketPdf(ticketData.id)

  const { handleReturnTicket, isLoading: loadingReturnTicket } = useReturnTicket(
    ticketData.order_id,
    ticketData.id
  )

  const { mutate: handleConfirmTicket, isLoading: loadingConfirmTicket } = useConfirmTicket()

  const { handleDeleteTicket, isLoading: isDeleting } = useDeleteTicket({
    ticketId: ticketData.id,
    orderId: ticketData.order_id,
  })

  const { handleCustomReturnPercent, isLoading: loadingCustomReturnPercent } =
    useCustomReturnTicket({ ticketId: ticketData.id })

  return {
    handlePrintPdf,
    handleReturnTicket,
    handleConfirmTicket,
    handleDeleteTicket,
    handleCustomReturnPercent,
    isLoading:
      loadingReturnTicket || loadingConfirmTicket || isDeleting || loadingCustomReturnPercent,
    loadingPrintPdf,
  }
}
