import { AxiosPromise } from 'axios'

import axios from '../../_axios/axios'
import { ByQuery } from '../../_byQuery'
import { DetailType, ListDataPaginationType, ListType, ListTypeWithTotal } from '../../_types'
import { OrderDetail } from '../../orders'
import {
  CustomerDetailDTO,
  CustomerDTO,
  CustomerReferralDTO,
  CustomerReferralTotal,
  CustomerReqChange,
  CustomerReqCreate,
  CustomerReqTrips,
  CustomerTrip,
  GetCustomerReferralRequest,
} from '../models'

export const getCustomerReferralList = (
  data: GetCustomerReferralRequest
): AxiosPromise<ListTypeWithTotal<CustomerReferralDTO, CustomerReferralTotal>> => {
  return axios.post('/customers/referrals', data)
}

export const apiCustomersByQuery = (
  data: ByQuery.Request<CustomerDTO>
): AxiosPromise<ByQuery.ApiResponse<CustomerDTO>> => {
  return axios.post('/customers/byquery', data)
}

export const apiCustomerFetchDetail = (
  id: CustomerDTO['id']
): AxiosPromise<DetailType<CustomerDetailDTO>> => {
  return axios.get(`/customers/${id}`)
}

export const apiCustomerFetchOrders = (
  id: CustomerDTO['id']
): AxiosPromise<ListType<OrderDetail>> => {
  return axios.get(`/customers/${id}/orders`)
}

export const apiCustomerFetchTrips = ({
  id,
  params,
}: CustomerReqTrips): AxiosPromise<ListDataPaginationType<CustomerTrip>> => {
  return axios.get(`/customers/${id}/travels`, { params })
}

export const apiCustomerCreate = (
  data: CustomerReqCreate
): AxiosPromise<DetailType<CustomerDetailDTO>> => {
  return axios.post(`/customers`, data)
}

export const apiCustomerUpdate = ({
  id,
  data,
}: CustomerReqChange): AxiosPromise<DetailType<CustomerDetailDTO>> => {
  return axios.put(`/customers/${id}`, data)
}
