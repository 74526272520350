import React from 'react'
import { useTranslation } from 'react-i18next'
import { Search as SearchIcon } from '@mui/icons-material'
import { Autocomplete, CircularProgress, InputAdornment, TextField } from '@mui/material'
import { QueryKey } from '@tanstack/query-core'
import { useInfiniteQuery } from '@tanstack/react-query'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { AxiosError } from 'axios'

import {
  DEFAULT_LIMIT,
  getNextPageQueryParam,
  isListType,
  ListPaginationType,
  QueryKeyWithSearch,
} from '@klr/api-connectors'

import { ConnectedAutocompleteProps } from './ConnectedAutocomplete.types'
import { useControlScroll } from './useControlScroll'
import { useSearchValue } from './useSearchValue'

export const ConnectedAutocomplete = <
  Option,
  TQueryKey extends QueryKey = QueryKey,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>(
  props: ConnectedAutocompleteProps<Option, TQueryKey, Multiple, DisableClearable, FreeSolo>
) => {
  const {
    autocompleteProps,
    getOptionLabel,
    isOptionEqualToValue,
    renderOption,
    value,
    defaultValue,
    onChange,
    textFieldProps,
    queryFn,
    queryKey,
    queryOptions = {},
    limit = DEFAULT_LIMIT,
  } = props
  const { t } = useTranslation()

  const { searchValue, handleInputChange } = useSearchValue()

  const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery<
    ListPaginationType<Option>,
    AxiosError,
    ListPaginationType<Option>,
    QueryKeyWithSearch<TQueryKey>
  >({
    queryKey: [...queryKey, { search: searchValue }],
    queryFn,
    refetchOnMount: false,
    getNextPageParam: getNextPageQueryParam,
    ...queryOptions,
  })

  const options: Option[] = React.useMemo(
    () =>
      data?.pages.flatMap((page) => {
        // we don't use defaultValue in a project for now when we have an old pagination type,
        // so we can safely ignore this case
        if (isListType(page)) return page.items

        if (page.meta.current_page === 1 && defaultValue)
          return [defaultValue as Option, ...page.data]

        return page.data
      }) ?? [],
    [data?.pages, defaultValue]
  )

  const listBoxProps = useControlScroll({
    data,
    limit,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  })

  return (
    <Autocomplete<Option, Multiple, DisableClearable, FreeSolo>
      loading={isLoading}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('Common.search')}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
            ...textFieldProps?.InputProps,
          }}
          {...textFieldProps}
        />
      )}
      renderOption={(optionProps, option, state, ownerState) => {
        const matches = match(getOptionLabel(option), state.inputValue, { insideWords: true })
        const parts = parse(getOptionLabel(option), matches)

        let optionElement: React.ReactNode = (
          <li {...optionProps}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        )

        if (renderOption) optionElement = renderOption(optionProps, option, state, ownerState)

        // https://mui.com/material-ui/react-autocomplete/#highlights

        if (isFetchingNextPage && state.index === options.length - 1) {
          return (
            <React.Fragment key={state.index}>
              {optionElement}
              <li
                {...optionProps}
                key={`${state.index}_loading`}
                style={{ ...optionProps.style, justifyContent: 'center' }}
              >
                <CircularProgress />
              </li>
            </React.Fragment>
          )
        }

        return optionElement
      }}
      onInputChange={handleInputChange}
      {...autocompleteProps}
      ListboxProps={listBoxProps}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      forcePopupIcon={false}
    />
  )
}
