import { GetNextPageParamFunction } from '@tanstack/query-core'

import { ListPaginationType, ListType } from '../../_types'

export const isListType = <T>(data: ListPaginationType<T>): data is ListType<T> => 'items' in data

export const getNextPageQueryParam: GetNextPageParamFunction<ListPaginationType<unknown>> = (
  lastPage,
  pages
) => {
  const firstPage = pages[0]

  if (!firstPage) return false

  const perPageLimit = isListType(firstPage) ? firstPage.items.length : firstPage.data.length
  const totalAmountOfRecordsInDataBase = isListType(lastPage) ? lastPage.count : lastPage.meta.total
  const amountOfFetchedPages = pages.length

  // There are pages to fetch
  if (amountOfFetchedPages * perPageLimit < totalAmountOfRecordsInDataBase) {
    return amountOfFetchedPages + 1
  }

  return false
}
