import { lazy } from 'react'

import { Loadable } from '@klr/shared'

const BusSchemes = Loadable(lazy(() => import('./pages/bus-schemes')))
const BusSchemeCreate = Loadable(lazy(() => import('./pages/bus-scheme-create')))
const BusSchemeEdit = Loadable(lazy(() => import('./pages/bus-scheme-edit')))

export const schemesRoutes = {
  path: 'schemes',
  children: [
    { element: <BusSchemes />, index: true },
    { path: 'create', element: <BusSchemeCreate /> },
    { path: ':id', element: <BusSchemeEdit /> },
  ],
}

export const schemesPaths = {
  schemes: '/app/settings/schemes/',
  schemesCreate: '/app/settings/schemes/create/',
  schemesEdit: '/app/settings/schemes/:id',
}
