import { getQueryPagination } from '../_byQuery'
import { ConnectedQueryFunctionContext } from '../_shared'
import { ListType } from '../_types'

import { apiAgentsFetchList } from './agent.api'
import { AgentItem, AgentReqFetchItems } from './models'

export const agentQueryKeys = {
  all: ['agents'] as const,
  list: (request: AgentReqFetchItems) => [...agentQueryKeys.all, request] as const,
  detail: (id: AgentItem['id']) => [...agentQueryKeys.all, id] as const,
  routesCommission: (id: AgentItem['id']) =>
    [...agentQueryKeys.detail(id), 'routesCommission'] as const,
  carriersCommission: (id: AgentItem['id']) =>
    [...agentQueryKeys.detail(id), 'carriersCommission'] as const,
  ratesAllowance: (id: AgentItem['id']) =>
    [...agentQueryKeys.detail(id), 'ratesAllowance'] as const,
  subAgents: (id: AgentItem['id']) => [...agentQueryKeys.detail(id), 'subAgents'] as const,
}

export type AgentQueryKeys = {
  all: readonly ['agents']
  list: readonly [...AgentQueryKeys['all'], AgentReqFetchItems]
  detail: readonly [...AgentQueryKeys['all'], AgentItem['id']]
  routesCommission: readonly [...AgentQueryKeys['detail'], 'routesCommission']
  carriersCommission: readonly [...AgentQueryKeys['detail'], 'carriersCommission']
  ratesAllowance: readonly [...AgentQueryKeys['detail'], 'ratesAllowance']
  subAgents: readonly [...AgentQueryKeys['detail'], 'subAgents']
}

export const agentQueryFns = {
  getAgentList: async (
    context: ConnectedQueryFunctionContext<AgentQueryKeys['list']>
  ): Promise<ListType<AgentItem>> => {
    const {
      pageParam = 1,
      queryKey: [, request, { search }],
    } = context

    const payload: AgentReqFetchItems = {
      ...request,
      ...getQueryPagination({ pageParam }),
    }

    if (search) payload.query = search.trim()

    const { data } = await apiAgentsFetchList(payload)
    return data
  },
}
