export const downloadPDF = (pdf: string, filename: string) => {
  const fakeLink = document.createElement('a')

  fakeLink.style.display = 'none'
  document.body.appendChild(fakeLink)

  const url = 'data:application/octet-stream;base64,' + pdf

  fakeLink.setAttribute('href', url)
  fakeLink.setAttribute('download', `${filename}.pdf`)
  fakeLink.click()
}
