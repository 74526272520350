import { RefreshCw as RefreshIcon, Send as SendIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Alert, AlertTitle, IconButton, SvgIcon, Typography } from '@mui/material'
import * as Sentry from '@sentry/react'
import { FallbackRender } from '@sentry/react'

export const AlertError = ({ resetError, eventId }: Parameters<FallbackRender>[0]) => {
  const { t } = useTranslation()

  return (
    <Alert
      severity="error"
      action={
        <>
          <IconButton onClick={resetError} size="large">
            <SvgIcon fontSize="small">
              <RefreshIcon />
            </SvgIcon>
          </IconButton>
          <IconButton onClick={() => Sentry.showReportDialog({ eventId })} size="large">
            <SvgIcon fontSize="small">
              <SendIcon />
            </SvgIcon>
          </IconButton>
        </>
      }
    >
      <AlertTitle>
        {t('404.something_broke')}{' '}
        <span role="img" aria-label="ohh">
          😱
        </span>
      </AlertTitle>
      <Typography variant="body1">{t('404.try_reloading_the_page')}</Typography>
      <Typography variant="body2">
        {t('404.sorry_for_the_inconvenience')}
        <span role="img" aria-label="sorry">
          🥺
        </span>
        <br />
        {t('404.error_processing')}
      </Typography>
    </Alert>
  )
}
