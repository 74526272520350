import { TFunction } from 'react-i18next'

import { ArrayFields } from '@klr/shared'

export const getFormFields = (t: TFunction): ArrayFields[] => [
  {
    name: 'firstName',
    label: t('Auth.first_name'),
    type: 'text',
    required: false,
    placeholder: 'Іван',
  },
  {
    name: 'lastName',
    label: t('Auth.last_name'),
    type: 'text',
    required: false,
    placeholder: 'Іванов',
  },
  {
    name: 'email',
    label: t('Auth.email'),
    type: 'email',
    required: false,
    placeholder: 'ivanov.ivan@example.com',
  },
  {
    name: 'ticketNumber',
    label: t('Common.ticket_number'),
    type: 'text',
    required: false,
    placeholder: '___________',
  },
]
